import * as React from "react";
import { Grid, PaletteMode, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "../components/home/AppAppBar";
import getLPTheme from "./getLPTheme";
import { useEffect } from "react";

const defaultTheme = createTheme({});

export default function PrivacyEulaScreen() {
  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  }, []);

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />

      <Grid
        item
        container
        xs={12}
        justifyContent={"center"}
        sx={{ paddingTop: 5 }}
      >
        <Grid
          item
          xs={10}
          container
          flexDirection={"column"}
          className="policies"
        >
          <Typography variant="h5" gutterBottom>
            End User License Agreement
          </Typography>
          <Typography variant="body1" paragraph>
            **Effective Date: November 4, 2024**
          </Typography>
          <Typography variant="body1" paragraph>
            Please read this End User License Agreement (the "License") carefully before using the software provided by GEO-GO LIMITED (the "Company"). By using the software, you agree to be bound by the terms of this License.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. GRANT OF LICENSE
          </Typography>
          <Typography variant="body1" paragraph>
            1.1 The Company grants you a non-exclusive, non-transferable license to use the software (the "Software") for personal, non-commercial use on your mobile device.
          </Typography>
          <Typography variant="body1" paragraph>
            1.2 You may not modify, copy, or distribute the Software without the prior written consent of the Company.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. USER OBLIGATIONS
          </Typography>
          <Typography variant="body1" paragraph>
            2.1 You agree to use the Software in accordance with all applicable laws and regulations.
          </Typography>
          <Typography variant="body1" paragraph>
            2.2 You will not use the Software for any unlawful purpose or in a manner that could damage, disable, overburden, or impair the Software.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. INTELLECTUAL PROPERTY
          </Typography>
          <Typography variant="body1" paragraph>
            3.1 The Company owns all rights, title, and interest in and to the Software, including all intellectual property rights.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. LIMITATION OF LIABILITY
          </Typography>
          <Typography variant="body1" paragraph>
            4.1 We are not liable for any loss or damage that was not caused by our breach of the License or our negligence.
          </Typography>
          <Typography variant="body1" paragraph>
            4.2 We are not liable for business losses. We only make the Software available for domestic and private use. If you use the Software for any commercial, business, or resale purpose, we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
          </Typography>
          <Typography variant="body1" paragraph>
            4.3 We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your device, computer equipment, programs, or data due to your use of the Software or to your downloading of any content on it, or on any website linked to it.
          </Typography>
          <Typography variant="body1" paragraph>
            4.4 We assume no responsibility for the content of websites linked on our Software. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. INDEMNITY
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to indemnify us in respect of any breach of this License by you. You will be liable to us for any loss or damage we suffer as a result of your breach.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. TERMINATION
          </Typography>
          <Typography variant="body1" paragraph>
            6.1 We may terminate this License immediately by written notice to you if you fail to comply with any of the terms of this License.
          </Typography>
          <Typography variant="body1" paragraph>
            6.2 Upon termination, you must cease all use of the Software and destroy all copies of the Software in your possession.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. COMMUNICATIONS BETWEEN US
          </Typography>
          <Typography variant="body1" paragraph>
            7.1 If you wish to contact us in writing, or if any condition in this License requires you to give us notice in writing, you can send this to us by email at support@geo-go-exec.com.
          </Typography>
          <Typography variant="body1" paragraph>
            7.2 If we have to contact you or give you notice in writing, we will do so by email or by pre-paid post to the address you provide to us in your order.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. USE OF PERSONAL INFORMATION
          </Typography>
          <Typography variant="body1" paragraph>
            8.1 We will use your personal information in accordance with our Privacy Policy. You agree that we may collect and use your personal information in accordance with our Privacy Policy.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. OTHER IMPORTANT TERMS
          </Typography>
          <Typography variant="body1" paragraph>
            9.1 We may transfer our rights and obligations under this License to another organization. We will always tell you in writing if this happens, and we will ensure that the transfer will not affect your rights under the License.
          </Typography>
          <Typography variant="body1" paragraph>
            9.2 You may not transfer your rights or obligations under this License to another person.
          </Typography>
          <Typography variant="body1" paragraph>
            9.3 This License does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this License.
          </Typography>
          <Typography variant="body1" paragraph>
            9.4 If a court finds part of this License illegal, the rest will continue in force. Each of the paragraphs of this License operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
          </Typography>
          <Typography variant="body1" paragraph>
            9.5 Even if we delay in enforcing this License, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under this License, or if we delay in taking steps against you in respect of your breaching this License, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. APPLICABLE LAW
          </Typography>
          <Typography variant="body1" paragraph>
            10.1 This License, its subject matter, and its formation (and any non-contractual disputes or claims) are governed by the laws of England and Wales. You and we both agree that the courts of England and Wales will have exclusive jurisdiction.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            **Effective Date: November 4, 2024**
          </Typography>
          <Typography variant="body1" paragraph>
            **GEO-GO LIMITED** is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our Geo Go app.
          </Typography>
          <Typography variant="h6" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            When you use Geo Go, we collect the following types of information:
          </Typography>
          <Typography variant="body1" paragraph>
            - **Personal Information:** Name, email address, phone number, and payment information.
          </Typography>
          <Typography variant="body1" paragraph>
            - **Usage Data:** Information about how you use the app, including your location data while you are using our services.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We may use the information we collect for the following purposes:
          </Typography>
          <Typography variant="body1" paragraph>
            - To provide and maintain our services.
          </Typography>
          <Typography variant="body1" paragraph>
            - To process your transactions and manage your account.
          </Typography>
          <Typography variant="body1" paragraph>
            - To improve our app and develop new services.
          </Typography>
          <Typography variant="body1" paragraph>
            - To communicate with you about your account and our services.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Disclosure of Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We may share your information in the following situations:
          </Typography>
          <Typography variant="body1" paragraph>
            - With service providers who assist us in operating our app.
          </Typography>
          <Typography variant="body1" paragraph>
            - To comply with legal obligations or respond to legal requests.
          </Typography>
          <Typography variant="body1" paragraph>
            - To protect our rights, privacy, safety, or property, or that of our users or others.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You have certain rights regarding your personal information, including the right to:
          </Typography>
          <Typography variant="body1" paragraph>
            - Access and obtain a copy of your personal information.
          </Typography>
          <Typography variant="body1" paragraph>
            - Request correction of inaccurate or incomplete information.
          </Typography>
          <Typography variant="body1" paragraph>
            - Request deletion of your personal information under certain conditions.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Security of Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We take reasonable measures to protect your personal information from loss, theft, and misuse. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact us at support@geo-go-exec.com.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Acceptable Use Policy
          </Typography>
          <Typography variant="body1" paragraph>
            **Effective Date: November 4, 2024**
          </Typography>
          <Typography variant="body1" paragraph>
            This Acceptable Use Policy (AUP) outlines acceptable behavior for users of the Geo Go app. By using our services, you agree to comply with this AUP.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. User Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to use the Geo Go app only for lawful purposes. You must not use the app:
          </Typography>
          <Typography variant="body1" paragraph>
            - To conduct any illegal activities or encourage others to do so.
          </Typography>
          <Typography variant="body1" paragraph>
            - To transmit or store any content that is offensive, abusive, or harmful.
          </Typography>
          <Typography variant="body1" paragraph>
            - To attempt to gain unauthorized access to any part of our service.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Prohibited Activities
          </Typography>
          <Typography variant="body1" paragraph>
            The following activities are strictly prohibited:
          </Typography>
          <Typography variant="body1" paragraph>
            - Sending unsolicited communications (spam) or phishing.
          </Typography>
          <Typography variant="body1" paragraph>
            - Interfering with or disrupting the security or integrity of the Geo Go app.
          </Typography>
          <Typography variant="body1" paragraph>
            - Impersonating any person or entity or falsely stating or misrepresenting your affiliation with any person or entity.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Enforcement
          </Typography>
          <Typography variant="body1" paragraph>
            Geo Go reserves the right to investigate any violations of this AUP and take appropriate action. This may include:
          </Typography>
          <Typography variant="body1" paragraph>
            - Warning or suspending accounts.
          </Typography>
          <Typography variant="body1" paragraph>
            - Terminating access to the app.
          </Typography>
          <Typography variant="body1" paragraph>
            - Reporting violations to law enforcement or other authorities.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Acceptable Use Policy from time to time. Any changes will be posted on this page with an updated effective date.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Additional Information:
          </Typography>
          <Typography variant="body1" paragraph>
            1. **Changes to Terms:** These terms may have changed since you last reviewed them. For a list of changes and when they were made, see Geo Go Terms.
          </Typography>
          <Typography variant="body1" paragraph>
            2. **Information About Us:** We provide a mobile app for fare-paying customers wishing to book a trip, which can be accessed by licensed private hire drivers to complete pre-ordered trips (“the service”). You can find everything you need to know about us, Geo-Go Limited, and our products on our website before you register for the app.
          </Typography>
          <Typography variant="body1" paragraph>
            3. **Age Restrictions:** There are age restrictions for use of this app. You agree that you are over the age of 18 years old on the date that you open an account with us.
          </Typography>
          <Typography variant="body1" paragraph>
            4. **Student Discount:** To qualify for our student discounts, students must register with their university email address. If a verifiable student email address is not used, you will not be able to register for the student discount.
          </Typography>
          <Typography variant="body1" paragraph>
            5. **Payment Information:** We charge you when you complete a trip. When you create a trip request and choose to book a trip, the payment is pre-authorized and only debited from your account when the trip is finished. Changes may be made to the price if (i) you are late to the pick-up, (ii) if you change the destination during the trip, or (iii) if you add stops. The payment is handled through Stripe, and by agreeing to our terms, you also agree to their terms. Stripe’s terms and conditions can be accessed here.
          </Typography>
          <Typography variant="body1" paragraph>
            6. **Refund Policy:** Once a trip has started, we are unable to issue refunds.
          </Typography>
          <Typography variant="body1" paragraph>
            7. **Delays:** If our supply of the service is delayed by an event outside our control, such as vehicle breakdown or traffic accidents, we will contact you as soon as possible to let you know. We don’t offer compensation for the delay, but if the delay is likely to be in excess of 1 hour past your scheduled pick-up, you can cancel the booking and receive a refund for any fees you have paid in advance.
          </Typography>
          <Typography variant="body1" paragraph>
            8. **Cancellation Policies:** If your driver is not at the pickup location, you can cancel your trip free of charge. If the driver is at the pickup location at the designated time, you will be charged a fixed fee. If the driver has waited over 3 minutes and you have not turned up, the driver can cancel the booking and receive the cancellation fee.
          </Typography>
          <Typography variant="body1" paragraph>
            9. **Safety Measures:** Your safety is important to us. We recommend that you check the driver’s vehicle number plate against the details in the app to make sure you are getting in the right car at all times. Once your trip is accepted by a driver, you will also be provided a code to confirm the right driver. The code will be shown on your driver’s screen. Please ensure that you check this before beginning your journey.
          </Typography>
          <Typography variant="body1" paragraph>
            10. **Changes to Terms:** We can always change the app to reflect changes in relevant laws and regulatory requirements, make minor technical adjustments and improvements, or update digital content to improve usability. We may suspend the supply of the app to deal with technical problems, make minor technical adjustments and improvements, or update digital content to improve usability.
          </Typography>
          <Typography variant="body1" paragraph>
            11. **Disclaimers:** We cannot guarantee that the app is free from bugs or viruses. You are responsible for configuring your information technology, computer programs, and platform to access our app. You should use your own virus protection software. We are not responsible for any loss or damage caused by a virus or distributed denial-of-service attack that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of our app or to your downloading of any content on it, or on any website linked to it.
            <Typography variant="h6" gutterBottom>
            12. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Geo-Go Limited</strong>
          </Typography>
          <Typography variant="body1" paragraph>
            1 Capital Quarter, Tyndall Street
          </Typography>
          <Typography variant="body1" paragraph>
            Cardiff, Wales, CF10 4BZ
          </Typography>
          <Typography variant="body1" paragraph>
            Email: <a href="mailto:support@geo-go-exec.com">support@geo-go-exec.com</a>
          </Typography>

          <Typography variant="h6" gutterBottom>
            Legal Disclaimer
          </Typography>
          <Typography variant="body1" paragraph>
            This Privacy Policy is provided for informational purposes only and does not constitute legal advice. While we strive to ensure the accuracy and completeness of the information herein, we make no representations or warranties of any kind, express or implied, about the accuracy, reliability, suitability, or availability of this information.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Acceptance of Policy
          </Typography>
          <Typography variant="body1" paragraph>
            By using our Services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy. If you do not agree to this policy, please do not use our Services.
          </Typography>

          <Typography variant="body1" paragraph>
            This Privacy Policy aims to provide you with a clear and transparent understanding of how Geo Go collects, uses, and protects your information. We are committed to maintaining your privacy and ensuring the security of your data. Thank you for choosing Geo Go!
          </Typography>

          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
