import { Grid, Typography } from "@mui/material";

interface IProps {
  backgroundColor: string;
  icon: any;
  color: string;
  title: string;
  value: string;
}
export function InfoPanel({
  backgroundColor,
  icon,
  color,
  title,
  value,
}: IProps) {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        backgroundColor: backgroundColor,
        height: 100,
        borderRadius: 5,
        padding: 2,
      }}
    >
      <Grid item container xs={12}>
        <Typography
          variant="h6"
          fontSize={12}
          fontWeight={"800"}
          sx={{ color: color }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignContent={"center"}
        alignItems={"center"}
        spacing={1}
      >
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography
            variant={"h6"}
            style={{ fontWeight: "200", fontFamily: "Roboto", color: color }}
            fontSize={20}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
