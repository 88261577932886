import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StateType } from "../models/BookingModels";
import UserDataModel from "../models/UserDataModel";
import { DriverModel } from "../models/DriverModel";
import moment from "moment";
import { db } from "./firebase_services";

export function useTools() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const theme = useTheme();

  return { navigate, dispatch, theme };
}

// Scroll to section
export const scrollToSection = (sectionId: string, setOpen: Function) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });
    setOpen(false);
  }
};

// Get current date time is used to get the current date and time, frequently used in data storage.
export function getCurrentDateTime() {
  let now = new Date();

  // Get the current UTC timestamp
  let utcTimestamp = now.getTime();

  // Define the time zone offset for UK time (in minutes)
  let ukTimeZoneOffset = 60; // UK is UTC+0 or UTC+1 depending on daylight saving time

  // Calculate the UK time by adding the time zone offset
  let ukTimestamp = utcTimestamp + ukTimeZoneOffset * 60 * 1000;

  // Create a new Date object using the UK timestamp
  let ukDateTime = new Date(ukTimestamp);

  return ukDateTime.toISOString();
}

export function getCurrentDateTimeAsDate() {
  let time = new Date();
  var offset = time.getTimezoneOffset();
  offset = Math.abs(offset / 60);
  time.setHours(time.getHours() + offset);

  return time;
}

// Function to ensure a useRef object is not undefined or null.
export function checkRefNotEmpty(ref: any) {
  return ref !== null && ref && ref.current && ref.current !== null;
}
export function getMiles(meters: number) {
  return meters * 0.000621371192;
}
export function getMeters(miles: number) {
  return miles * 1609.344;
}

export function getLastObjectOfList(list: any[]) {
  try {
    return list[list.length - 1];
  } catch (err) {
    console.error("Error getting last object of list: ", list);
  }
}

export function getStateTypeString(state: StateType): string {
  switch (state) {
    case StateType.FUTURE_TRIP_LOOKING_FOR_DRIVER:
    case StateType.AIRPORT_TRIP_LOOKING_FOR_DRIVER:
    case StateType.INSTANT_TRIP_LOOKING_FOR_DRIVER:
      return "Looking for driver";

    case StateType.FUTURE_TRIP_DRIVER_FOUND:
    case StateType.AIRPORT_TRIP_DRIVER_FOUND:
      return "Driver found";

    case StateType.GENERAL_DRIVER_ON_WAY:
      return "Driver on way";
    case StateType.GENERAL_DRIVER_FINISHING_TRIP:
      return "Driver finishing trip";

    case StateType.GENERAL_DRIVER_NOT_FOUND:
      return "Driver not found";

    case StateType.AIRPORT_DRIVER_ARRIVED_AT_PICKUP:
    case StateType.GENERAL_DRIVER_ARRIVED:
      return "Driver arrived";

    case StateType.GENERAL_TRIP_IN_PROGRESS:
      return "In progress";

    case StateType.AIRPORT_CUSTOMER_ARRIVED_AT_AIRPORT:
    case StateType.AIRPORT_DRIVER_ARRIVED_AT_AIRPORT:
      return "Arrived at airport";

    case StateType.GENERAL_ARRIVED_AT_WAYPOINT:
      return "Arrived at waypoint";

    case StateType.GENERAL_ARRIVED_AT_DESTINATION:
      return "Arrived at destination";

    case StateType.GENERAL_TRIP_CHANGED:
      return "Trip changed";

    case StateType.GENERAL_TRIP_FINISHED:
      return "Trip finished";

    case StateType.GENERAL_CUSTOMER_ENDED_EARLY:
    case StateType.GENERAL_DRIVER_ENDED_EARLY:
      return "Trip ended early";

    case StateType.GENERAL_CUSTOMER_LATE:
      return "Customer late";

    case StateType.GENERAL_CONFIRM_NEW_PRICE:
      return "Confirm price";

    case StateType.GENERAL_DRIVER_LEFT:
      return "Driver left";
    case StateType.GENERAL_DRIVER_NOT_CONFIRMED:
      return "Driver didn't confirm";
    default:
      return "Unknown state";
  }
}

export function getStateTypeDesc(state: string): string {
  switch (state) {
    case "Looking for driver":
      return "This trip became available for drivers to book.";

    case "Driver found":
      return "A driver joined this booked trip.";

    case "Driver on way":
      return "The driver started heading towards the trips origin. ";

    case "Driver arrived":
      return "The driver arrived and is waiting for the customer.";

    case "In progress":
      return "Customer has entered ride & journey has begun.";

    case "Arrived at airport":
      return "Customer has arrived at the airport for the first leg.";

    case "Arrived at waypoint":
      return "Customer has reached a waypoint, and driver awaits them.";

    case "Arrived at destination":
      return "Customer has arrrived at their destination.";

    case "Trip changed":
      return "Customer has requested changes to confirm.";

    case "Trip finished":
      return "One or both parties have pressed 'Go Home'.";

    case "Trip ended early":
      return "The driver or customer ended the trip early.";

    case "Customer late":
      return "The driver was waiting over 3 minutes for the customer.";

    case "Driver finishing trip":
      return "The driver accepted the trip, but is finishing another first.";

    case "Driver left":
      return "The driver had to leave the trip early.";
    case "Driver not found":
      return "The trip could not find a driver within 3 minutes of a search.";
    case "Driver didn't confirm":
      return "The assigned driver failed to confirm their availability.";
    default:
      return "Unknown state";
  }
}
export function isJourneyActive(state: StateType): boolean {
  return (
    state !== StateType.GENERAL_TRIP_FINISHED &&
    state !== StateType.GENERAL_CUSTOMER_ENDED_EARLY &&
    state !== StateType.GENERAL_DRIVER_ENDED_EARLY
  );
}

export function showActivityIndicator(state: StateType): boolean {
  return (
    state === StateType.FUTURE_TRIP_LOOKING_FOR_DRIVER ||
    state === StateType.AIRPORT_TRIP_LOOKING_FOR_DRIVER ||
    state === StateType.INSTANT_TRIP_LOOKING_FOR_DRIVER ||
    state === StateType.GENERAL_ARRIVED_AT_WAYPOINT
  );
}

export function getDateOfTrip(trip: any) {
  return trip.dateTime
    ? trip.dateTime
    : trip.pickupDateTime
    ? trip.pickupDateTime
    : trip.creationDateTime;
}

export function checkPageTwoDetails(
  firstName: string,
  setFirstNameError: Function,
  lastName: string,
  setLastNameError: Function,
  phoneNumber: string,
  setPhoneNumberError: Function,
  callback: Function
) {
  let numErrors = 0;

  //Name validation
  if (firstName === "") {
    setFirstNameError("First name must not be empty");
    numErrors += 1;
  } else if (firstName.length > 20 || firstName.length < 2) {
    setFirstNameError("First name length must be between 2-20 characters.");
    numErrors += 1;
  } else if (!/^[A-Za-z]*/.test(firstName)) {
    setFirstNameError("First name must be text only.");
  } else {
    setFirstNameError("");
  }
  if (lastName === "") {
    setLastNameError("Surname must not be empty");
    numErrors += 1;
  } else if (lastName.length > 30 || lastName.length < 2) {
    setLastNameError("Surname length must be between 2-30 characters.");
    numErrors += 1;
  } else if (!/^[A-Za-z]*/.test(lastName)) {
    setLastNameError("Surname must be text only.");
  } else {
    setLastNameError("");
  }

  // Phone number validation
  if (phoneNumber === "") {
    setPhoneNumberError("Phone number must not be empty.");
    numErrors += 1;
  } else if (phoneNumber.length !== 101) {
    setPhoneNumberError(
      "Phone number must be a valid UK number. E.g +44 7123456789"
    );
    numErrors += 1;
  } else if (!/^[0-9]*$/.test(phoneNumber)) {
    setPhoneNumberError("Phone number must be numbers only.");
    numErrors += 1;
  } else if (phoneNumber[0] === "0") {
    setPhoneNumberError("Phone number must not contain '0' at the beginnning.");
  } else {
    setPhoneNumberError("");
  }

  if (numErrors === 0) {
    callback();
  }
}

export function secondsToHms(d: number) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " Min" : " Mins") : "";
  return hDisplay + mDisplay;
}

export async function createStripeAccount(
  driverId: string,
  driver: DriverModel,
  ip: string,
  dateOfBirth: Date,
  addressLine1: string,
  addressLine2: string,
  postCode: string,
  city: string,
  day: number,
  year: number,
  month: number
) {
  const body = {
    driverId: driverId,
    firstName: driver.firstName,
    lastName: driver.lastName,
    emailAddress: driver.emailAddress,
    phoneNumber: driver.phoneNumber,
    ip: ip,
    dateOfBirth,
    addressLine1,
    addressLine2,
    postCode,
    city,
    day,
    year,
    month,
  };

  await fetch(
    "https://us-central1-geo-go-app.cloudfunctions.net/createDriverConnectAccount",
    {
      mode: "no-cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }
  );

  return;
}

export async function deleteStripeAccount(
  stripeAccountId: string,
  driverId: string
) {
  const body = {
    stripeAccountId: stripeAccountId,
    driverId: driverId,
  };

  await fetch(
    "https://us-central1-geo-go-app.cloudfunctions.net/deleteDriverConnectAccount",
    {
      mode: "no-cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }
  );

  db.ref("/users/" + driverId + "/stripeAccountId").remove();

  return;
}
