import { useEffect, useState } from "react";
import { useTools } from "../../services/general_services";
import { setPage } from "../../store/slices/general.slice";
import { PageType } from "../../models/PageType";
import { Grid, Typography, CircularProgress } from "@mui/material";
import DarkTextField from "../components/general/TextFieldDarker";
import { useSelector } from "react-redux";
import { RideGrid } from "../components/rides/RideGrid";
import { TimeFrameSelect } from "../components/general/TimeFrameSelect";
import { PayoutAppModel } from "../../models/PayoutModel";
import { PayoutGrid } from "../components/payouts/PayoutGrid";

export function PayoutScreen() {
  const { dispatch, navigate } = useTools();
  const { payouts, payoutsLoading, payoutsError } = useSelector(
    (state: any) => state.PayoutSlice
  );

  useEffect(() => {
    dispatch(setPage(PageType.PAYOUTS));
  }, []);

  const [filteredList, setFilteredList] = useState<PayoutAppModel[]>([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (payouts) {
      setFilteredList(payouts);
    }
  }, [payouts]);

  useEffect(() => {}, []);
  return (
    <Grid
      container
      item
      xs={12}
      sx={{ padding: 2 }}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      {/* Top section - Search & Filter */}
      <Grid item container xs={12} alignItems={"center"}>
        <Grid item container xs={9} alignItems={"center"}>
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            style={{ marginRight: 30 }}
          >
            Payouts
          </Typography>

          <DarkTextField
            placeholder="Search Rides..."
            value={searchValue}
            onChange={(e) => {
              console.log("uhh ");
              setSearchValue(e.target.value);

              let searchVal = e.target.value;
              console.log(searchVal);
              if (e.target.value === "") {
                console.log("empty...");
                setFilteredList(payouts);
              } else {
                console.log("not empty");
                let payout_list: PayoutAppModel[] = payouts ? payouts : [];

                let searchVal = e.target.value;
                setFilteredList(
                  payout_list.filter((val) => {
                    if (
                      val.driver.firstName
                        .toLowerCase()
                        .includes(searchVal.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.driver.lastName
                        .toLowerCase()
                        .includes(searchVal.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                );
              }
            }}
            style={{
              width: "200px",
              borderRadius: 30,
              marginRight: 30,
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          />

          <TimeFrameSelect />
        </Grid>
        {/* Bottom section - Driver Grid */}
        <Grid
          item
          container
          xs={12}
          sx={{ marginTop: 5 }}
          justifyContent={"center"}
        >
          {payoutsError ? (
            <CircularProgress />
          ) : (
            <Grid item container>
              <PayoutGrid rows={filteredList} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
