//Methods for firebase connectivity

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";

// Config object for firebase...
const firebaseConfig = {
  apiKey: "AIzaSyB2dJwVSthC_keKkbNF43j0SZ9hm9XGx8c",
  authDomain: "geo-go-app.firebaseapp.com",
  databaseURL:
    "https://geo-go-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "geo-go-app",
  storageBucket: "geo-go-app.appspot.com",
  messagingSenderId: "918841208617",
  appId: "1:918841208617:web:6bb0d386721ce3a5a41138",
  measurementId: "G-RS7LXH1CSM",
};

// If firebase isn't initialized, intialize it for connection.
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Db used to access realtime database
const db = firebase.database();

// Auth used to authenticate users
const auth = firebase.auth();

// Storage used to retrieve/store images
const storage = firebase.storage();

auth.setPersistence("session");

export { db, auth, storage };

export enum TypeModel {
  LIST = "LIST",
  OBJECT = "OBJECT",
}
export async function saveDataToFirebase(
  path: string,
  data: any,
  type: TypeModel,
  identifier?: string
) {
  try {
    if (type === "LIST") {
      var pushedRef = await db.ref(path).push(data);

      if (identifier) {
        let newObj = {
          ...data,
          [identifier]: pushedRef.key,
        };

        await db.ref(path + pushedRef.key).set(newObj);
      }
      return pushedRef.key;
    } else {
      await db.ref(path).set(data);

      return path.split("/")[path.split("/").length - 1];
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getDataFromFirebase(path: string) {
  let data = db.ref(path);

  let data_retrieved = await data.once("value");

  if (data_retrieved.val() && data_retrieved.val() !== null) {
    return data_retrieved.val();
  }
}

export async function removeDataFromFirebase(path: string) {
  try {
    await db.ref(path).remove();
  } catch (error) {
    console.log(error);
  }
}
