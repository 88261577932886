import {
  BookingModel,
  GeneralAppDriver,
  GeneralDriver,
  StateType,
  TripType,
  UserBookingModel,
} from "../models/BookingModels";
import { db } from "./firebase_services";

export async function saveBookingForUser(
  bookingToSave: BookingModel,
  userId: string
) {
  await db
    .ref("/booking_data/user_bookings/" + userId + "/" + bookingToSave.id)
    .set(bookingToSave);
}

export async function getDriversFromList(
  driversResponded: GeneralDriver[] | undefined
) {
  let driversApp: GeneralAppDriver[] = [];
  if (driversResponded) {
    for (let i = 0; i < driversResponded?.length; i++) {
      let driverDb = db.ref("users/" + driversResponded[i].driverId);

      let driverFound = await driverDb.once("value");

      if (driverFound.val() !== null) {
        driversApp.push({
          active: driversResponded[i].active,
          driver: driverFound.val(),
        });
      } else {
        console.log(
          "Driver not found at ",
          "users/" + driversResponded[i].driverId
        );
      }
      if (i === driversResponded.length - 1) {
        return driversApp;
      }
    }
  }
}

export async function isUserBanned(id: string) {
  try {
    let banned_user = db.ref("banned_users/" + id);

    console.log((await banned_user.once("value")).val());
    return (await banned_user.once("value")).val() !== null;
  } catch (err: any) {
    return false;
  }
}
