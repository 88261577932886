import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CarClassModel } from "../../models/CarClassModel";
import { getCarClassList } from "../thunks/carClass.thunks";

/**
 * userId - The user ID belonging to the logged in user
 * userData - The data belonging to the logged in user (UserDataModel)
 * userDataSearching - If the user data is loading
 * userDataError - If the user data had an error loading.
 */
interface IProps {
  carClassList: CarClassModel[];
  carClassListLoading: boolean;
  carClassListError: boolean;
  selectedEditCarClass: CarClassModel | undefined;
  createNewCarClass: boolean;
}

const carClassInitialState: IProps = {
  carClassList: [],
  carClassListError: false,
  carClassListLoading: false,
  selectedEditCarClass: undefined,
  createNewCarClass: false,
};

const carClassSlice: any = createSlice({
  name: "carClass_data",
  initialState: carClassInitialState,
  reducers: {
    // Setter functions
    setCarClassList: (
      state: IProps,
      { payload }: PayloadAction<CarClassModel[]>
    ) => {
      state.carClassList = payload;
    },
    setCarClassListLoading: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.carClassListLoading = payload;
    },
    setCarClassListError: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.carClassListError = payload;
    },
    setSelectedEditCarClass: (
      state: IProps,
      { payload }: PayloadAction<CarClassModel>
    ) => {
      state.selectedEditCarClass = payload;
    },
    setCreateNewCarClass: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.createNewCarClass = payload;
    },
  },

  // Reducers to tell react which states to update with the thunk functions.
  extraReducers: (builder: any) => {
    builder.addCase(getCarClassList.rejected, (state: IProps) => {
      state.carClassListLoading = false;
      state.carClassListError = true;
    });
    builder.addCase(getCarClassList.pending, (state: IProps) => {
      state.carClassListLoading = true;
      state.carClassListError = false;
    });
    builder.addCase(
      getCarClassList.fulfilled,
      (state: IProps, { payload }: PayloadAction<CarClassModel[]>) => {
        state.carClassListError = false;
        state.carClassListLoading = false;

        state.carClassList = payload;
      }
    );
  },
});

export const {
  setCarClassList,
  setCarClassListError,
  setCarClassListLoading,
  setSelectedEditCarClass,
  setCreateNewCarClass,
} = carClassSlice.actions;

export default carClassSlice.reducer;
