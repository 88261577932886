import { useEffect, useState } from "react";
import { PageType } from "../../models/PageType";
import { Grid, Typography } from "@mui/material";
import { primaryColor } from "../../assets/style/theme";

interface NavButtonProps {
  buttonPage: PageType;
  page: PageType;
  onClick: Function;
  imgSrc: string;
  hoveredImgSrc: string;
  text: string;
}

export function NavigationButton({
  buttonPage,
  page,
  onClick,
  imgSrc,
  hoveredImgSrc,
  text,
}: NavButtonProps) {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setHovered(false);
  }, [page]);
  // /static/clients_white.png
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={"flex-end"}
      style={{ position: "relative" }}
    >
      <Grid
        item
        container
        xs={11}
        style={{
          paddingLeft: 10,
          cursor: "pointer",
          backgroundColor: buttonPage === page ? "white" : "transparent",
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
          padding: 10,
          transition: "10",
        }}
        alignItems={"center"}
        onClick={() => {
          setHovered(false);
          onClick();
        }}
        onMouseOver={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          if (buttonPage !== page) {
            setHovered(false);
          }
        }}
      >
        <Grid item container xs={3} sm={2} justifyContent={"center"}>
          <img
            src={hovered || buttonPage === page ? hoveredImgSrc : imgSrc}
            style={{ height: 25, width: 25 }}
            alt={text + " Icon"}
          />
        </Grid>
        <Grid
          item
          container
          xs={9}
          sm={10}
          justifyContent={"flex-start"}
          style={{ paddingLeft: 10 }}
        >
          <Typography
            variant="h6"
            fontSize={18}
            style={{
              color: hovered || buttonPage === page ? primaryColor : "white",
            }}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
      {buttonPage === page && (
        <>
          <img
            src={"/static/nav_selected_top.png"}
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              right: 0,
              top: -30,
            }}
          />
          <img
            src={"/static/nav_selected_bottom.png"}
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              right: 0,
              bottom: -30,
            }}
          />
        </>
      )}
    </Grid>
  );
}
