import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { da } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { TimeFrame } from "../../../models/TimeFrameModel";
import moment from "moment";
import { RideAppModel, TripType } from "../../../models/BookingModels";
import { Grid, Typography } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

export function DailyChart() {
  const { timeframe } = useSelector((state: any) => state.GeneralSlice);
  const {
    trips,
    earnings,
    numTrips,
    grossProfit,
    netProfit,
    preCancelled,
    numBooked,
    completed,
    cancelled,
    inProgress,
    upcoming,
  } = useSelector((state: any) => state.BookingSlice);

  const [preCancelledLocal, setPreCancelledLocal] = useState([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [cancelledLocal, setCancelledLocal] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [bookedLocal, setBookedLocal] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [completedLocal, setCompletedLocal] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [upcomingLocal, setUpcomingLocal] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [inProgressLocal, setInProgressLocal] = useState([0, 0, 0, 0, 0, 0, 0]);

  const [labels, setLabels] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const data = {
    labels,
    datasets: [
      {
        label: "Pre-Cancelled",
        data: [100, 2000, 300],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Booked",
        data: [2000, 2030, 1000],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Completed",
        data: [2000, 2030, 100],
        borderColor: "rgb(03, 255, 100)",
        backgroundColor: "rgba(0, 355, 100, 0.5)",
      },
      {
        label: "Cancelled",
        data: [2000, 300, 400],
        borderColor: "rgb(255, 100, 0)",
        backgroundColor: "rgba(255, 100, 0, 0.5)",
      },
      {
        label: "Upcoming",
        data: [2000, 300, 400],
        borderColor: "rgb(255, 200, 0)",
        backgroundColor: "rgba(255, 200, 0, 0.5)",
      },
      {
        label: "In Progress",
        data: [2000, 300, 400],
        borderColor: "rgb(55, 120, 100)",
        backgroundColor: "rgba(55, 120, 100, 0.5)",
      },
    ],
  };
  useEffect(() => {
    // We need to get the data for this timeframe
    let interval_loops = 12;
    let interval_between = -3;
    let interval_format = "hours";
    if (timeframe === TimeFrame.DAILY) {
      interval_loops = 8;
      interval_between = -3;
      interval_format = "hours";
    } else if (timeframe === TimeFrame.WEEKLY) {
      interval_loops = 7;
      interval_between = -1;
      interval_format = "day";
    } else if (timeframe === TimeFrame.MONTHLY) {
      interval_loops = 4;
      interval_between = -1;
      interval_format = "week";
    } else if (timeframe === TimeFrame.YEARLY) {
      interval_loops = 12;
      interval_between = -1;
      interval_format = "month";
    } else if (timeframe === TimeFrame.ALL) {
      interval_loops = 12;
      interval_between = -1;
      interval_format = "month";
    }
    // Break up into 12 for each two hour of prior day.
    let pre_cancelled_data_list: number[] = [];
    let cancelled_data_list: number[] = [];
    let completed_data_list: number[] = [];
    let in_progress_data_list: number[] = [];
    let upcoming_data_list: number[] = [];
    let booked_data_list: number[] = [];
    let label_list: string[] = [];

    // Loop 7 times, check dates in last 24 hours - Add to list - reverse?
    let date_to_check = new Date().toISOString();

    for (let i = 0; i < interval_loops; i++) {
      // Get each type for this day
      let day_prior = moment(date_to_check)
        .add(interval_between, interval_format as any)
        .toDate()
        .toISOString();

      if (timeframe === TimeFrame.DAILY) {
        let label =
          moment(date_to_check).format("HHa") +
          " - " +
          moment(day_prior).format("HHa");

        console.log(date_to_check);
        console.log(day_prior);
        label_list.push(label);
      } else if (timeframe === TimeFrame.WEEKLY) {
        let label =
          moment(date_to_check).format("Do") +
          " - " +
          moment(day_prior).format("Do");

        console.log(date_to_check);
        console.log(day_prior);
        label_list.push(label);
      } else if (timeframe === TimeFrame.MONTHLY) {
        let label =
          moment(date_to_check).format("Do") +
          " - " +
          moment(day_prior).format("Do MMM");

        label_list.push(label);
      } else if (
        timeframe === TimeFrame.YEARLY ||
        timeframe === TimeFrame.ALL
      ) {
        let label =
          moment(date_to_check).format("MMM YY") +
          " - " +
          moment(day_prior).format("MMM YY");
        label_list.push(label);
      }

      pre_cancelled_data_list.push(
        preCancelled
          ? preCancelled.filter(
              (val: { trip: RideAppModel; type: TripType; id: string }) => {
                let trip_date = val.trip.dateTime
                  ? val.trip.dateTime
                  : val.trip.creationDateTime;
                //  Limit to today only
                if (
                  moment(trip_date).isBefore(moment(date_to_check)) &&
                  moment(trip_date).isAfter(moment(day_prior))
                ) {
                  return val;
                }
              }
            ).length
          : 0
      );

      pre_cancelled_data_list = pre_cancelled_data_list;
      setPreCancelledLocal(pre_cancelled_data_list);

      completed_data_list.push(
        completed
          ? completed.filter(
              (val: { trip: RideAppModel; type: TripType; id: string }) => {
                let trip_date = val.trip.dateTime
                  ? val.trip.dateTime
                  : val.trip.creationDateTime;
                //  Limit to today only
                if (
                  moment(trip_date).isBefore(moment(date_to_check)) &&
                  moment(trip_date).isAfter(moment(day_prior))
                ) {
                  return val;
                }
              }
            ).length
          : 0
      );

      completed_data_list = completed_data_list;
      setCompletedLocal(completed_data_list);

      cancelled_data_list.push(
        cancelled
          ? cancelled.filter(
              (val: { trip: RideAppModel; type: TripType; id: string }) => {
                let trip_date = val.trip.dateTime
                  ? val.trip.dateTime
                  : val.trip.creationDateTime;
                //  Limit to today only
                if (
                  moment(trip_date).isBefore(moment(date_to_check)) &&
                  moment(trip_date).isAfter(moment(day_prior))
                ) {
                  return val;
                }
              }
            ).length
          : 0
      );

      cancelled_data_list = cancelled_data_list;
      setCancelledLocal(cancelled_data_list);

      booked_data_list.push(
        numBooked
          ? numBooked.filter(
              (val: { trip: RideAppModel; type: TripType; id: string }) => {
                let trip_date = val.trip.dateTime
                  ? val.trip.dateTime
                  : val.trip.creationDateTime;
                //  Limit to today only
                if (
                  moment(trip_date).isBefore(moment(date_to_check)) &&
                  moment(trip_date).isAfter(moment(day_prior))
                ) {
                  console.log("MATCH :", val, date_to_check, day_prior);

                  return val;
                }
              }
            ).length
          : 0
      );

      booked_data_list = booked_data_list;
      setBookedLocal(booked_data_list);

      upcoming_data_list.push(
        upcoming
          ? upcoming.filter(
              (val: { trip: RideAppModel; type: TripType; id: string }) => {
                let trip_date = val.trip.dateTime
                  ? val.trip.dateTime
                  : val.trip.creationDateTime;
                //  Limit to today only
                if (
                  moment(trip_date).isBefore(moment(date_to_check)) &&
                  moment(trip_date).isAfter(moment(day_prior))
                ) {
                  return val;
                }
              }
            ).length
          : 0
      );

      setUpcomingLocal(upcoming_data_list);

      in_progress_data_list.push(
        inProgress
          ? inProgress.filter(
              (val: { trip: RideAppModel; type: TripType; id: string }) => {
                let trip_date = val.trip.dateTime
                  ? val.trip.dateTime
                  : val.trip.creationDateTime;
                //  Limit to today only
                if (
                  moment(trip_date).isBefore(moment(date_to_check)) &&
                  moment(trip_date).isAfter(moment(day_prior))
                ) {
                  return val;
                }
              }
            ).length
          : 0
      );

      setInProgressLocal(in_progress_data_list);

      date_to_check = moment(date_to_check)
        .add(interval_between, interval_format as any)
        .toDate()
        .toISOString();
    }

    console.log(label_list);
    setLabels(label_list);
  }, [timeframe, preCancelled, completed]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#F7F9FC",
        borderRadius: 2,
        padding: 2,
        paddingTop: 4,
        minHeight: 200,
      }}
      justifyContent={"center"}
    >
      <Typography variant="h5" style={{ marginBottom: -5 }}>
        Bookings Overview
      </Typography>
      <Line
        style={{ marginTop: 0 }}
        title="Bookings Overview"
        options={options}
        data={{
          ...data,
          labels: labels,
          datasets: data.datasets.map((val, index) => {
            switch (index) {
              case 0:
                return { ...val, data: preCancelledLocal };
              case 1:
                return { ...val, data: bookedLocal };
              case 2:
                return { ...val, data: completedLocal };
              case 3:
                return { ...val, data: cancelledLocal };

              case 4:
                return { ...val, data: upcomingLocal };
              case 5:
                return { ...val, data: inProgressLocal };
              default:
                return { ...val, data: [] };
            }
          }),
        }}
      />
    </Grid>
  );
}
