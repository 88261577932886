import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DriverAndDocModel, DriverModel } from "../../models/DriverModel";
import {
  getDriverById,
  getDriverDocumentList,
  getDriverList,
} from "../thunks/driver.thunks";
import { RideAppModel, TripType } from "../../models/BookingModels";
import { findDriverTrips } from "../thunks/booking.thunks";

/**
 * userId - The user ID belonging to the logged in user
 * userData - The data belonging to the logged in user (UserDataModel)
 * userDataSearching - If the user data is loading
 * userDataError - If the user data had an error loading.
 */
interface IProps {
  selectedViewDriver: DriverModel | undefined;
  selectedViewDriverLoading: boolean;
  selectedViewDriverError: boolean;
  selectedViewDriverTrips: {
    trips: Array<{
      trip: RideAppModel;
      type: TripType;
      id: string;
    }>;
  };
  selectedViewDriverTripsLoading: boolean;
  selectedViewDriverTripsError: boolean;
  selectedEditDriver: DriverModel | undefined;
  driverList: DriverModel[];
  driverDocList: DriverAndDocModel[];
  driverDocListLoading: boolean;
  driverDocListError: boolean;
  driverListLoading: boolean;
  driverListError: boolean;
  createNewDriver: boolean;
}

const driverInitialState: IProps = {
  selectedViewDriver: undefined,
  selectedViewDriverLoading: false,
  selectedViewDriverError: false,
  selectedViewDriverTrips: { trips: [] },
  selectedViewDriverTripsLoading: false,
  selectedViewDriverTripsError: false,
  selectedEditDriver: undefined,
  driverList: [],
  driverListError: false,
  createNewDriver: false,
  driverListLoading: false,
  driverDocList: [],
  driverDocListError: false,
  driverDocListLoading: false,
};

const driverSlice: any = createSlice({
  name: "driver_data",
  initialState: driverInitialState,
  reducers: {
    // Setter functions
    setSelectedViewDriver: (
      state: IProps,
      { payload }: PayloadAction<DriverModel>
    ) => {
      state.selectedViewDriver = payload;
    },
    setSelectedViewDriverLoading: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.selectedViewDriverLoading = payload;
    },
    setSelectedViewDriverTrips: (
      state: IProps,
      {
        payload,
      }: PayloadAction<{
        trips: Array<{
          trip: RideAppModel;
          type: TripType;
          id: string;
        }>;
      }>
    ) => {
      state.selectedViewDriverTrips = payload;
    },
    setSelectedViewDriverError: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.selectedViewDriverError = payload;
    },
    setCreateNewDriver: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.createNewDriver = payload;
    },
    setSelectedEditDriver: (
      state: IProps,
      { payload }: PayloadAction<DriverModel>
    ) => {
      state.selectedEditDriver = payload;
    },
    setDriverList: (
      state: IProps,
      { payload }: PayloadAction<DriverModel[]>
    ) => {
      state.driverList = payload;
    },
    setDriverListLoading: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.driverListLoading = payload;
    },
    setDriverListError: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.driverListError = payload;
    },
  },

  // Reducers to tell react which states to update with the thunk functions.
  extraReducers: (builder: any) => {
    builder.addCase(getDriverList.rejected, (state: IProps) => {
      state.driverListLoading = false;
      state.driverListError = true;
    });
    builder.addCase(getDriverList.pending, (state: IProps) => {
      state.driverListLoading = true;
      state.driverListError = false;
    });
    builder.addCase(
      getDriverList.fulfilled,
      (state: IProps, { payload }: PayloadAction<DriverModel[]>) => {
        state.driverListError = false;
        state.driverListLoading = false;

        state.driverList = payload;
      }
    );
    builder.addCase(getDriverDocumentList.rejected, (state: IProps) => {
      state.driverDocListLoading = false;
      state.driverDocListError = true;
    });
    builder.addCase(getDriverDocumentList.pending, (state: IProps) => {
      state.driverDocListLoading = true;
      state.driverDocListError = false;
    });
    builder.addCase(
      getDriverDocumentList.fulfilled,
      (state: IProps, { payload }: PayloadAction<DriverAndDocModel[]>) => {
        state.driverDocListError = false;
        state.driverDocListLoading = false;

        state.driverDocList = payload;
      }
    );
    builder.addCase(getDriverById.rejected, (state: IProps) => {
      state.selectedViewDriverError = true;
      state.selectedViewDriverLoading = false;
    });
    builder.addCase(getDriverById.pending, (state: IProps) => {
      state.selectedViewDriverLoading = true;
      state.selectedViewDriverError = false;
    });
    builder.addCase(
      getDriverById.fulfilled,
      (state: IProps, { payload }: PayloadAction<DriverModel>) => {
        state.selectedViewDriverError = false;
        state.selectedViewDriverLoading = false;

        state.selectedViewDriver = payload;
      }
    );

    builder.addCase(findDriverTrips.rejected, (state: IProps) => {
      state.selectedViewDriverTripsError = true;
      state.selectedViewDriverTripsLoading = false;
    });
    builder.addCase(findDriverTrips.pending, (state: IProps) => {
      state.selectedViewDriverTripsLoading = true;
      state.selectedViewDriverTripsError = false;
    });
    builder.addCase(
      findDriverTrips.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<{
          trips: Array<{
            trip: RideAppModel;
            type: TripType;
            id: string;
          }>;
        }>
      ) => {
        state.selectedViewDriverTripsError = false;
        state.selectedViewDriverTripsLoading = false;

        if (payload && payload.trips) {
          state.selectedViewDriverTrips = {
            trips: payload.trips.sort(
              (
                a: {
                  trip: RideAppModel;
                  type: TripType;
                  id: string;
                },
                b: {
                  trip: RideAppModel;
                  type: TripType;
                  id: string;
                }
              ) => {
                return (
                  // @ts-ignore
                  new Date(b.trip.creationDateTime) -
                  // @ts-ignore
                  new Date(a.trip.creationDateTime)
                );
              }
            ),
          };
        }
      }
    );
  },
});

export const {
  setSelectedEditDriver,
  setSelectedViewDriver,
  setDriverList,
  setDriverListError,
  setDriverListLoading,
  setCreateNewDriver,
} = driverSlice.actions;

export default driverSlice.reducer;
