import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VoucherModel } from "../../../models/VoucherModel";
import { primaryColor } from "../../../assets/style/theme";
import { Cancel, Close, Delete, Save } from "@mui/icons-material";
import ReactImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { db, storage } from "../../../services/firebase_services";
import { DriverModalType } from "../../../models/DriverModelType";
import { styles } from "../../../assets/style/styles";
import {
  setVoucherList,
  setCreateNewVoucher,
  setSelectedEditVoucher,
} from "../../../store/slices/voucher.slice";
import { getCurrentDateTime } from "../../../services/general_services";

interface IProps {
  type: DriverModalType;
}

export function VoucherModal() {
  const {
    voucherList,
    voucherListLoading,
    selectedEditVoucher,
    createNewVoucher,
  } = useSelector((state: any) => state.VoucherSlice);

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountPercentError, setDiscountPercentError] = useState("");

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const [confirmDeletion, setConfirmDeletion] = useState(false);

  useEffect(() => {
    if (selectedEditVoucher) {
      selectedEditVoucher.name && setName(selectedEditVoucher.name);
      selectedEditVoucher.code && setCode(selectedEditVoucher.code);
      selectedEditVoucher.discountPercent &&
        setDiscountPercent(selectedEditVoucher.discountPercent);
    } else {
      clearStates();
    }
  }, [selectedEditVoucher]);

  function clearStates() {
    setName("");
    setNameError("");
    setCode("");
    setCodeError("");
    setDiscountPercent(0);
    setDiscountPercentError("");
  }

  async function validateVoucher() {
    let numErrors = 0;

    if (name === "") {
      setNameError("Name must not be empty");
      numErrors += 1;
    } else if ((name && name.length > 20) || name.length < 2) {
      setNameError("Name length must be between 2-20 characters.");
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(name)) {
      setNameError("Name must be text only.");
      numErrors += 1;
    } else {
      setNameError("");
    }

    if (code === "") {
      setCodeError("Code must not be empty");
      numErrors += 1;
    } else if ((code && code.length > 20) || code.length < 2) {
      setCodeError("Code length must be between 2-20 characters.");
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(code)) {
      setCodeError("Code must be text only.");
      numErrors += 1;
    } else {
      setCodeError("");
    }

    // Base Price
    if (isNaN(discountPercent)) {
      setDiscountPercentError("Value must be a number above 0.");
      numErrors += 1;
    } else if (discountPercent <= 0) {
      setDiscountPercentError("Value must be a number above 0.");
      numErrors += 1;
    } else {
      setDiscountPercentError("");
    }

    if (numErrors !== 0) {
      toast.error("Errors within voucher data");
    } else {
      if (createNewVoucher) {
        // Create Voucher
        createVoucher();
      } else {
        alterVoucher();
      }
    }
  }

  async function alterVoucher() {
    try {
      const id = selectedEditVoucher.id;

      let voucherObj: VoucherModel = {
        id,
        name,
        code,
        discountPercent,
        creationDate: selectedEditVoucher.creationDate,
      };

      dispatch(
        setVoucherList([
          ...voucherList.map((val: any) => {
            if (val.id === voucherObj.id) {
              return voucherObj;
            } else {
              return val;
            }
          }),
        ])
      );
      db.ref("/vouchers/" + selectedEditVoucher.id).set(voucherObj);
      toast.success("Voucher successfully updated");
      dispatch(setSelectedEditVoucher(undefined));
    } catch (err) {
      toast.error("Something went wrong there...");
      console.log(err);
    }
  }

  async function createVoucher() {
    try {
      let voucherObj: VoucherModel = {
        id: "id",
        name,
        code,
        discountPercent,
        creationDate: getCurrentDateTime(),
      };

      let ref = await db.ref("/vouchers/").push(voucherObj);

      let id = ref.key;

      if (id) {
        dispatch(
          setVoucherList([
            ...voucherList.map((val: any) => {
              if (val.id === voucherObj.id) {
                return voucherObj;
              } else {
                return val;
              }
            }),
          ])
        );
        voucherObj = { ...voucherObj, id };

        db.ref("/vouchers/" + id).set(voucherObj);

        toast.success("Voucher successfully created");
        dispatch(setCreateNewVoucher(undefined));
        dispatch(setVoucherList([...voucherList, voucherObj]));
      }
    } catch (err) {
      toast.error("Something went wrong there...");
      console.log(err);
    }
  }

  async function deleteVouchers() {
    if (selectedEditVoucher) {
      setConfirmDeletion(false);
      db.ref("/vouchers/" + selectedEditVoucher.id).remove();
      dispatch(
        setVoucherList(
          voucherList.filter(
            (val: VoucherModel) => val.id !== selectedEditVoucher.id
          )
        )
      );
      dispatch(setSelectedEditVoucher(undefined));

      clearStates();
    } else {
      toast.error("Please re-open this voucher to delete it.");
    }
  }

  return (
    <Fade in={selectedEditVoucher !== undefined || createNewVoucher === true}>
      <Grid
        sx={{
          ...styles.modalBackdrop,
        }}
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        {voucherListLoading ? (
          <CircularProgress />
        ) : !confirmDeletion ? (
          <Grid
            item
            container
            xs={11}
            sm={5}
            alignItems={"flex-start"}
            spacing={1}
            sx={{
              ...styles.modal,
            }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{ height: 40 }}
              justifyContent={"space-between"}
            >
              <Typography variant={"h6"}>
                {selectedEditVoucher !== undefined
                  ? "Edit Voucher"
                  : "Create Voucher"}
              </Typography>

              <IconButton
                onClick={() => {
                  dispatch(setSelectedEditVoucher(undefined));
                  dispatch(setCreateNewVoucher(undefined));
                  clearStates();
                }}
              >
                <Close />
              </IconButton>
            </Grid>
            <Grid item container xs={12}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                NAME:
              </Typography>
              <TextField
                placeholder="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: nameError ? 5 : 10,
                }}
              />

              {nameError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {nameError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                CODE
              </Typography>
              <TextField
                placeholder="Code..."
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: codeError ? 5 : 10,
                }}
              />

              {codeError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {codeError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                DISCOUNT PERCENTAGE
              </Typography>
              <Grid item container xs={12}>
                <Grid item container xs={12}>
                  <TextField
                    placeholder="Discount Percentage"
                    value={discountPercent}
                    type="number"
                    onChange={(e) => {
                      if (e.target.value.includes(".")) {
                        setDiscountPercent(Number.parseFloat(e.target.value));
                      } else {
                        setDiscountPercent(Number.parseInt(e.target.value));
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography>%</Typography>
                        </InputAdornment>
                      ),
                    }}
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      marginBottom: discountPercentError ? 5 : 10,
                    }}
                  />
                </Grid>{" "}
                {discountPercentError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {discountPercentError}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  xs={12}
                  alignItems={"center"}
                  marginTop={2}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    {selectedEditVoucher &&
                      selectedEditVoucher.code !== "STUDENT" && (
                        <Button
                          variant={"contained"}
                          startIcon={<Delete />}
                          style={{
                            backgroundColor: "red",
                            height: "56px",
                            marginRight: 10,
                          }}
                          onClick={() => {
                            setConfirmDeletion(true);
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    <Button
                      variant={"contained"}
                      startIcon={<Save />}
                      onClick={() => {
                        validateVoucher();
                      }}
                      style={{
                        backgroundColor: primaryColor,
                        height: "56px",
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={11}
            sm={5}
            alignItems={"flex-start"}
            spacing={2}
            sx={{
              ...styles.modal,
            }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{ height: 40 }}
              justifyContent={"space-between"}
            >
              <Typography variant={"h6"}>Are you sure?</Typography>

              <IconButton
                onClick={() => {
                  setConfirmDeletion(false);
                }}
              >
                <Close />
              </IconButton>
            </Grid>

            <Grid item container xs={12} justifyContent={"center"}>
              <img src={selectedEditVoucher.imageUrl} width={250} />
            </Grid>
            <Grid item container xs={12} justifyContent={"center"}>
              <Typography variant="body1">
                If you delete this voucher, it will no longer be accessible
                through the GEO GO application.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent={"flex-end"}
              spacing={2}
              sx={{ marginTop: 3 }}
            >
              <Button
                variant={"contained"}
                startIcon={<Cancel />}
                style={{ backgroundColor: "grey", marginRight: 5 }}
                onClick={() => {
                  setConfirmDeletion(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                startIcon={<Delete />}
                onClick={() => {
                  deleteVouchers();
                }}
                style={{ backgroundColor: "red", zIndex: 1 }}
              >
                Delete Voucher
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fade>
  );
}
