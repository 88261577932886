import React from "react";
import logo from "./logo.svg";
import "./App.css";
import AppTheme from "./assets/style/theme";
import {
  Button,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import AppRoutes from "./views/AppRoutes";
import { ToastContainer } from "react-toastify";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-toastify/dist/ReactToastify.css";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2VvZ29leGVjIiwiYSI6ImNsdDc4Nmx0MzBraWgycXFybHpqamFoY3oifQ.o9oaOPzv8NsRk-Djod2m_Q";

// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function App() {
  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      <ToastContainer />

      <AppRoutes mapboxgl={mapboxgl} />
    </ThemeProvider>
  );
}

export default App;
