import { useEffect, useState } from "react";
import { getCurrentDateTime, useTools } from "../../services/general_services";
import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { getDriverById } from "../../store/thunks/driver.thunks";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AttachFile,
  AttachMoney,
  Block,
  CalendarMonth,
  Cancel,
  ChevronLeft,
  Close,
  Delete,
  DocumentScanner,
  Edit,
  Launch,
  Money,
  MoneyOff,
  Phone,
} from "@mui/icons-material";
import { setSelectedEditDriver } from "../../store/slices/driver.slice";
import { styles } from "../../assets/style/styles";
import { db } from "../../services/firebase_services";
import { primaryColor } from "../../assets/style/theme";
import { DriverModal } from "../components/drivers/DriverModal";
import { DriverModalType } from "../../models/DriverModelType";
import moment, { isDate } from "moment";
import { isUserBanned } from "../../services/data_saving_services";
import { RideGrid } from "../components/rides/RideGrid";
import { findDriverTrips } from "../../store/thunks/booking.thunks";
import { RideAppModel } from "../../models/BookingModels";
import { ExpirationModel } from "../../models/DriverModel";

export function ViewDriverScreen() {
  const { dispatch, navigate } = useTools();
  const {
    selectedViewDriver,
    selectedViewDriverTrips,
    selectedViewDriverTripsError,
    selectedViewDriverTripsLoading,
    selectedViewDriverLoading,
    selectedViewDriverError,
  } = useSelector((state: any) => state.DriverSlice);
  const { id } = useParams();

  const [initialized, setInitialized] = useState(false);
  const [confirmBanUser, setConfirmBanUser] = useState(false);

  const [showDocments, setShowDocuments] = useState(false);
  const [showExpirations, setShowExpirations] = useState(false);

  const [userBanned, setUserBanned] = useState(false);

  const [expirationTitle, setExpirationTitle] = useState("None");
  const [expirationDesc, setExpirationDesc] = useState("Never Expires");

  async function getUserBanStatus(id: string) {
    let userBanned = await isUserBanned(id);
    setUserBanned(userBanned);
    return;
  }
  useEffect(() => {
    if (!selectedViewDriver && !initialized && id) {
      dispatch(getDriverById({ id: id }));

      setInitialized(true);
    }
  }, []);
  useEffect(() => {
    if (id) {
      getUserBanStatus(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedViewDriver && selectedViewDriver.expirations) {
      if (selectedViewDriver.expirations.length === 0) {
        setExpirationTitle("None");
        setExpirationDesc("Never Expires");
      } else {
        let closest = selectedViewDriver.expirations[0];

        for (let i = 1; i < selectedViewDriver.expirations.length; i++) {
          let current = selectedViewDriver.expirations[i];

          if (!closest) {
            closest = current;
          } else {
            let diff1 = moment(closest.expirationDate).diff(
              new Date(),
              "minutes"
            );
            let diff2 = moment(current.expirationDate).diff(
              new Date(),
              "minutes"
            );

            console.log(diff2);

            if (diff2 < diff1 && diff2 > 0) {
              closest = current;
            }
          }
        }

        if (closest) {
          setExpirationTitle("Exp.");
          setExpirationDesc(
            moment(moment(closest.expirationDate)).from(getCurrentDateTime())
          );
        }
      }
    }
  }, [selectedViewDriver]);
  function banUser() {
    if (id) {
      db.ref("/banned_users/" + id).set(true);
      setConfirmBanUser(false);
      setUserBanned(true);
    }
  }
  function unbanUser() {
    if (id) {
      db.ref("/banned_users/" + id).remove();
      setUserBanned(false);
    }
  }

  useEffect(() => {
    if (selectedViewDriver) {
      dispatch(findDriverTrips({ userObj: selectedViewDriver }));
    }
  }, [selectedViewDriver]);
  return (
    <Grid container xs={12} item paddingBottom={1}>
      <Grid
        container
        item
        xs={12}
        sx={{ padding: 2 }}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item container xs={12} padding={0}>
          <Button
            onClick={() => {
              navigate("/admin/drivers");
            }}
            variant={"text"}
            sx={{ position: "relative", top: -40, left: 0 }}
          >
            <ChevronLeft />
            Back to drivers
          </Button>
        </Grid>

        {selectedViewDriverLoading ? (
          <CircularProgress />
        ) : selectedViewDriver ? (
          <Grid item container>
            <Grid
              item
              container
              justifyContent={"space-between"}
              alignContent={"center"}
            >
              <Grid item xs={12} md={4}>
                <Typography
                  variant={"h6"}
                  sx={{ marginBottom: { xs: 2, md: 0 } }}
                >
                  Driver Profile
                </Typography>
              </Grid>
              <Grid
                item
                container
                style={{}}
                xs={12}
                md={8}
                spacing={1}
                sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }}
                justifyContent={"flex-end"}
              >
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  xs={4}
                  sx={{
                    borderRadius: 3,
                    padding: 0,
                    paddingLeft: 0,
                    height: "50px",
                    cursor:
                      selectedViewDriver &&
                      selectedViewDriver.documents &&
                      selectedViewDriver.documents.length > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    setShowDocuments(true);
                  }}
                >
                  <Grid
                    item
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      backgroundColor: "rgba(20,100,202,0.2)",

                      borderRadius: 3,
                      height: "50px",
                    }}
                    onClick={() => {
                      if (
                        selectedViewDriver.documents &&
                        selectedViewDriver.documents.length > 0
                      ) {
                        setShowDocuments(true);
                      }
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={4}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <DocumentScanner
                        style={{ color: "rgba(20,100,202)" }}
                        fontSize="small"
                      />
                    </Grid>
                    <Grid item container flexDirection={"column"} xs={8}>
                      <Typography
                        style={{ color: "rgba(20,100,202)" }}
                        variant={"h6"}
                        fontSize={16}
                      >
                        {selectedViewDriver &&
                        selectedViewDriver.documents &&
                        selectedViewDriver.documents.length > 0
                          ? "Files"
                          : "None"}
                      </Typography>
                      <Typography
                        style={{ color: "rgba(20,100,202)" }}
                        variant={"body1"}
                        fontSize={11}
                      >
                        {selectedViewDriver.documents &&
                        selectedViewDriver.documents.length > 0
                          ? "View Files"
                          : "No Files"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  xs={4}
                  sx={{
                    borderRadius: 3,
                    padding: 0,
                    paddingLeft: 0,
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (userBanned) {
                      unbanUser();
                    } else {
                      setConfirmBanUser(true);
                    }
                  }}
                >
                  <Grid
                    container
                    sx={{
                      backgroundColor: "rgba(255,0,0,0.2)",
                      height: "50px",
                      borderRadius: 3,
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={4}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Block
                        style={{ color: "rgb(255,0,0)" }}
                        fontSize="small"
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      flexDirection={"column"}
                      xs={8}
                      justifyContent={"center"}
                    >
                      <Typography
                        style={{ color: "rgb(255,0,0)" }}
                        variant={"h6"}
                        fontSize={16}
                      >
                        {userBanned ? "Unban" : "Ban"}
                      </Typography>
                      <Typography
                        style={{ color: "rgb(255,0,0)" }}
                        variant={"body1"}
                        fontSize={11}
                      >
                        {userBanned ? "Unban Account" : "Ban Account"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  xs={4}
                  sx={{
                    borderRadius: 3,
                    padding: 0,
                    paddingLeft: 0,
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setSelectedEditDriver(selectedViewDriver));
                  }}
                >
                  <Grid
                    container
                    sx={{
                      backgroundColor: "rgba(250,150,0,0.2)",
                      height: "50px",
                      borderRadius: 3,
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={4}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Edit
                        style={{ color: "rgba(250,150,0)" }}
                        fontSize="small"
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      flexDirection={"column"}
                      xs={8}
                      justifyContent={"center"}
                    >
                      <Typography
                        style={{ color: "rgba(250,150,0)" }}
                        variant={"h6"}
                        fontSize={16}
                      >
                        Edit
                      </Typography>
                      <Typography
                        style={{ color: "rgba(250,150,0)" }}
                        variant={"body1"}
                        fontSize={11}
                      >
                        Edit Profile
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{ borderRadius: 14, marginTop: 10 }}
                justifyContent={"flex-start"}
              >
                <Grid item>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ borderRadius: 14, marginTop: 10 }}
                    justifyContent={"flex-start"}
                    sx={{
                      boxShadow: 2,
                      width: "100%",
                      padding: 1,
                      marginLeft: 0,
                    }}
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={4}
                      md={3}
                      sx={{
                        padding: "0 !important",
                        marginRight: "10px,boxShadow: 2",
                      }}
                    >
                      <Grid
                        item
                        boxShadow={1}
                        sx={{
                          backgroundImage: `url("${
                            selectedViewDriver.profilePictureUrl
                              ? selectedViewDriver.profilePictureUrl
                              : "/static/avatar.svg"
                          }")`,
                          padding: 0,
                          paddingRight: 10,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: 150,
                          width: 150,
                          borderRadius: 4,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={7}
                      md={3.5}
                      alignContent={"space-evenly"}
                      justifyContent={"flex-start"}
                    >
                      <Grid
                        item
                        container
                        xs={4}
                        justifyContent={"flex-end"}
                        alignContent={"space-evenly"}
                        sx={{ height: "100%" }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="h6" fontSize={13}>
                            Name:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" fontSize={13}>
                            Car Desc:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" fontSize={13}>
                            Licenses:
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="h6" fontSize={13}>
                            Last Active:
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7.9}
                        justifyContent={"flex-start"}
                        alignContent={"space-evenly"}
                      >
                        <Grid item xs={12}>
                          <Typography variant="body1" fontSize={13}>
                            {selectedViewDriver.firstName +
                              " " +
                              selectedViewDriver.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" fontSize={13}>
                            {selectedViewDriver.carDescriptionShort}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" fontSize={13}>
                            {selectedViewDriver.licensingCouncils
                              ? selectedViewDriver.licensingCouncils.map(
                                  (val: any, index: number) => {
                                    if (
                                      index !==
                                      selectedViewDriver.licensingCouncils
                                        .length -
                                        1
                                    ) {
                                      return val + ", ";
                                    } else {
                                      return val;
                                    }
                                  }
                                )
                              : "No Councils"}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            fontSize={13}
                            sx={{
                              backgroundColor: "rgba(90,96,255,0.2)",
                              color: "#5A60FF",
                              padding: 1,
                              paddingLeft: 2,
                              paddingRight: 2,
                              width: "fit-content",
                              borderRadius: 25,
                            }}
                          >
                            {selectedViewDriver.lastActiveDate
                              ? moment(selectedViewDriver.lastActiveDate).from(
                                  getCurrentDateTime()
                                )
                              : "Never Logged In"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={0.1}
                        sx={{
                          display: { xs: "none", md: "flex" },
                          height: "100%",
                        }}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <Grid
                          item
                          container
                          xs={10}
                          sx={{
                            borderRight: "0.5px solid rgba(0,0,0,0.1)",
                            height: "100%",
                          }}
                        ></Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ paddingTop: 100 }} alignItems={"center"}>
                      <Grid
                        container
                        item
                        flexDirection={"column"}
                        sx={{ paddingTop: 2 }}
                      >
                        <Grid item container xs={12} sx={{ height: 60 }}>
                          <Grid
                            item
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{
                              ...styles.regPlate,
                              borderRadius: 3,
                              padding: 0,
                              paddingLeft: 0,
                              paddingRight: 0,
                              height: "50px",
                              width: 140,
                            }}
                          >
                            <Typography variant={"h6"} fontSize={16}>
                              {selectedViewDriver.registrationPlate}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{
                            backgroundColor: "rgba(57,222,84,0.2)",
                            marginTop: 1,
                            borderRadius: 3,
                            padding: 0,
                            paddingLeft: 0,
                            marginRight: 1,
                            height: "50px",
                            maxWidth: 130,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              `tel:+44${selectedViewDriver.phoneNumber}`
                            );
                          }}
                        >
                          <Grid
                            item
                            container
                            xs={4}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Phone
                              style={{ color: "#39DE54" }}
                              fontSize="small"
                            />
                          </Grid>
                          <Grid item container flexDirection={"column"} xs={8}>
                            <Typography
                              style={{ color: "#39DE54" }}
                              variant={"h6"}
                              fontSize={16}
                            >
                              Call
                            </Typography>
                            <Typography variant={"body1"} fontSize={11}>
                              Call Driver
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={0.1}
                      sx={{
                        display: { xs: "none", md: "flex" },
                        height: "100%",
                      }}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Grid
                        item
                        container
                        xs={10}
                        sx={{
                          borderRight: "0.5px solid rgba(0,0,0,0.1)",
                          height: "100%",
                        }}
                      ></Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      md={2.5}
                      sx={{ alignItems: "baseline" }}
                    >
                      <Grid container sx={{ paddingTop: 2 }}>
                        {selectedViewDriver &&
                          selectedViewDriver.stripeAccountId && (
                            <Grid
                              item
                              container
                              justifyContent={"center"}
                              alignItems={"center"}
                              sx={{
                                backgroundColor: "rgba(100,100,202,0.2)",

                                borderRadius: 3,
                                padding: 0,
                                paddingLeft: 0,
                                marginRight: 1,
                                height: "50px",
                                cursor: "pointer",
                                marginBottom: "8px",
                              }}
                              onClick={() => {
                                window.open(
                                  "https://dashboard.stripe.com/test/connect/accounts/" +
                                    selectedViewDriver.stripeAccountId
                                );
                              }}
                            >
                              <Grid
                                item
                                container
                                xs={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <AttachMoney
                                  style={{ color: "rgba(100,100,202)" }}
                                  fontSize="small"
                                />
                              </Grid>
                              <Grid
                                item
                                container
                                flexDirection={"column"}
                                xs={8}
                              >
                                <Typography
                                  style={{ color: "rgba(100,100,202)" }}
                                  variant={"h6"}
                                  fontSize={16}
                                >
                                  Stripe
                                </Typography>
                                <Typography
                                  style={{ color: "rgba(100,100,202)" }}
                                  variant={"body1"}
                                  fontSize={11}
                                >
                                  View Account
                                </Typography>
                              </Grid>
                            </Grid>
                          )}

                        <Grid
                          item
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{
                            backgroundColor: "rgba(200,100,202,0.2)",

                            borderRadius: 3,
                            padding: 0,
                            paddingLeft: 0,
                            marginRight: 1,
                            paddingRight: 2,
                            height: "50px",
                            cursor:
                              selectedViewDriver &&
                              selectedViewDriver.expirations &&
                              selectedViewDriver.expirations.length > 0
                                ? "pointer"
                                : "default",
                          }}
                          onClick={() => {
                            if (
                              selectedViewDriver &&
                              selectedViewDriver.expirations &&
                              selectedViewDriver.expirations.length > 0
                            ) {
                              setShowExpirations(true);
                            }
                          }}
                        >
                          <Grid
                            item
                            container
                            xs={4}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <CalendarMonth
                              style={{ color: "rgba(200,100,202)" }}
                              fontSize="small"
                            />
                          </Grid>
                          <Grid item container flexDirection={"column"} xs={8}>
                            <Typography
                              style={{ color: "rgba(200,100,202)" }}
                              variant={"h6"}
                              fontSize={16}
                            >
                              {expirationTitle}
                            </Typography>
                            <Typography
                              style={{ color: "rgba(200,100,202)" }}
                              variant={"body1"}
                              fontSize={11}
                            >
                              {expirationDesc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Modals */}
            <Fade in={confirmBanUser}>
              <Grid
                sx={{
                  ...styles.modalBackdrop,
                }}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item container xs={11} sm={5} sx={{ ...styles.modal }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: 40 }}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"h6"}>Are you sure?</Typography>

                    <IconButton
                      onClick={() => {
                        setConfirmBanUser(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    <img src="/static/rubbish.svg" width={250} />
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    <Typography variant="body1">
                      If you ban this driver, they will be unable to access the
                      GEO-GO application, and any associated GEO-GO databases.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"flex-end"}
                    spacing={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Button
                      variant={"contained"}
                      startIcon={<Cancel />}
                      style={{ backgroundColor: "grey", marginRight: 5 }}
                      onClick={() => {
                        setConfirmBanUser(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={"contained"}
                      startIcon={<Delete />}
                      onClick={() => {
                        banUser();
                      }}
                      style={{ backgroundColor: "red", zIndex: 1 }}
                    >
                      Ban User
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>

            <Fade in={showDocments}>
              <Grid
                sx={{
                  ...styles.modalBackdrop,
                }}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item container xs={5} sx={{ ...styles.modal }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: 40 }}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"h6"}>Documents</Typography>

                    <IconButton
                      onClick={() => {
                        setShowDocuments(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    {selectedViewDriver &&
                      selectedViewDriver.documents &&
                      selectedViewDriver.documents.map(
                        (val: any, index: number) => {
                          return (
                            <Grid
                              container
                              item
                              xs={12}
                              spacing={2}
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{ height: 48 }}
                              key={index}
                            >
                              <Grid
                                item
                                container
                                xs={7}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Typography variant={"h6"} fontSize={13}>
                                  {val.name}{" "}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={3}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Typography variant={"body1"} fontSize={13}>
                                  {val.type}
                                </Typography>
                              </Grid>
                              <Grid item container xs={1}>
                                <IconButton
                                  style={{ zIndex: 10 }}
                                  onClick={() => {
                                    window
                                      .open(val.downloadUrl, "_blank")
                                      ?.focus();
                                  }}
                                >
                                  <Launch />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"flex-end"}
                    spacing={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Button
                      variant={"contained"}
                      startIcon={<Cancel />}
                      style={{ backgroundColor: "grey", marginRight: 5 }}
                      onClick={() => {
                        setShowDocuments(false);
                      }}
                    >
                      Close Documents
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>

            <Fade in={showExpirations}>
              <Grid
                sx={{
                  ...styles.modalBackdrop,
                }}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item container xs={5} sx={{ ...styles.modal }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: 40 }}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"h6"}>Expirations</Typography>

                    <IconButton
                      onClick={() => {
                        setShowExpirations(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    {selectedViewDriver &&
                      selectedViewDriver.expirations &&
                      selectedViewDriver.expirations.map(
                        (val: ExpirationModel, index: number) => {
                          return (
                            <Grid
                              container
                              item
                              xs={12}
                              spacing={2}
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{ height: 48 }}
                              key={index}
                            >
                              <Grid
                                item
                                container
                                xs={7}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Typography variant={"h6"} fontSize={13}>
                                  {val.title}{" "}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={3}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Typography variant={"body1"} fontSize={13}>
                                  {moment(val.expirationDate).format(
                                    "DD/MM/yyyy"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item container xs={1}>
                                <IconButton
                                  style={{ zIndex: 10 }}
                                  onClick={() => {
                                    setShowExpirations(false);
                                    dispatch(
                                      setSelectedEditDriver(selectedViewDriver)
                                    );
                                  }}
                                >
                                  <Launch />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"flex-end"}
                    spacing={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Button
                      variant={"contained"}
                      startIcon={<Cancel />}
                      style={{ backgroundColor: "grey", marginRight: 5 }}
                      onClick={() => {
                        setShowExpirations(false);
                      }}
                    >
                      Close Expirations
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        ) : (
          <Typography>Nothing here</Typography>
        )}
        {selectedViewDriverLoading ? (
          <CircularProgress />
        ) : (
          // Hey
          <Grid container sx={{ paddingTop: 2 }}>
            <RideGrid
              rows={
                selectedViewDriverTrips && selectedViewDriverTrips.trips
                  ? selectedViewDriverTrips.trips.map(
                      (val: RideAppModel, index: number) => {
                        return { ...val, id: index };
                      }
                    )
                  : []
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
