import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import {
  ChatRounded,
  FlightTakeoffRounded,
  ListAltRounded,
  LocalTaxiRounded,
  MoreHorizRounded,
  NotificationsActiveRounded,
  ScheduleRounded,
  StarRounded,
} from "@mui/icons-material";

const items = [
  {
    icon: <FlightTakeoffRounded />,
    title: "Airport Transfers",
    description: "Effortless airport transfers made simple with our app.",
  },
  {
    icon: <LocalTaxiRounded />,
    title: "Comfort",
    description: "Experience luxury travel in our comfy vehicles.",
  },
  {
    icon: <ScheduleRounded />,
    title: "Convenience",
    description:
      "Get a ride instantly or schedule for later with our easy-to-use app.",
  },
  {
    icon: <NotificationsActiveRounded />,
    title: "Notifications",
    description:
      "Stay informed with trip updates through instant phone notifications.",
  },
  {
    icon: <ChatRounded />,
    title: "In-app Messaging",
    description:
      "Chat seamlessly with drivers and fellow passengers within the app.",
  },
  {
    icon: <StarRounded />,
    title: "Ratings",
    description:
      "Rate your driver and view their ratings from other passengers.",
  },
  {
    icon: <ListAltRounded />,
    title: "Trips at a Glance",
    description: "Easily manage upcoming and past trips at a glance.",
  },
  {
    icon: <MoreHorizRounded />,
    title: "And More",
    description:
      "Continuous app improvements for an enhanced travel experience.",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer
            support and precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
