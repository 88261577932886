//-----------------------------------------------------------------------------------------------
// Imports
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import UserSlice from "./slices/user.slice";
import GeneralSlice from "./slices/general.slice";
import DriverSlice from "./slices/driver.slice";
import CarClassSlice from "./slices/carClass.slice";
import VoucherSlice from "./slices/voucher.slice";
import BookingSlice from "./slices/booking.slice";
import ClientSlice from "./slices/client.slice";
import PayoutSlice from "./slices/payout.slice";

//-----------------------------------------------------------------------------------------------
// Set application reducers
const reducer = {
  UserSlice,
  GeneralSlice,
  DriverSlice,
  CarClassSlice,
  VoucherSlice,
  BookingSlice,
  ClientSlice,
  PayoutSlice,
};

//-----------------------------------------------------------------------------------------------
// Set store
const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Redux exports

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
