import { useEffect, useState } from "react";
import { useTools } from "../../services/general_services";
import { setPage } from "../../store/slices/general.slice";
import { PageType } from "../../models/PageType";
import { ChevronRight } from "@mui/icons-material";
import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import DarkTextField from "../components/general/TextFieldDarker";
import { useSelector } from "react-redux";
import { RideGrid } from "../components/rides/RideGrid";
import { RideAppModel, TripType } from "../../models/BookingModels";
import moment from "moment";
import { TimeFrameSelect } from "../components/general/TimeFrameSelect";
import { primaryColor } from "../../assets/style/theme";

export function RidesScreen() {
  const { dispatch, navigate } = useTools();
  const {
    trips,
    tripsLoading,
    preCancelled,
    completed,
    cancelled,
    inProgress,
    upcoming,
  } = useSelector((state: any) => state.BookingSlice);

  useEffect(() => {
    dispatch(setPage(PageType.RIDES));
  }, []);

  const [filteredList, setFilteredList] = useState<
    { trip: RideAppModel; type: TripType }[]
  >([]);
  const [searchValue, setSearchValue] = useState("");

  enum Pages {
    ALL = "ALL",
    IN_PROGRESS = "IN_PROGRESS",
    CANCELLED = "CANCELLED",
    PRE_CANCELLED = "PRE_CANCELLED",
    COMPLETED = "COMPLETED",
    UPCOMING = "UPCOMING",
  }

  const [currentPage, setCurrentPage] = useState(Pages.ALL);

  useEffect(() => {
    if (trips) {
      setFilteredList(trips);
    }
  }, [trips]);

  useEffect(() => {}, []);
  return (
    <Grid
      container
      item
      xs={12}
      sx={{ padding: 2 }}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      {/* Top section - Search & Filter */}
      <Grid item container xs={12} alignItems={"center"}>
        <Grid
          item
          container
          sm={12}
          md={9}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid
            container
            item
            xs={12}
            sm={8}
            sx={{
              justifyContent: { xs: "space-between", sm: "flex-start" },
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Typography
              variant={"h6"}
              fontWeight={"800"}
              style={{ marginRight: 30 }}
            >
              Rides
            </Typography>

            <DarkTextField
              placeholder="Search Rides..."
              value={searchValue}
              onChange={(e) => {
                console.log("uhh ");
                setSearchValue(e.target.value);

                let searchVal = e.target.value;
                console.log(searchVal);
                if (e.target.value === "") {
                  console.log("empty...");
                  setFilteredList(trips);
                } else {
                  console.log("not empty");
                  let trip_list: { trip: RideAppModel; type: TripType }[] =
                    trips ? trips : [];
                  setFilteredList(
                    trip_list.filter((val) => {
                      console.log(val);
                      if (
                        val.trip.driversResponded &&
                        val.trip.driversResponded.length > 0 &&
                        val.trip.driversResponded[
                          val.trip.driversResponded.length - 1
                        ].driver.firstName &&
                        val.trip.driversResponded[
                          val.trip.driversResponded.length - 1
                        ].driver.firstName
                          .toLowerCase()
                          .includes(searchVal)
                      ) {
                        return val;
                      } else if (
                        val.trip.driversResponded &&
                        val.trip.driversResponded.length > 0 &&
                        val.trip.driversResponded[
                          val.trip.driversResponded.length - 1
                        ].driver.lastName &&
                        val.trip.driversResponded[
                          val.trip.driversResponded.length - 1
                        ].driver.lastName
                          .toLowerCase()
                          .includes(searchVal.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.trip.bookingUser.firstName &&
                        val.trip.bookingUser.firstName
                          .toLowerCase()
                          .includes(searchVal.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.trip.bookingUser.lastName &&
                        val.trip.bookingUser.lastName
                          .toLowerCase()
                          .includes(searchVal.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.trip.origin.address &&
                        val.trip.origin.address
                          .toLowerCase()
                          .includes(searchVal.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.trip.waypoints.find((val2) => {
                          if (
                            val2.address &&
                            val2.address
                              .toLowerCase()
                              .includes(searchVal.toLowerCase())
                          ) {
                            return val2;
                          }
                        })
                      ) {
                        return val;
                      } else if (
                        val.trip.dateTime &&
                        moment(val.trip.dateTime)
                          .format("Do MMM HH:mma")
                          .includes(searchVal)
                      ) {
                        return val;
                      } else if (
                        val.trip.creationDateTime.toLowerCase() &&
                        moment(val.trip.creationDateTime)
                          .format("Do MMM HH:mma")
                          .toLowerCase()
                          .includes(searchVal)
                      ) {
                        return val;
                      } else if (
                        val.trip.selectedCarClass.name &&
                        val.trip.selectedCarClass.name
                          .toLowerCase()
                          .includes(searchVal.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.trip.amountPaid &&
                        val.trip.amountPaid.toString().includes(searchVal)
                      ) {
                        return val;
                      } else if (
                        val.trip.amountPaid &&
                        val.trip.driverPaid &&
                        (val.trip.amountPaid - val.trip.driverPaid)
                          .toString()
                          .includes(searchVal)
                      ) {
                        return val;
                      } else if (
                        val.trip.id
                          .toLowerCase()
                          .includes(searchVal.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                  );
                }
              }}
              style={{
                width: "200px",
                borderRadius: 30,
              }}
            />
          </Grid>

          <Grid item container xs={12} sm={4} sx={{ marginBottom: 2 }}>
            <TimeFrameSelect />
          </Grid>
        </Grid>
        {/* Bottom section - Driver Grid */}
        <Grid item container xs={12} justifyContent={"center"}>
          {tripsLoading ? (
            <CircularProgress />
          ) : (
            <Grid item container>
              <Grid item container sx={{ overflow: "scroll" }}>
                <Grid
                  item
                  container
                  sx={{ marginBottom: 1, width: "max-content" }}
                >
                  <Grid
                    item
                    sx={{
                      borderBottomWidth: currentPage === Pages.ALL ? 3 : 2,
                      borderBottomStyle: "solid",
                      borderBottomColor:
                        currentPage === Pages.ALL
                          ? primaryColor
                          : "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Button
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        setCurrentPage(Pages.ALL);
                        setFilteredList(trips);
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          color:
                            currentPage === Pages.ALL ? primaryColor : "black",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        All ({trips ? trips.length : 0})<ChevronRight />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      borderBottomWidth:
                        currentPage === Pages.IN_PROGRESS ? 3 : 2,
                      borderBottomStyle: "solid",
                      borderBottomColor:
                        currentPage === Pages.IN_PROGRESS
                          ? primaryColor
                          : "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Button
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        setCurrentPage(Pages.IN_PROGRESS);
                        setFilteredList(inProgress);
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          color:
                            currentPage === Pages.IN_PROGRESS
                              ? primaryColor
                              : "black",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        In Progress ({inProgress ? inProgress.length : 0})
                        <ChevronRight />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      borderBottomWidth: currentPage === Pages.UPCOMING ? 3 : 2,
                      borderBottomStyle: "solid",
                      borderBottomColor:
                        currentPage === Pages.UPCOMING
                          ? primaryColor
                          : "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Button
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        setCurrentPage(Pages.UPCOMING);
                        setFilteredList(upcoming);
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          color:
                            currentPage === Pages.UPCOMING
                              ? primaryColor
                              : "black",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Upcoming ({upcoming ? upcoming.length : 0})
                        <ChevronRight />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      borderBottomWidth:
                        currentPage === Pages.COMPLETED ? 3 : 2,
                      borderBottomStyle: "solid",
                      borderBottomColor:
                        currentPage === Pages.COMPLETED
                          ? primaryColor
                          : "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Button
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        setCurrentPage(Pages.COMPLETED);
                        setFilteredList(completed);
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          color:
                            currentPage === Pages.COMPLETED
                              ? primaryColor
                              : "black",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Completed ({completed ? completed.length : 0}){" "}
                        <ChevronRight />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      borderBottomWidth:
                        currentPage === Pages.PRE_CANCELLED ? 3 : 2,
                      borderBottomStyle: "solid",
                      borderBottomColor:
                        currentPage === Pages.PRE_CANCELLED
                          ? primaryColor
                          : "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Button
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        setCurrentPage(Pages.PRE_CANCELLED);
                        setFilteredList(preCancelled);
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          color:
                            currentPage === Pages.PRE_CANCELLED
                              ? primaryColor
                              : "black",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Pre-Cancelled ({preCancelled ? preCancelled.length : 0}){" "}
                        <ChevronRight />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      borderBottomWidth:
                        currentPage === Pages.CANCELLED ? 3 : 2,
                      borderBottomStyle: "solid",
                      borderBottomColor:
                        currentPage === Pages.CANCELLED
                          ? primaryColor
                          : "rgba(0,0,0,0.6)",
                    }}
                  >
                    <Button
                      style={{
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        setCurrentPage(Pages.CANCELLED);
                        setFilteredList(cancelled);
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          color:
                            currentPage === Pages.CANCELLED
                              ? primaryColor
                              : "black",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Cancelled ({cancelled ? cancelled.length : 0}){" "}
                        <ChevronRight />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <RideGrid rows={filteredList} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
