import theme, { primaryColor } from "./theme";

export const styles = {
  largeRoundedBtn: {
    paddingTop: 25,
    paddingBottom: 25,
    borderRadius: 45,
    backgroundColor: primaryColor,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  navbar: {
    paddingTop: 30,
    height: "100vh",
    backgroundColor: "#101010",
  },
  sidePadding: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  modalBackdrop: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0,0,0,0.6)",
    top: 0,
    left: 0,
    zIndex: 9,
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: 5,
    padding: 2,
    maxHeight: "95vh",
    overflowY: "scroll",
    position: "relative",
    zIndex: 10,
  },
  regPlate: {
    backgroundColor: "#FFDB1F",
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
};
