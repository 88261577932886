import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RideAppModel,
  StateAppModel,
  StateType,
  TripType,
} from "../../models/BookingModels";
import {
  findAllDriverEarnings,
  findTripById,
  findTrips,
} from "../thunks/booking.thunks";
import { DriverEarningModel, EarningModel } from "../../models/EarningsModel";
import { DriverModel } from "../../models/DriverModel";

export enum BookingType {
  AIRPORT_TRANSFER = "AIRPORT_TRANSFER",
  REGULAR = "REGULAR",
}
/**
 * userId - The user ID belonging to the logged in user
 * userData - The data belonging to the logged in user (UserDataModel)
 * userDataSearching - If the user data is loading
 * userDataError - If the user data had an error loading.
 */
interface IProps {
  trips:
    | Array<{
        trip: RideAppModel;
        type: TripType;
        id: string;
      }>
    | undefined;
  tripsLoading: boolean;
  tripsError: boolean;
  selectedTripLoading: boolean;
  selectedTripError: boolean;
  selectedTrip:
    | {
        trip: RideAppModel;
        type: TripType;
        id: string;
      }
    | undefined;
  earnings: DriverEarningModel[];
  earningsLoading: boolean;
  earningsError: boolean;
  numTrips: number;
  grossProfit: string;
  netProfit: string;
  numBooked: Array<{
    trip: RideAppModel;
    type: TripType;
    id: string;
  }>;
  preCancelled: Array<{
    trip: RideAppModel;
    type: TripType;
    id: string;
  }>;
  cancelled: Array<{
    trip: RideAppModel;
    type: TripType;
    id: string;
  }>;
  completed: Array<{
    trip: RideAppModel;
    type: TripType;
    id: string;
  }>;
  inProgress: Array<{
    trip: RideAppModel;
    type: TripType;
    id: string;
  }>;
  upcoming: Array<{
    trip: RideAppModel;
    type: TripType;
    id: string;
  }>;
}

const bookingInitialState: IProps = {
  trips: undefined,
  tripsLoading: false,
  tripsError: false,
  earnings: [],
  earningsError: false,
  earningsLoading: false,
  selectedTrip: undefined,
  selectedTripError: false,
  selectedTripLoading: false,
  numTrips: 0,
  netProfit: "0.00",
  grossProfit: "0.00",
  numBooked: [],
  preCancelled: [],
  cancelled: [],
  completed: [],
  inProgress: [],
  upcoming: [],
};

const bookingSlice: any = createSlice({
  name: "booking_data",
  initialState: bookingInitialState,
  reducers: {
    // Setter functions
    setTrips: (
      state: IProps,
      {
        payload,
      }: PayloadAction<
        | Array<{
            trip: RideAppModel;
            type: TripType;
            id: string;
          }>
        | undefined
      >
    ) => {
      state.trips = payload;
    },
    setSelectedTrip: (
      state: IProps,
      {
        payload,
      }: PayloadAction<
        | {
            trip: RideAppModel;
            type: TripType;
            id: string;
          }
        | undefined
      >
    ) => {
      state.selectedTrip = payload;
    },
  },

  // Reducers to tell react which states to update with the thunk functions.
  extraReducers: (builder: any) => {
    builder.addCase(findAllDriverEarnings.rejected, (state: IProps) => {
      state.earningsLoading = false;
      state.earningsError = true;
    });
    builder.addCase(findAllDriverEarnings.pending, (state: IProps) => {
      state.earningsLoading = true;
      state.earningsError = false;
    });
    builder.addCase(
      findAllDriverEarnings.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<{
          earnings: DriverEarningModel[];
          grossProfit: number;
          netProfit: number;
        }>
      ) => {
        if (payload) {
          state.earnings = payload.earnings;
          state.grossProfit = payload.grossProfit.toFixed(2);
          state.netProfit = payload.netProfit.toFixed(2);

          state.earningsError = false;
          state.earningsLoading = false;
        } else {
          state.earnings = [];
        }
      }
    );
    builder.addCase(findTrips.rejected, (state: IProps) => {
      state.tripsLoading = false;
      state.tripsError = true;
    });
    builder.addCase(findTrips.pending, (state: IProps) => {
      state.tripsLoading = true;
      state.tripsError = false;
    });
    builder.addCase(
      findTrips.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<
          | {
              trips: Array<{
                trip: RideAppModel;
                type: TripType;
                id: string;
              }>;
              numTrips: number;
            }
          | undefined
        >
      ) => {
        if (payload) {
          console.log("before sorting trips...");
          state.trips = payload.trips.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              // @ts-ignore
              new Date(b.trip.creationDateTime) -
              // @ts-ignore
              new Date(a.trip.creationDateTime)
            );
          });

          state.numTrips = payload.numTrips;

          try {
            console.log("HERE...");
            if (payload) {
              console.log("checking..");
              let cancelled_by_driver = payload.trips.filter(
                (val: { trip: RideAppModel; type: TripType; id: string }) => {
                  if (
                    val.trip.states.find(
                      (val2: StateAppModel) => val2.state === "Trip ended early"
                    ) &&
                    val.trip.origin.departureTime
                  ) {
                    return val;
                  }
                }
              );

              console.log(cancelled_by_driver);
              let cancelled_by_customer = payload.trips.filter(
                (val: { trip: RideAppModel; type: TripType; id: string }) => {
                  if (
                    (val.trip.states.find(
                      (val2: StateAppModel) => val2.state === "Trip ended early"
                    ) &&
                      !val.trip.origin.departureTime) ||
                    val.trip.states.find(
                      (val2: StateAppModel) => val2.state === "Driver not found"
                    )
                  ) {
                    return val;
                  }
                }
              );

              let completed = payload.trips.filter(
                (val: { trip: RideAppModel; type: TripType; id: string }) => {
                  if (
                    val.trip.states.find(
                      (val2: StateAppModel) =>
                        val2.state === "Arrived at destination"
                    )
                  ) {
                    console.log("completed?");
                    return val;
                  }
                }
              );
              let in_progress = payload.trips.filter(
                (val: { trip: RideAppModel; type: TripType; id: string }) => {
                  if (
                    val.trip.states.filter(
                      (val2: StateAppModel) =>
                        val2.state === "Trip ended early" ||
                        val2.state === "Arrived at destination" ||
                        val2
                    ).length === 0
                  ) {
                    return val;
                  }
                }
              );

              let up_coming = payload.trips.filter(
                (val: { trip: RideAppModel; type: TripType; id: string }) => {
                  if (
                    val.trip.states[val.trip.states.length - 1].state ===
                      "Looking for driver" ||
                    val.trip.states[val.trip.states.length - 1].state ===
                      "Driver found"
                  ) {
                    return val;
                  }
                }
              );

              state.cancelled = cancelled_by_driver;
              state.completed = completed;
              state.preCancelled = cancelled_by_customer;
              state.numBooked = payload.trips;
              state.inProgress = in_progress;
              state.upcoming = up_coming;
            }
          } catch (err) {
            console.log(err);
          }

          state.tripsError = false;
          state.tripsLoading = false;
        } else {
          state.trips = [];
        }
      }
    );
    builder.addCase(findTripById.rejected, (state: IProps) => {
      state.selectedTripLoading = false;
      state.selectedTripError = true;
    });
    builder.addCase(findTripById.pending, (state: IProps) => {
      state.selectedTripLoading = true;
      state.selectedTripError = false;
    });
    builder.addCase(
      findTripById.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<
          | {
              trip: RideAppModel;
              type: TripType;
              id: string;
            }
          | undefined
        >
      ) => {
        if (payload) {
          state.selectedTrip = payload;
          state.selectedTripError = false;
          state.selectedTripLoading = false;
        }
      }
    );
  },
});

export const {
  setBookingCarClass,
  setBookingDateTime,
  setBookingDestinations,
  setBookingOrigin,
  setEstPrice,
  setEstArrivalTime,
  setSelectedVoucher,
  setBooking,
  setBookingType,
  setCurrentTrip,
  setCurrentDriver,
  setCurrentState,
  setSelectedTrip,
} = bookingSlice.actions;

export default bookingSlice.reducer;
