import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VoucherModel } from "../../models/VoucherModel";
import { getVoucherList } from "../thunks/voucher.thunks";

/**
 * userId - The user ID belonging to the logged in user
 * userData - The data belonging to the logged in user (UserDataModel)
 * userDataSearching - If the user data is loading
 * userDataError - If the user data had an error loading.
 */
interface IProps {
  voucherList: VoucherModel[];
  voucherListLoading: boolean;
  voucherListError: boolean;
  selectedEditVoucher: VoucherModel | undefined;
  createNewVoucher: boolean;
}

const voucherInitialState: IProps = {
  voucherList: [],
  voucherListError: false,
  voucherListLoading: false,
  selectedEditVoucher: undefined,
  createNewVoucher: false,
};

const voucherSlice: any = createSlice({
  name: "voucher_data",
  initialState: voucherInitialState,
  reducers: {
    // Setter functions
    setVoucherList: (
      state: IProps,
      { payload }: PayloadAction<VoucherModel[]>
    ) => {
      state.voucherList = payload;
    },
    setVoucherListLoading: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.voucherListLoading = payload;
    },
    setVoucherListError: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.voucherListError = payload;
    },
    setSelectedEditVoucher: (
      state: IProps,
      { payload }: PayloadAction<VoucherModel>
    ) => {
      state.selectedEditVoucher = payload;
    },
    setCreateNewVoucher: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.createNewVoucher = payload;
    },
  },

  // Reducers to tell react which states to update with the thunk functions.
  extraReducers: (builder: any) => {
    builder.addCase(getVoucherList.rejected, (state: IProps) => {
      state.voucherListLoading = false;
      state.voucherListError = true;
    });
    builder.addCase(getVoucherList.pending, (state: IProps) => {
      state.voucherListLoading = true;
      state.voucherListError = false;
    });
    builder.addCase(
      getVoucherList.fulfilled,
      (state: IProps, { payload }: PayloadAction<VoucherModel[]>) => {
        state.voucherListError = false;
        state.voucherListLoading = false;

        state.voucherList = payload;
      }
    );
  },
});

export const {
  setVoucherList,
  setVoucherListError,
  setVoucherListLoading,
  setSelectedEditVoucher,
  setCreateNewVoucher,
} = voucherSlice.actions;

export default voucherSlice.reducer;
