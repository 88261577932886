import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAdminStatus } from "../thunks/general.thunks";
import { PageType } from "../../models/PageType";
import { TimeFrame } from "../../models/TimeFrameModel";

/**
 * modalHeight - Height of the information modal being shown during a journey - Used to display properly.
 * reportUser - Data of a user from a group selected to be reported.
 * page - The current page a user is on - PageType.HOME - PageType.JOURNEYS etc.
 */
interface IProps {
  admin: boolean;
  adminLoading: boolean;
  page: PageType;
  timeframe: TimeFrame;
}

const generalInitialState: IProps = {
  admin: false,
  adminLoading: false,
  page: PageType.INITIAL,
  timeframe: TimeFrame.DAILY,
};

const generalSlice: any = createSlice({
  name: "general_data",
  initialState: generalInitialState,
  reducers: {
    // Setters
    setAdmin: (state: IProps, { payload }: PayloadAction<boolean>) => {
      state.admin = payload;
    },
    setPage: (state: IProps, { payload }: PayloadAction<PageType>) => {
      state.page = payload;
    },
    setTimeframe: (state: IProps, { payload }: PayloadAction<TimeFrame>) => {
      state.timeframe = payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      getAdminStatus.fulfilled,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.admin = payload;
        state.adminLoading = false;
      }
    );
    builder.addCase(
      getAdminStatus.pending,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.adminLoading = true;
      }
    );
  },
});

export const { setAdmin, setPage, setTimeframe } = generalSlice.actions;

export default generalSlice.reducer;
