export enum PageType {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  DASHBOARD = "DASHBOARD",
  RIDES = "RIDES",
  PAYOUTS = "PAYOUTS",
  CLIENTS = "CLIENTS",
  DRIVERS = "DRIVERS",
  DOCUMENTS = "DOCUMENTS",
  SETTINGS = "SETTINGS",
  HOME = "HOME",
  PRIVACY_EULA = "PRIVACY_EULA",
}
