import { createAsyncThunk } from "@reduxjs/toolkit";
import UserDataModel from "../../models/UserDataModel";
import { db } from "../../services/firebase_services";

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getUserData = createAsyncThunk(
  "GetUserData",
  async ({ id }: any): Promise<UserDataModel | undefined> => {
    try {
      let user = db.ref("users/" + id);
      let userObj: any = {};

      await user.once("value", function (userFound: any) {
        if (userFound !== null) {
          userObj = userFound.val();

          console.log("We got: ", userObj);
          // Subscribe to my topic if i have not
        }
      });

      return userObj;
    } catch (err: any) {
      console.log(err);
    }
  }
);
