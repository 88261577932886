import { useEffect, useState } from "react";
import { getCurrentDateTime, useTools } from "../../services/general_services";
import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { getClientById } from "../../store/thunks/client.thunks";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AttachFile,
  Cancel,
  ChevronLeft,
  Close,
  Delete,
  Edit,
  Launch,
  Phone,
} from "@mui/icons-material";
import { setSelectedEditClient } from "../../store/slices/client.slice";
import { styles } from "../../assets/style/styles";
import { db } from "../../services/firebase_services";
import { primaryColor } from "../../assets/style/theme";
import moment, { isDate } from "moment";
import { findUserTrips } from "../../store/thunks/booking.thunks";
import { RideGrid } from "../components/rides/RideGrid";
import { isUserBanned } from "../../services/data_saving_services";

export function ViewClientScreen() {
  const { dispatch, navigate } = useTools();
  const {
    selectedViewClient,
    selectedViewClientLoading,
    selectedViewClientError,
    selectedClientTrips,
    selectedClientTripsLoading,
  } = useSelector((state: any) => state.ClientSlice);
  const { id } = useParams();

  const [initialized, setInitialized] = useState(false);
  const [confirmBanUser, setConfirmBanUser] = useState(false);

  const [showDocments, setShowDocuments] = useState(false);

  const [userBanned, setUserBanned] = useState(false);
  async function getUserBanStatus(id: string) {
    const userBanned = await isUserBanned(id);
    setUserBanned(userBanned);
    return;
  }
  useEffect(() => {
    if (!initialized && id) {
      dispatch(getClientById({ id: id }));

      getUserBanStatus(id);
      setInitialized(true);
    }
  }, []);

  function banUser() {
    if (id) {
      db.ref("/users/" + id + "/banned").set(true);
      db.ref("/banned_users/" + id).set(true);
      setConfirmBanUser(false);
      setUserBanned(true);
    }
  }
  function unbanUser() {
    if (id) {
      db.ref("/users/" + id + "/banned").set(false);
      db.ref("/banned_users/" + id).remove();
      setUserBanned(false);
    }
  }

  useEffect(() => {
    if (selectedViewClient) {
      dispatch(findUserTrips({ userId: selectedViewClient.id }));
    }
  }, [selectedViewClient]);

  return (
    <Grid container xs={12} item>
      <Grid
        container
        item
        xs={12}
        sx={{ padding: 2 }}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item container xs={12} padding={0}>
          <Button
            onClick={() => {
              navigate("/admin/clients");
            }}
            variant={"text"}
            sx={{ position: "relative", top: -40, left: 0 }}
          >
            <ChevronLeft />
            Back to clients
          </Button>
        </Grid>

        {selectedViewClientLoading ? (
          <CircularProgress />
        ) : selectedViewClient ? (
          <Grid item container>
            <Grid
              item
              container
              sx={{ marginBottom: 3 }}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Typography variant={"h6"}>Client Profile</Typography>

              <Grid item container justifyContent={"flex-end"}>
                {/* <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    backgroundColor: "rgba(57,222,84,0.2)",

                    borderRadius: 3,
                    padding: 0,
                    paddingLeft: 0,
                    marginRight: 1,
                    height: "50px",
                    maxWidth: 130,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(`tel:+44${selectedViewClient.phoneNumber}`);
                  }}
                >
                  <Grid
                    item
                    container
                    xs={4}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Phone style={{ color: "#39DE54" }} fontSize="small" />
                  </Grid>
                  <Grid item container flexDirection={"column"} xs={8}>
                    <Typography
                      style={{ color: "#39DE54" }}
                      variant={"h6"}
                      fontSize={16}
                    >
                      Call
                    </Typography>
                    <Typography variant={"body1"} fontSize={11}>
                      Call Client.
                    </Typography>
                  </Grid>
                </Grid> */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: userBanned ? primaryColor : "red",
                    marginRight: 1,
                  }}
                  onClick={() => {
                    if (userBanned) {
                      unbanUser();
                    } else {
                      setConfirmBanUser(true);
                    }
                  }}
                >
                  {userBanned ? "Unban User" : "Ban User"}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (
                      selectedViewClient &&
                      selectedViewClient.user.customerId
                    ) {
                      window.open(
                        "https://dashboard.stripe.com/test/customers/" +
                          selectedViewClient.user.customerId
                      );
                    }
                  }}
                >
                  View Stripe
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent={"flex-start"}
              sx={{ padding: 1 }}
              spacing={1}
            >
              <Grid item container xs={3} sx={{ padding: "0 !important" }}>
                <Grid
                  item
                  boxShadow={1}
                  sx={{
                    backgroundImage: `url("${
                      selectedViewClient.user.profilePictureUrl
                        ? selectedViewClient.user.profilePictureUrl
                        : "/static/avatar.svg"
                    }")`,
                    padding: 0,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: 175,
                    width: 175,
                    borderRadius: 4,
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={4}
                alignContent={"space-evenly"}
                justifyContent={"flex-start"}
              >
                <Grid
                  item
                  container
                  xs={7.9}
                  justifyContent={"flex-start"}
                  alignContent={"flex-start"}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {selectedViewClient.user.firstName +
                        " " +
                        selectedViewClient.user.lastName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontSize={12}
                      sx={{ marginTop: 0 }}
                      color={primaryColor}
                    >
                      {selectedViewClient.user.emailAddress}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" fontSize={13}></Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontSize={13}
                      sx={{
                        backgroundColor: "rgba(90,96,255,0.2)",
                        color: "#5A60FF",
                        padding: 1,
                        paddingLeft: 2,
                        paddingRight: 2,
                        width: "fit-content",
                        borderRadius: 25,
                        textTransform: "capitalize",
                      }}
                    >
                      {selectedViewClient.user.lastActiveDate
                        ? moment(selectedViewClient.user.lastActiveDate).from(
                            getCurrentDateTime()
                          )
                        : "Never Logged In"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Modals */}
            <Fade in={confirmBanUser}>
              <Grid
                sx={{
                  ...styles.modalBackdrop,
                }}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item container xs={11} sm={5} sx={{ ...styles.modal }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: 40 }}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"h6"}>Are you sure?</Typography>

                    <IconButton
                      onClick={() => {
                        setConfirmBanUser(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    <img src="/static/rubbish.svg" width={250} />
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    <Typography variant="body1">
                      If you ban this client, they will be unable to access the
                      GEO-GO application, and any associated GEO-GO databases.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"flex-end"}
                    spacing={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Button
                      variant={"contained"}
                      startIcon={<Cancel />}
                      style={{ backgroundColor: "grey", marginRight: 5 }}
                      onClick={() => {
                        setConfirmBanUser(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={"contained"}
                      startIcon={<Delete />}
                      onClick={() => {
                        banUser();
                      }}
                      style={{ backgroundColor: "red", zIndex: 1 }}
                    >
                      Ban User
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>

            <Fade in={showDocments}>
              <Grid
                sx={{
                  ...styles.modalBackdrop,
                }}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item container xs={5} sx={{ ...styles.modal }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: 40 }}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"h6"}>Documents</Typography>

                    <IconButton
                      onClick={() => {
                        setShowDocuments(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    {selectedViewClient &&
                      selectedViewClient.documents &&
                      selectedViewClient.documents.map(
                        (val: any, index: number) => {
                          return (
                            <Grid
                              container
                              item
                              xs={12}
                              spacing={2}
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{ height: 48 }}
                              key={index}
                            >
                              <Grid
                                item
                                container
                                xs={7}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Typography variant={"h6"} fontSize={13}>
                                  {val.name}{" "}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={3}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Typography variant={"body1"} fontSize={13}>
                                  {val.type}
                                </Typography>
                              </Grid>
                              <Grid item container xs={1}>
                                <IconButton
                                  style={{ zIndex: 10 }}
                                  onClick={() => {
                                    window
                                      .open(val.downloadUrl, "_blank")
                                      ?.focus();
                                  }}
                                >
                                  <Launch />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"flex-end"}
                    spacing={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Button
                      variant={"contained"}
                      startIcon={<Cancel />}
                      style={{ backgroundColor: "grey", marginRight: 5 }}
                      onClick={() => {
                        setShowDocuments(false);
                      }}
                    >
                      Close Documents
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        ) : (
          <Typography>Nothing here</Typography>
        )}

        {selectedClientTripsLoading ? (
          <CircularProgress />
        ) : (
          <RideGrid
            rows={selectedClientTrips ? selectedClientTrips.trips : []}
          />
        )}
      </Grid>
    </Grid>
  );
}
