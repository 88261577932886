import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : "linear-gradient(#02294F, #090E10)",
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 6 },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={7}
          sx={{
            marginTop: { xs: 10, sm: -20 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{ width: { xs: "100%", sm: "90%" } }}
          >
            <Typography
              component="h1"
              variant="h1"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignSelf: "flex-start",
                textAlign: "left",
              }}
            >
              Travel in&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.main"
                      : "primary.light",
                }}
              >
                luxury.
              </Typography>
            </Typography>
            <Typography variant="body1" textAlign="left" color="text.secondary">
              Indulge in luxury travel with our sleek taxi app, ensuring
              seamless booking and premium service.
            </Typography>
            <Grid
              container
              justifyContent="flex-start"
              paddingTop={2}
              paddingBottom={2}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ width: 200 }}
                component="a"
                href="https://onelink.to/2qqbx7"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Now
              </Button>
            </Grid>
          </Stack>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={5}
          alignContent={"flex-end"}
          sx={{
            justifyContent: { xs: "center", sm: "flex-end" },
            marginLeft: { xs: 0, sm: -10 },
            marginTop: { xs: 5 },
          }}
        >
          <img src="/static/geo_driver_on_way.png" width={"70%"} />
        </Grid>
      </Container>
    </Box>
  );
}
