import * as React from "react";
import Slider, { SliderProps } from "@mui/material/Slider";
import { alpha, styled } from "@mui/material/styles";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Search } from "@mui/icons-material";
import { styles } from "../../../assets/style/styles";

const TextFieldRegPlate = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: "100%",
  color: "#000",
  height: 40,
  border: "none",
  ...styles.regPlate,
}));

export default function RegPlateTextField({
  placeholder,
  value,
  onChange,
  style,
}: TextFieldProps) {
  return (
    <TextFieldRegPlate
      defaultValue={30}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={{ ...style, border: "none" }}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        style: {
          color: "black",
          fontFamily: "Montserrat",
          fontWeight: "800",
          border: "none",
          padding: 10,
        },
      }}
    />
  );
}
