import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { DriverAndDocModel } from "../../../models/DriverModel";
import { Download, Launch } from "@mui/icons-material";
import { setSelectedViewDriver } from "../../../store/slices/driver.slice";
import { useTools } from "../../../services/general_services";

export function DriverDocsGrid({ rows }: { rows: DriverAndDocModel[] }) {
  const { driverList } = useSelector((state: any) => state.DriverSlice);

  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: "driver",
      headerName: "Driver",
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid
            item
            container
            xs={12}
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
            style={{
              height: "52px",
            }}
            flexDirection={"row"}
          >
            {/* Circle Profile Pic */}
            <Grid
              item
              container
              xs={4}
              sx={{ height: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Avatar
                style={{
                  width: "28px",
                  height: "28px",
                  marginRight: 10,
                }}
                alt={params.row.driver.firstName}
                src={params.row.driver.profilePictureUrl}
              />
            </Grid>

            {/* Profile Information */}
            <Grid item xs={8} flexDirection={"column"}>
              <Typography
                variant={"h6"}
                style={{ lineHeight: 1.2, fontSize: 15 }}
              >
                {params.row.driver.firstName}
              </Typography>
              <Typography
                variant={"body1"}
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontFamily: "Roboto",
                  fontWeight: 200,
                  fontSize: 12,
                }}
              >
                +44 {params.row.driver.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "document",
      headerName: "File Name",
      renderCell: (params) => {
        return (
          <Typography
            variant={"body1"}
            style={{
              color: "rgba(0,0,0,0.4)",
              fontFamily: "Roboto",
              fontWeight: 200,
              fontSize: 12,
            }}
          >
            {params.row.document.name}
          </Typography>
        );
      },
      flex: 1,
    },

    {
      field: "action2",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton
            key={"id2"}
            onClick={() => {
              console.log(params.row.document.downloadUrl);
              window.open(params.row.document.downloadUrl, "_blank")?.focus();
            }}
          >
            <Download />
          </IconButton>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton
            key={"id2"}
            onClick={() => {
              dispatch(setSelectedViewDriver(params.row.driver));
              navigate("/admin/drivers/" + params.row.driver.id);
            }}
          >
            <Launch />
          </IconButton>
        );
      },
    },
  ];
  function getRowId(row: any) {
    return row.id;
  }
  return (
    <DataGrid
      rows={rows}
      getRowId={getRowId}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: "none",
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      onCellClick={(e) => {
        if (e.field === "driver") {
          navigate("/admin/drivers/" + e.row.driver.id);
          dispatch(setSelectedViewDriver(e.row.driver));
        }
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ height: 250 }}
    />
  );
}
