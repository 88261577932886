import { useEffect } from "react";
import { useTools } from "../../services/general_services";
import { setPage } from "../../store/slices/general.slice";
import { PageType } from "../../models/PageType";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { CarClassModel } from "../../models/CarClassModel";
import { getCarClassList } from "../../store/thunks/carClass.thunks";
import { Add } from "@mui/icons-material";
import {
  setCreateNewCarClass,
  setSelectedEditCarClass,
} from "../../store/slices/carClass.slice";
import { setCreateNewDriver } from "../../store/slices/driver.slice";
import {
  setCreateNewVoucher,
  setSelectedEditVoucher,
} from "../../store/slices/voucher.slice";
import { VoucherModel } from "../../models/VoucherModel";
import { getVoucherList } from "../../store/thunks/voucher.thunks";

export function SettingsScreen() {
  const { dispatch, navigate } = useTools();

  const { carClassList, carClassListLoading } = useSelector(
    (state: any) => state.CarClassSlice
  );
  const { voucherList, voucherListLoading } = useSelector(
    (state: any) => state.VoucherSlice
  );

  useEffect(() => {
    dispatch(setPage(PageType.SETTINGS));
    dispatch(getVoucherList());
  }, []);
  return (
    <Grid item container xs={12} sx={{ padding: 2 }} spacing={2}>
      <Grid
        item
        container
        sx={{ marginBottom: 1 }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography variant={"h6"}>Car Classes</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            dispatch(setCreateNewCarClass(true));
          }}
          sx={{ marginLeft: 3, borderRadius: 20 }}
        >
          Add Class
        </Button>
      </Grid>
      {/* Car class container */}
      <Grid container item sx={{ paddingTop: "0px !important" }}>
        <Grid item container sx={{ overflowX: "scroll", overflowY: "visible" }}>
          <Grid
            container
            item
            style={{
              height: carClassList && carClassList.length > 0 ? 300 : 50,
              minWidth: carClassList ? carClassList.length * 213 + 10 : 0,
              width: "max-content",
              marginBottom: 2,
              paddingLeft: "14px",
              marginTop: 2,
            }}
            spacing={2}
          >
            {carClassListLoading ? (
              <CircularProgress />
            ) : carClassList && carClassList.length !== 0 ? (
              carClassList.map((val: CarClassModel, index: number) => {
                console.log("Car class");
                return (
                  <Grid container justifyContent={"center"} sx={{ width: 212 }}>
                    <Paper
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 5,
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        marginRight: index === carClassList.length - 1 ? 0 : 2,
                        boxShadow: 2,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        justifyContent={"center"}
                        padding={2}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid
                            item
                            container
                            justifyContent={"center"}
                            style={{
                              width: 140,
                              height: 140,
                            }}
                            alignItems={"center"}
                          >
                            <img src={val.imageUrl} style={{ width: "90%" }} />
                          </Grid>
                        </Grid>

                        <Grid item container xs={12} justifyContent={"center"}>
                          <Typography variant={"h6"}>{val.name}</Typography>
                        </Grid>
                        <Grid item container xs={12} justifyContent={"center"}>
                          <Button
                            style={{ width: "100%", borderRadius: 220 }}
                            variant="contained"
                            onClick={() => {
                              dispatch(setSelectedEditCarClass(val));
                            }}
                          >
                            Edit Class
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })
            ) : (
              <Grid item container flexDirection={"column"}>
                <Typography>You have no car classes.</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        sx={{ marginBottom: 1 }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography variant={"h6"}>Vouchers</Typography>

        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            dispatch(setCreateNewVoucher(true));
          }}
          sx={{ marginLeft: 3, borderRadius: 20 }}
        >
          Add Voucher
        </Button>
      </Grid>

      <Grid
        item
        container
        sx={{
          overflowX: "scroll",
          overflowY: "visible",
          marginBottom: { xs: 10, sm: 0 },
          paddingTop: "0px !important",
        }}
      >
        <Grid
          container
          item
          style={{
            minWidth: voucherList ? voucherList.length * 220 : 0,
            marginBottom: 2,
            paddingLeft: 2,
            marginTop: 2,
          }}
          spacing={1}
        >
          {voucherListLoading ? (
            <CircularProgress />
          ) : voucherList && voucherList.length > 0 ? (
            voucherList.map((val: VoucherModel) => {
              return (
                <Grid
                  item
                  container
                  xs={3}
                  justifyContent={"center"}
                  sx={{ minWidth: 210 }}
                >
                  <Paper
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 5,
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: 2,
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={2}
                      justifyContent={"center"}
                      padding={2}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"flex-start"}
                      >
                        <Typography variant={"h6"}>{val.name}</Typography>
                        <Grid
                          item
                          container
                          justifyContent={"space-between"}
                          sx={{ marginTop: 2 }}
                          alignItems={"center"}
                        >
                          <Typography variant={"body1"}>{val.code}</Typography>
                          <Typography
                            variant={"body1"}
                            style={{
                              padding: 4,
                              paddingLeft: 8,
                              paddingRight: 8,
                              backgroundColor: "rgba(95,102,234,0.5)",
                              borderRadius: 44,
                              color: "#fff",
                            }}
                          >
                            {val.discountPercent}% Off
                          </Typography>{" "}
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} justifyContent={"center"}>
                        <Button
                          style={{ width: "100%", borderRadius: 220 }}
                          variant="contained"
                          onClick={() => {
                            dispatch(setSelectedEditVoucher(val));
                          }}
                        >
                          Edit Voucher
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })
          ) : (
            <Grid item container flexDirection={"column"}>
              <Typography>You have no vouchers.</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
