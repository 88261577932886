import { useEffect, useRef, useState } from "react";
import { useTools } from "../../services/general_services";
import { setPage, setTimeframe } from "../../store/slices/general.slice";
import { PageType } from "../../models/PageType";
import {
  Avatar,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { InfoPanel } from "../components/dashboard/InfoPanel";
import { EventAvailable, Payments } from "@mui/icons-material";
import { TimeFrame } from "../../models/TimeFrameModel";
import {
  findAllDriverEarnings,
  findTrips,
} from "../../store/thunks/booking.thunks";
import { useSelector } from "react-redux";
import { RideGrid } from "../components/rides/RideGrid";
import { DriverEarningModel } from "../../models/EarningsModel";
import { DailyChart } from "../components/charts/DailyChart";
import { TimeFrameSelect } from "../components/general/TimeFrameSelect";

export function DashboardScreen({ mapboxgl }: any) {
  const { dispatch, navigate } = useTools();

  const { timeframe } = useSelector((state: any) => state.GeneralSlice);
  const { trips, earnings, numTrips, grossProfit, netProfit } = useSelector(
    (state: any) => state.BookingSlice
  );

  useEffect(() => {
    dispatch(setPage(PageType.DASHBOARD));
  }, []);

  const driverData = [
    {
      name: "Muhammed Arkins",
      orders: 23,
      income: 34.2,
      image:
        "https://www.wikihow.com/images/9/90/What_type_of_person_are_you_quiz_pic.png",
      number: "44725788489",
    },
    {
      name: "Alice Jones",
      orders: 20,
      income: 31.2,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDfNptdvekR5KCo8abtfUP7aTWsg9TfPF7sseU-5mw469yTR--lZRkA3aRHA55KECxPh8&usqp=CAU",

      number: "4479114123",
    },
    {
      name: "Mark Long",
      orders: 19,
      income: 29.5,
      image:
        "https://www.wikihow.com/images/9/90/What_type_of_person_are_you_quiz_pic.png",
      number: "44725788246",
    },
    {
      name: "Georgian-Razvan Caparci",
      orders: 23,
      income: 34.2,
      image:
        "https://www.wikihow.com/images/9/90/What_type_of_person_are_you_quiz_pic.png",
      number: "447877621649",
    },
  ];

  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);

  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(15);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (mapboxgl) {
      console.log("intiialising ma");
      // map.current = new mapboxgl.Map({
      //   container: mapContainer.current,
      //   style: "mapbox://styles/mapbox/streets-v11",
      //   center: [lng, lat],
      //   zoom: zoom,
      // });
    }
  }, [mapboxgl]);

  // Get data based on timeframe.

  return (
    <Grid item container xs={12}>
      {/* Graph Chart - Top Left */}
      <Grid
        item
        container
        xs={12}
        sm={7}
        alignItems={"center"}
        flexDirection={"column"}
        sx={{ padding: 2 }}
      >
        {/* Top Title Section */}
        <Grid
          item
          container
          sx={{ marginBottom: { xs: 2, sm: 2 } }}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Grid item container xs={12} md={7}>
            <Typography variant={"h6"}>Knowledge Base</Typography>
          </Grid>
          <Grid item container xs={12} md={5} justifyContent={"flex-end"}>
            {" "}
            <Grid
              item
              container
              sx={{
                width: { xs: "100%", md: "160px" },
              }}
            >
              <TimeFrameSelect />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item style={{ height: 100 }} columnSpacing={2}>
            <Grid item xs={4}>
              <InfoPanel
                backgroundColor={"rgba(0,238,253,0.19)"}
                icon={
                  <Payments fontSize={"medium"} style={{ color: "#249BC1" }} />
                }
                color={"#249BC1"}
                title={"Gross Profit"}
                value={`£${grossProfit}`}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoPanel
                backgroundColor={"rgba(158,255,0,0.2)"}
                icon={
                  <Payments fontSize={"medium"} style={{ color: "#24C18F" }} />
                }
                color={"#24C18F"}
                title={"Net Profit"}
                value={`£${netProfit}`}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoPanel
                backgroundColor={"rgba(255,51,63,0.19)"}
                icon={
                  <EventAvailable
                    fontSize={"medium"}
                    style={{ color: "red" }}
                  />
                }
                color={"red"}
                title={"Bookings"}
                value={numTrips}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{ height: "350px", borderRadius: 10, marginTop: 2 }}
          >
            <DailyChart />
          </Grid>
        </Grid>
      </Grid>

      {/* Top Drivers - Top Right */}
      <Grid item container xs={12} sm={5} sx={{ padding: 2 }}>
        <Grid
          item
          container
          sx={{ marginBottom: 2 }}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          alignContent={
            earnings && earnings.length > 0 ? "flex-start" : "center"
          }
        >
          <Grid item container>
            <Typography variant={"h6"} sx={{ marginBottom: 2 }}>
              Top Drivers
            </Typography>
          </Grid>

          {earnings &&
            earnings.map((val: DriverEarningModel) => {
              return (
                <Grid
                  item
                  container
                  xs={12}
                  sx={{
                    backgroundColor: "#F7F9FC",
                    borderRadius: 5,
                    marginBottom: 2,
                    padding: 2,
                  }}
                  alignSelf={"flex-start"}
                  justifyContent={"space-between"}
                >
                  <Grid item container xs={2}>
                    <Avatar src={val.driver.profilePictureUrl} />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    md={5}
                    flexDirection={"column"}
                    sx={{ paddingLeft: 1 }}
                  >
                    <Typography variant={"h6"} fontSize={13}>
                      {val.driver.firstName + " " + val.driver.lastName}
                    </Typography>
                    <Typography variant={"body2"} fontSize={10}>
                      +{val.driver.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    md={4}
                    justifyContent={"flex-end"}
                  >
                    <Grid
                      container
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant={"body2"}
                        sx={{ marginRight: 1, fontSize: 13 }}
                      >
                        Orders:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        {val.orders}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant={"body2"}
                        sx={{ marginRight: 1, fontSize: 13 }}
                      >
                        Income:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        £{val.amount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

          {earnings.length === 0 && (
            <Grid item container justifyContent={"center"}>
              <Grid item xs={6} flexDirection={"column"} alignItems={"center"}>
                <img
                  src={"/static/driver.svg"}
                  style={{
                    width: 140,
                    height: 140,

                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    background: "none",
                  }}
                />
                <Typography align="center">No Top Drivers</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Recent Journeys - Bottom */}
      <Grid item container xs={12}>
        <RideGrid rows={trips ? trips : []} />
      </Grid>
    </Grid>
  );
}
