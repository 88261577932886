import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment, { isDate } from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DriverModel } from "../../../models/DriverModel";
import { Edit, Launch } from "@mui/icons-material";
import {
  setSelectedEditDriver,
  setSelectedViewDriver,
} from "../../../store/slices/driver.slice";
import { useTools } from "../../../services/general_services";
import { RideAppModel, TripType } from "../../../models/BookingModels";
import { styles } from "../../../assets/style/styles";
import { setSelectedViewClient } from "../../../store/slices/client.slice";
import { setSelectedTrip } from "../../../store/slices/booking.slice";

export function RideGrid({
  rows,
}: {
  rows: Array<{
    trip: RideAppModel;
    type: TripType;
  }>;
}) {
  const { driverList } = useSelector((state: any) => state.DriverSlice);

  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: "bookingUser",
      headerName: "User",
      flex: 2,
      renderCell: (params) => {
        return (
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
            style={{
              height: "52px",
            }}
            flexDirection={"row"}
          >
            {/* Circle Profile Pic */}
            <Grid
              item
              container
              xs={4}
              sx={{ height: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Avatar
                style={{
                  width: "28px",
                  height: "28px",
                  marginRight: 10,
                }}
                alt={params.row.trip.bookingUser.firstName}
                src={params.row.trip.bookingUser.profilePictureUrl}
              />
            </Grid>

            {/* Profile Information */}
            <Grid item xs={8} flexDirection={"column"}>
              <Typography
                variant={"h6"}
                style={{ lineHeight: 1.2, fontSize: 15 }}
              >
                {params.row.trip.bookingUser.firstName}
              </Typography>
              <Typography
                variant={"body1"}
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontFamily: "Roboto",
                  fontWeight: 200,
                  fontSize: 12,
                }}
              >
                +44 {params.row.trip.bookingUser.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "driversResponded",
      headerName: "Driver",
      flex: 2,
      valueGetter: (params) => {
        if (
          params.row.trip.driversResponded[
            params.row.trip.driversResponded.length - 1
          ]
        ) {
          return `${
            params.row.trip.driversResponded
              ? params.row.trip.driversResponded[
                  params.row.trip.driversResponded.length - 1
                ].driver.firstName +
                params.row.trip.driversResponded[
                  params.row.trip.driversResponded.length - 1
                ].driver.lastName
              : ""
          }`;
        } else {
          return "";
        }
      },
      renderCell: (params) => {
        if (
          params.row.trip &&
          params.row.trip.driversResponded &&
          params.row.trip.driversResponded.length > 0
        ) {
          let driver =
            params.row.trip.driversResponded[
              params.row.trip.driversResponded.length - 1
            ].driver;

          if (driver && driver !== null) {
            return (
              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"center"}
                style={{
                  height: "52px",
                }}
                flexDirection={"row"}
              >
                {/* Circle Profile Pic */}
                <Grid
                  item
                  container
                  xs={4}
                  sx={{ height: "100%" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      alignSelf: "center",
                      borderRadius: 25,
                      backgroundImage: `url(${
                        driver ? driver.profilePictureUrl : "/static/avatar.svg"
                      }`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </Grid>

                {/* Profile Information */}
                <Grid item xs={8} flexDirection={"column"}>
                  <Typography
                    variant={"h6"}
                    style={{ lineHeight: 1.2, fontSize: 15 }}
                  >
                    {driver.firstName}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    style={{
                      color: "rgba(0,0,0,0.4)",
                      fontFamily: "Roboto",
                      fontWeight: 200,
                      fontSize: 12,
                    }}
                  >
                    +44 {driver.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Grid item container justifyContent={"center"}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 12,
                  }}
                  variant={"body1"}
                >
                  {" "}
                  No driver.
                </Typography>
              </Grid>
            );
          }
        } else {
          return (
            <Grid item container justifyContent={"center"}>
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: 12,
                }}
                variant={"body1"}
              >
                {" "}
                No driver.
              </Typography>
            </Grid>
          );
        }
      },
    },
    {
      field: "selectedCarClass",
      headerName: "Car Class",
      valueGetter: (params) => {
        return `${params.row.trip.selectedCarClass.name}`;
      },
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid item container justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "400",
                fontSize: 12,
              }}
              variant={"body1"}
            >
              {params.row.trip.selectedCarClass
                ? params.row.trip.selectedCarClass.name
                : "Not selected."}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: "creationDateTime",
      headerName: "Trip Date",
      valueGetter: (params) => {
        return `${
          params.row.trip.dateTime
            ? params.row.trip.dateTime
            : params.row.trip.creationDateTime
        }`;
      },
      flex: 1.5,
      type: "string",
      renderCell: (params) => {
        return (
          <Grid item container justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "400",
                fontSize: 12,
              }}
              variant={"body1"}
            >
              {params.row.trip.dateTime
                ? moment(params.row.trip.dateTime).format("Do MMM HH:mma")
                : moment(params.row.trip.creationDateTime).format(
                    "Do MMM YYYY HH:mma"
                  )}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: "amountPaid",
      headerName: "Cost",
      valueGetter: (params) => {
        return `${
          params.row.trip.amountPaid
            ? "£" + params.row.trip.amountPaid
            : "£" + params.row.trip.cost + " (Est)"
        }`;
      },
      renderCell: (params) => {
        return (
          <Grid item container justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "400",
                fontSize: 12,
              }}
              variant={"body1"}
            >
              {params.row.trip.amountPaid
                ? "£" + params.row.trip.amountPaid
                : "£" + params.row.trip.cost + " (Est)"}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: "driverPaid",
      headerName: "Payout",
      valueGetter: (params) => {
        return `${
          params.row.trip.driverPaid
            ? "£" + params.row.trip.driverPaid
            : "Unpaid"
        }`;
      },
      renderCell: (params) => {
        return (
          <Grid item container justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "400",
                fontSize: 12,
              }}
              variant={"body1"}
            >
              {params.row.trip.driverPaid
                ? "£" + params.row.trip.driverPaid
                : "Unpaid"}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: "cost2",
      headerName: "Profit",
      valueGetter: (params) => {
        return params.row.trip.amountPaid
          ? `${
              "£" +
              (
                Number.parseFloat(params.row.trip.amountPaid) -
                Number.parseFloat(params.row.trip.driverPaid)
              ).toFixed(2)
            }`
          : "Unpaid";
      },
      renderCell: (params) => {
        if (params.row.trip.cost && params.row.trip.selectedCarClass)
          return (
            <Tooltip
              title={
                params.row.trip.amountPaid
                  ? `Total: £${params.row.trip.amountPaid} \n\n Driver Paid: £${params.row.trip.driverPaid}`
                  : "Unpaid"
              }
            >
              <div
                style={{
                  backgroundColor: params.row.trip.amountPaid
                    ? "rgba(100,255,100,0.2)"
                    : "rgba(90,96,255,0.2)",
                  color: "black",
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 25,
                  fontSize: 12,
                }}
              >
                {params.row.trip.amountPaid
                  ? "£" +
                    (
                      Number.parseFloat(params.row.trip.amountPaid) -
                      Number.parseFloat(params.row.trip.driverPaid)
                    ).toFixed(2)
                  : "Unpaid"}
              </div>
            </Tooltip>
          );
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton key={"id2"} onClick={() => {}}>
            <Launch />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: "none",
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      onCellClick={(e) => {
        if (
          e.field === "driversResponded" &&
          e.row.trip.driversResponded[e.row.trip.driversResponded.length - 1] &&
          e.row.trip.driversResponded[e.row.trip.driversResponded.length - 1]
            .driver
        ) {
          navigate(
            "/admin/drivers/" +
              e.row.trip.driversResponded[
                e.row.trip.driversResponded.length - 1
              ].driver.id
          );
          dispatch(
            setSelectedViewDriver(
              e.row.trip.driversResponded[
                e.row.trip.driversResponded.length - 1
              ].driver
            )
          );
        } else if (e.field === "bookingUser") {
          navigate("/admin/clients/" + e.row.trip.bookingUser.id);
          dispatch(setSelectedViewClient(e.row.trip.bookingUser));
        } else {
          navigate("/admin/rides/" + e.row.trip.id);
          dispatch(setSelectedTrip(e.row));
        }
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ height: 300 }}
    />
  );
}
