//File containing login methods
import LoginErrorModel from "../models/LoginErrorModel";
import { auth } from "./firebase_services";
import AuthUserModel from "../models/AuthUserModel";
import { setUserData, setUserId } from "../store/slices/user.slice";
import { setPage } from "../store/slices/general.slice";
import { PageType } from "../models/PageType";
import { getAdminStatus } from "../store/thunks/general.thunks";
import { getUserData } from "../store/thunks/user.thunks";
import { getCarClassList } from "../store/thunks/carClass.thunks";

// Watch auth state function is an async method to check if a user is logged in, and will take them to the
// verify email, or home page.
export async function watchAuthState(
  navigate: any,
  dispatch: any,
  page: PageType
): Promise<AuthUserModel | any> {
  auth.onAuthStateChanged(async function (result: any) {
    if (result && result !== null) {
      // Change to home.
      if (page === PageType.LOGIN) {
        navigate("/admin/dashboard");
      }
      dispatch(setUserId(result.uid));
    } else if (result) {
      navigate("/login");
    }
  });
}

// Login submit function will attempt to login a user, will set error message accordingly if needed.
export function loginSubmit(
  email: string,
  password: string,
  setEmailError: Function,
  setPasswordError: Function,
  navigate: any,
  dispatch: any,
  page: any
) {
  try {
    try {
      auth
        .setPersistence("session")
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          auth
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              setEmailError("");
              setPasswordError("");

              if (page === PageType.LOGIN) {
                navigate("/admin/dashboard");
                dispatch(setPage(PageType.DASHBOARD));
              }

              if (page !== PageType.DRIVERS) {
                dispatch(getAdminStatus({ id: auth.currentUser?.uid }));
              }

              dispatch(getUserData({ id: auth.currentUser?.uid }));
              dispatch(getCarClassList());
            })
            .catch((error: LoginErrorModel) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              if (errorCode === "auth/wrong-password") {
                setEmailError("");
                setPasswordError("Wrong password for this account.");
              } else if (errorCode === "auth/missing-password") {
                setEmailError("");
                setPasswordError("Wrong password for this account.");
              } else {
                setPasswordError("");
                setEmailError(errorMessage.split(":")[1]);
              }
            });
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;

          console.log(errorMessage);
        });
    } catch {}
  } catch {}
}

// Logout user function will log a user out, reset states and navigate the user to the login screen.
export function logoutUser(navigate: any, dispatch: any) {
  resetStates(dispatch);
  navigate("/login");
  auth.signOut();
}

// Reset states function will reset all redux states to their original values.
export function resetStates(dispatch: any) {
  dispatch(setUserId(""));
  dispatch(setUserData(undefined));
}

//Do password reset function will attempt to send a password reset email link to a user.
export function doPasswordReset(
  email: string,
  setErrorMessage: Function,
  setSent: Function,
  setSending: Function
) {
  auth
    .sendPasswordResetEmail(email)
    .then(() => {
      setSent(true);
      setSending(false);
    })
    .catch((error: any) => {
      setErrorMessage(error.message);
      setSending(false);
    });
}

// Check valid email function will check whether email string format is valid.
export function checkValidEmail(input: string) {
  if (
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      input
    )
  ) {
    return true;
  }
  return false;
}
