import {
  CircularProgress,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import "../../index.css";
import { LoginScreen } from "../pages/LoginScreen";
import { NavBar } from "./NavBar";
import { useEffect, useState } from "react";
import { Logout, Menu, MenuOpen } from "@mui/icons-material";
import { logoutUser } from "../../services/auth_services";
import { useTools } from "../../services/general_services";
import { BorderLine } from "./general/BorderLine";
import { DriverModalType } from "../../models/DriverModelType";
import { DriverModal } from "./drivers/DriverModal";
import { CarClassModal } from "./settings/CarClassModal";
import { VoucherModal } from "./settings/VoucherModal";
import { isMobile } from "react-device-detect";
import { PageType } from "../../models/PageType";
import {
  findAllDriverEarnings,
  findPayouts,
  findTrips,
} from "../../store/thunks/booking.thunks";
import { auth } from "../../services/firebase_services";
import { getAdminStatus } from "../../store/thunks/general.thunks";
import { getUserData } from "../../store/thunks/user.thunks";
import { getCarClassList } from "../../store/thunks/carClass.thunks";

export function AdminContainer({ children }: any) {
  const { admin, adminLoading, timeframe, page } = useSelector(
    (state: any) => state.GeneralSlice
  );

  const { userData } = useSelector((state: any) => state.UserSlice);

  const { navigate, dispatch, theme } = useTools();

  const [showNav, setShowNav] = useState(!isMobile);
  const [slideNav, setSlideNav] = useState(true);

  useEffect(() => {
    if (showNav) {
      setTimeout(() => {
        setSlideNav(true);
      }, 250);
    } else {
      setSlideNav(false);
    }
  }, [showNav]);

  useEffect(() => {
    // Get earnings and drivers.
    if (admin) {
      dispatch(findAllDriverEarnings({ timeframe: timeframe }));

      // Get rides for timeframe
      dispatch(findTrips({ timeframe: timeframe }));

      dispatch(findPayouts({ timeframe: timeframe }));
    }
  }, [timeframe, page]);

  useEffect(() => {
    console.log("Rendering");
    try {
      let unsubscribe = auth.onAuthStateChanged(async function (result: any) {
        if (result && result !== null) {
          console.log("state changed...", result);

          dispatch(getAdminStatus({ id: result.uid }));
          dispatch(getUserData({ id: result.uid }));
          dispatch(getCarClassList());

          return unsubscribe();
        }
      });
      unsubscribe();
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
    }
  }, []);

  return (
    <>
      {adminLoading ? (
        <Grid
          container
          item
          sx={{ height: "100vh", width: "100vw" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Grid>
      ) : admin && userData ? (
        <Grid
          container
          justifyContent={"flex-end"}
          sx={{
            backgroundColor: "black",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          {slideNav && (
            <Grid
              item
              container
              xs={showNav ? 12 : 0}
              sm={showNav ? 3 : 0}
              md={showNav ? 2.5 : 0}
              sx={{ overflow: "hidden" }}
            >
              <Slide timeout={300} in={slideNav} direction="right">
                <div>
                  <NavBar setShowNav={setShowNav} />
                </div>
              </Slide>
            </Grid>
          )}

          <Grid
            container
            item
            xs={showNav ? 0 : 12}
            sm={showNav ? 9 : 12}
            md={showNav ? 9.5 : 12}
            sx={{
              backgroundColor: "black",
              paddingTop: 1,
              paddingBottom: 1,
              paddingRight: 1,
              height: "100vh",
              transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 2,
                height: "100%",
                overflow: "hidden",
                overflowY: "scroll",
              }}
              flexDirection={"row"}
            >
              <Grid item container xs={12}>
                <Grid item container xs={10} alignItems={"center"}>
                  <IconButton
                    onClick={() => {
                      setShowNav(!showNav);
                    }}
                  >
                    {showNav ? <MenuOpen /> : <Menu />}
                  </IconButton>

                  <Typography
                    variant="h5"
                    fontWeight={"100"}
                    sx={{ paddingLeft: 2, fontStyle: "italic" }}
                  >
                    Hello, {userData.firstName} 👋
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <IconButton
                    onClick={() => {
                      logoutUser(navigate, dispatch);
                    }}
                  >
                    <Logout />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{ height: "100%", paddingTop: 5 }}
                alignItems={"flex-start"}
              >
                {children}
              </Grid>
            </Grid>
          </Grid>

          <DriverModal type={DriverModalType.CREATE} />
          <DriverModal type={DriverModalType.EDIT} />
          <CarClassModal />
          <VoucherModal />
        </Grid>
      ) : (
        <LoginScreen type={PageType.INITIAL} />
      )}
    </>
  );
}
