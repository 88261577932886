import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChairIcon from "@mui/icons-material/Chair";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import BadgeIcon from "@mui/icons-material/Badge";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { primaryColor } from "../../../assets/style/theme";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How do I contact customer support if I have a question or issue?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              You can reach our customer support team by emailing
              <Link> support@geo-go-exec.com</Link> - We&apos;re here to assist
              you promptly.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is Geo GO available everywhere?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Geo GO is currently only available within Cardiff & Newport, in
              Wales. Whilst "instant trips" depend on drivers being nearby, you
              can book a taxi or airport transfer wherever you are.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What makes your product stand out from others in the market?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{
                width: "100%",
              }}
            >
              <ListItem
                sx={{ marginBottom: "20px", bgcolor: "background.paper" }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: primaryColor }}>
                    <DirectionsCarIcon style={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Executive Cars at Affordable Prices"}
                  secondary={`We offer only executive-level cars on our platform, ensuring a luxurious experience.
Despite this, our prices remain competitive, aligning closely with the lowest tier services
of other ride-hailing companies.`}
                />
              </ListItem>

              <ListItem
                sx={{ bgcolor: "background.paper", marginBottom: "20px" }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: primaryColor }}>
                    <ChairIcon style={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Premium Comfort"}
                  secondary={`Our fleet consists of high-end vehicles, providing superior comfort and style for every
                  journey.`}
                />
              </ListItem>

              <ListItem
                sx={{ bgcolor: "background.paper", marginBottom: "20px" }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: primaryColor }}>
                    <BadgeIcon style={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Professional Drivers"}
                  secondary={`Our drivers are carefully selected, trained professionals who prioritize your safety,
                  punctuality, and satisfaction.`}
                />
              </ListItem>

              <ListItem
                sx={{ bgcolor: "background.paper", marginBottom: "20px" }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: primaryColor }}>
                    <PhonelinkRingIcon style={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Advanced Technology"}
                  secondary={`Our user-friendly app features advanced technology for easy booking, real-time tracking,
                  secure payments, and customizable ride preferences.`}
                />
              </ListItem>
              <ListItem
                sx={{ bgcolor: "background.paper", marginBottom: "20px" }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: primaryColor }}>
                    <ConnectingAirportsIcon style={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Airport Transfers"}
                  secondary={`Experience seamless airport transfers with us. Whether you're departing or arriving, our
                  service ensures a stress-free journey, providing reliable transportation to and from the
                  airport.`}
                />
              </ListItem>
              <ListItem
                sx={{ bgcolor: "background.paper", marginBottom: "20px" }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: primaryColor }}>
                    <SentimentSatisfiedAltIcon style={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Exceptional Service"}
                  secondary={`We prioritize customer service with a dedicated support team available 24/7 to assist
                  with any needs, ensuring a seamless and pleasant experience.`}
                />
              </ListItem>
              <ListItem
                sx={{ bgcolor: "background.paper", marginBottom: "20px" }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: primaryColor }}>
                    <AdminPanelSettingsIcon style={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Reliability and Safety"}
                  secondary={`Our service is known for its reliability, ensuring timely pickups and drop-offs. Safety is
                  paramount, with stringent checks and regular maintenance for all vehicles.`}
                />
              </ListItem>
            </List>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Enjoy a first-class travel experience at an unbeatable price,
              setting us apart in the taxi service market. With us, luxury and
              affordability go hand in hand.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Will i be guaranteed a driver?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Like most ridesharing apps, our fleet is dedicated to providing an
              excellent service to all of our customers. However, there may be
              times where our fleet is busy with other trips, and we encourage
              you to book ahead to avoid interruptions to your transportation
              schedule.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            window.location.href = "mailto:support@geo-go-exec.com";
          }}
        >
          Request Help
        </Button>
      </Box>
    </Container>
  );
}
