import { useEffect, useState } from "react";
import { useTools } from "../../services/general_services";
import { setPage } from "../../store/slices/general.slice";
import { PageType } from "../../models/PageType";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DarkTextField from "../components/general/TextFieldDarker";
import { Add } from "@mui/icons-material";
import { Location } from "../../models/LocationModel";
import { DriverGrid } from "../components/drivers/DriverGrid";
import { getDriverList } from "../../store/thunks/driver.thunks";
import { useSelector } from "react-redux";
import { DriverModel } from "../../models/DriverModel";
import { getCarClassList } from "../../store/thunks/carClass.thunks";
import { DriverModal } from "../components/drivers/DriverModal";
import { setCreateNewDriver } from "../../store/slices/driver.slice";
import { DriverModalType } from "../../models/DriverModelType";

export function DriversScreen() {
  const { dispatch, navigate } = useTools();
  const { driverList, driverListLoading } = useSelector(
    (state: any) => state.DriverSlice
  );

  const [searchDriverValue, setSearchDriverValue] = useState("");
  const [filterDriverLocation, setFilterDriverLocation] = useState<
    Location | string
  >(Location.NONE);

  const [driverListRetrieved, setDriverListRetrieved] = useState(false);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    dispatch(setPage(PageType.DRIVERS));
    if (!driverListRetrieved) {
      dispatch(getDriverList());
      setDriverListRetrieved(true);
    }
  }, []);

  // Update local list with data from driver list
  useEffect(() => {
    setFilteredList(driverList);
  }, [driverList]);

  // Filter by lcoation
  useEffect(() => {
    if (filterDriverLocation === Location.NONE) {
      setFilteredList(driverList);
    } else {
      setFilteredList(
        driverList.filter(
          (val: DriverModel) =>
            val.licensingCouncils &&
            val.licensingCouncils.find(
              (val: Location) => val === filterDriverLocation
            ) !== undefined
        )
      );
    }
  }, [filterDriverLocation]);

  // Search through items
  useEffect(() => {
    if (searchDriverValue === "") {
      setFilteredList(driverList);
    } else {
      let searchValue = searchDriverValue;
      if (searchDriverValue[0] === "0") {
        searchValue = searchDriverValue.slice(1);
      }
      let list = driverList.filter(
        (val: DriverModel) =>
          (val.id &&
            val.id.toLowerCase().includes(searchValue.toLowerCase())) ||
          (val.firstName &&
            val.firstName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (val.lastName &&
            val.lastName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (val.emailAddress &&
            val.emailAddress
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (val.registrationPlate &&
            val.registrationPlate
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (val.licensingCouncils &&
            val.licensingCouncils.find((val2: Location) => {
              if (
                val2 &&
                val2.toLowerCase().includes(searchValue.toLowerCase())
              ) {
                return val2;
              }
            }) !== undefined) ||
          (val.phoneNumber &&
            val.phoneNumber.toString().includes(searchValue.toLowerCase())) ||
          (val.emailAddress &&
            val.emailAddress.includes(searchValue.toLowerCase()))
      );
      setFilteredList(list);
    }
  }, [searchDriverValue]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{ padding: 2 }}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      {/* Top section - Search & Filter */}
      <Grid item container xs={12} alignItems={"center"}>
        <Grid item container xs={9} alignItems={"center"}>
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            style={{ marginRight: 30 }}
          >
            Drivers
          </Typography>

          <DarkTextField
            placeholder="Search Drivers..."
            value={searchDriverValue}
            onChange={(e) => {
              setSearchDriverValue(e.target.value);
            }}
            style={{
              width: "200px",
              borderRadius: 30,
              marginRight: 30,
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          />

          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              dispatch(setCreateNewDriver(true));
            }}
            sx={{ borderRadius: 20 }}
          >
            Add Driver
          </Button>
        </Grid>
        <Grid item container xs={3} justifyContent={"flex-end"}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterDriverLocation}
            style={{
              width: "160px",
              height: "40px",
              backgroundColor: "rgba(224,224,224,0.19)",
              border: "none",
              fontFamily: "Montserrat",
              fontSize: 12,
            }}
            onChange={(e) => {
              setFilterDriverLocation(e.target.value);
            }}
          >
            <MenuItem
              style={{ fontFamily: "Montserrat", fontSize: 15 }}
              value={Location.NONE}
            >
              None
            </MenuItem>
            <MenuItem
              style={{ fontFamily: "Montserrat", fontSize: 15 }}
              value={Location.CARDIFF}
            >
              Cardiff
            </MenuItem>
            <MenuItem
              style={{ fontFamily: "Montserrat", fontSize: 15 }}
              value={Location.NEWPORT}
            >
              Newport
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      {/* Bottom section - Driver Grid */}
      <Grid
        item
        container
        xs={12}
        sx={{ marginTop: 5 }}
        justifyContent={"center"}
      >
        {driverListLoading ? (
          <CircularProgress />
        ) : (
          <DriverGrid rows={filteredList} />
        )}
      </Grid>
    </Grid>
  );
}
