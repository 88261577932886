import { createAsyncThunk } from "@reduxjs/toolkit";

import { db } from "../../services/firebase_services";

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getAdminStatus = createAsyncThunk(
  "GetAdminStatus",
  async ({ id }: any): Promise<boolean> => {
    try {
      let user = db.ref("users/" + id);
      let userObj: any = {};

      let admin = false;

      await user.once("value", function (userFound: any) {
        if (userFound !== null) {
          userObj = userFound.val();
          if (userObj !== null && userObj.admin !== null && userObj.admin) {
            admin = true;
          }
        }
      });

      return admin;
    } catch (err: any) {
      // Go to error page if error occurs
      console.log(err);
      return false;
    }
  }
);
