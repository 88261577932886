import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CarClassModel } from "../../../models/CarClassModel";
import { primaryColor } from "../../../assets/style/theme";
import {
  Cancel,
  Close,
  Delete,
  HideSource,
  Refresh,
  Save,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ReactImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { db, storage } from "../../../services/firebase_services";
import { DriverModalType } from "../../../models/DriverModelType";
import { styles } from "../../../assets/style/styles";
import {
  setCarClassList,
  setCreateNewCarClass,
  setSelectedEditCarClass,
} from "../../../store/slices/carClass.slice";
import { DriverModel } from "../../../models/DriverModel";
import { isMobile } from "react-device-detect";

interface IProps {
  type: DriverModalType;
}

export function CarClassModal() {
  const {
    carClassList,
    carClassListLoading,
    selectedEditCarClass,
    createNewCarClass,
  } = useSelector((state: any) => state.CarClassSlice);

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [basePrice, setBasePrice] = useState(0);
  const [basePriceError, setBasePriceError] = useState("");
  const [numSeats, setNumSeats] = useState(0);
  const [numSeatsError, setNumSeatsError] = useState("");

  const [cancellationFee, setCancellationFee] = useState(0);
  const [cancellationFeeError, setCancellationFeeError] = useState("");
  const [companyCommission, setCompanyCommission] = useState(0);
  const [companyCommissionError, setCompanyCommissionError] = useState("");

  const [pricePerMin, setPricePerMin] = useState(0);
  const [pricePerMinError, setPricePerMinError] = useState("");

  const [pricePerMile, setPricePerMile] = useState(0);
  const [pricePerMileError, setPricePerMileError] = useState("");

  const [images, setImages] = useState<any[]>([]);
  const [imagesChanged, setImagedChanged] = useState(false);
  const [imagesError, setImagesError] = useState("");
  const maxNumber = 1;

  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const dropzoneRef = createRef<any>();

  const inputRef = useRef<any>();

  const onChange = (imageList: any[], addUpdateIndex: any) => {
    setImagedChanged(true);
    // data for submit
    setImages(imageList);
  };

  useEffect(() => {
    if (selectedEditCarClass) {
      selectedEditCarClass.name && setName(selectedEditCarClass.name);
      selectedEditCarClass.basePrice &&
        setBasePrice(Number.parseFloat(selectedEditCarClass.basePrice));
      selectedEditCarClass.cancellationFee &&
        setCancellationFee(
          Number.parseFloat(selectedEditCarClass.cancellationFee)
        );
      selectedEditCarClass.companyCommission &&
        setCompanyCommission(selectedEditCarClass.companyCommission);
      selectedEditCarClass.pricePerMin &&
        setPricePerMin(selectedEditCarClass.pricePerMin);
      selectedEditCarClass.pricePerMile &&
        setPricePerMile(selectedEditCarClass.pricePerMile);
      selectedEditCarClass.imageUrl &&
        setImages([{ data_url: selectedEditCarClass.imageUrl }]);
      selectedEditCarClass.numSeats &&
        setNumSeats(selectedEditCarClass.numSeats);
    } else {
      clearStates();
    }
  }, [selectedEditCarClass]);

  function clearStates() {
    setImages([]);
    setImagesError("");
    setName("");
    setNameError("");
    setBasePrice(0);
    setBasePriceError("");
    setCancellationFee(0);
    setCancellationFeeError("");
    setCompanyCommission(0);
    setCompanyCommissionError("");
    setPricePerMile(0);
    setPricePerMileError("");
    setPricePerMin(0);
    setPricePerMinError("");
    setNumSeats(0);
    setNumSeatsError("");
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(
    name: string,
    personName: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  async function validateCarClass() {
    let numErrors = 0;

    if (name === "") {
      setNameError("Name must not be empty");
      numErrors += 1;
    } else if ((name && name.length > 20) || name.length < 2) {
      setNameError("Name length must be between 2-20 characters.");
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(name)) {
      setNameError("Name must be text only.");
      numErrors += 1;
    } else {
      setNameError("");
    }

    // Base Price
    if (isNaN(basePrice)) {
      setBasePriceError("Value must be a number above 0.");
      numErrors += 1;
    } else if (basePrice <= 0) {
      setBasePriceError("Value must be a number above 0.");
      numErrors += 1;
    } else {
      setBasePriceError("");
    }

    //  Price per mile
    if (isNaN(pricePerMile)) {
      setPricePerMileError("Value must be a number above 0.");
      numErrors += 1;
    } else if (pricePerMile <= 0) {
      setPricePerMileError("Value must be a number above 0.");
      numErrors += 1;
    } else {
      setPricePerMileError("");
    }

    // Base Price
    if (isNaN(pricePerMin)) {
      setPricePerMinError("Value must be a number above 0.");
      numErrors += 1;
    } else if (pricePerMin <= 0) {
      setPricePerMinError("Value must be a number above 0.");
      numErrors += 1;
    } else {
      setPricePerMinError("");
    }

    // Base Price
    if (isNaN(companyCommission)) {
      setCompanyCommissionError("Value must be a number above 0.");
      numErrors += 1;
    } else if (companyCommission <= 0) {
      setCompanyCommissionError("Value must be a number above 0.");
      numErrors += 1;
    } else {
      setCompanyCommissionError("");
    }
    // Base Price
    if (isNaN(cancellationFee) || cancellationFee < 0) {
      setCancellationFeeError("Value must be a number above 0.");
      numErrors += 1;
    } else if (cancellationFee <= 0) {
      setCancellationFeeError("Value must be a number above 0.");
      numErrors += 1;
    } else if (cancellationFee > basePrice) {
      setCancellationFeeError("Cancellation fee must be less than base price.");
      numErrors += 1;
    } else {
      setCancellationFeeError("");
    }

    // Num seats
    if (isNaN(numSeats)) {
      setNumSeatsError("Value must be a number above 0.");
      numErrors += 1;
    } else if (numSeats <= 0) {
      setNumSeatsError("Value must be a number above 0.");
      numErrors += 1;
    } else {
      setNumSeatsError("");
    }
    if (images.length === 0) {
      setImagesError("Car class must have a picture for customers to view.");
      numErrors += 1;
    } else {
      setImagesError("");
    }

    console.log("Errors: ", numErrors);
    if (numErrors > 0) {
      toast.error("Errors within car class data");
    } else {
      console.log("doing it anyway dawg.", numErrors);
      if (createNewCarClass) {
        // Create Car Class
        createCarClass();
      } else {
        alterCarClass();
      }
    }
  }

  async function alterCarClass() {
    let imageUrl = selectedEditCarClass.imageUrl;

    try {
      const id = selectedEditCarClass.id;
      if (imagesChanged) {
        // Upload image...]
        const response = await fetch(images[0].data_url);
        const selfieBlob = await response.blob();
        // Store the selfie in the firebase store
        imageUrl = await storage
          .ref("/car_class_images/" + id + ".jpg")
          .put(selfieBlob)
          .then((snapshot: any) => {
            return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
          });
        toast.success("Image uploaded...");
      }

      let carClassObj: CarClassModel = {
        name,
        pricePerMile,
        pricePerMin,
        companyCommission,
        cancellationFee,
        id,
        imageUrl: imageUrl,
        basePrice: basePrice,
        numSeats: numSeats,
        public: selectedEditCarClass.public,
      };
      // let driverObj: DriverModel = {
      //   firstName,
      //   lastName,
      //   id: selectedEditDriver.id,
      //   driver: true,
      //   emailAddress: emailAddress,
      //   registrationPlate: registrationPlate,
      //   documents: documentList,
      //   profilePictureUrl: profilePictureUrl,
      //   licensingCouncils: licensingCouncils,
      //   lastActiveDate: "Never",
      //   carClasses: carClasses,
      //   phoneNumber: phoneNumber,
      //   carDescriptionShort: carDescriptionShort,
      // };

      dispatch(
        setCarClassList([
          ...carClassList.map((val: any) => {
            if (val.id === carClassObj.id) {
              return carClassObj;
            } else {
              return val;
            }
          }),
        ])
      );
      db.ref("/car_classes/" + selectedEditCarClass.id).set(carClassObj);
      toast.success("Car class successfully updated");
      dispatch(setSelectedEditCarClass(undefined));
    } catch (err) {
      toast.error("Something went wrong there...");
      console.log(err);
    }
  }

  async function createCarClass() {
    let imageUrl = "";

    try {
      let carClassObj: CarClassModel = {
        id: "",
        name,
        pricePerMile,
        pricePerMin,
        companyCommission,
        cancellationFee,
        imageUrl: imageUrl,
        basePrice: basePrice,
        numSeats: numSeats,
        public: true,
      };

      let ref = await db.ref("/car_classes/").push(carClassObj);

      let id = ref.key;

      if (imagesChanged) {
        // Upload image...]
        const response = await fetch(images[0].data_url);
        const selfieBlob = await response.blob();
        // Store the selfie in the firebase store
        imageUrl = await storage
          .ref("/car_class_images/" + id + ".jpg")
          .put(selfieBlob)
          .then((snapshot: any) => {
            return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
          });
        toast.success("Image uploaded...");
      }
      if (id) {
        dispatch(
          setCarClassList([
            ...carClassList.map((val: any) => {
              if (val.id === carClassObj.id) {
                return carClassObj;
              } else {
                return val;
              }
            }),
          ])
        );
        carClassObj = { ...carClassObj, id, imageUrl };

        db.ref("/car_classes/" + id).set(carClassObj);

        toast.success("Car class successfully created");
        dispatch(setCreateNewCarClass(undefined));
        dispatch(setCarClassList([...carClassList, carClassObj]));
        clearStates();
      }
    } catch (err) {
      toast.error("Something went wrong there...");
      console.log(err);
    }
  }

  async function deleteClass() {
    if (selectedEditCarClass) {
      setConfirmDeletion(false);

      let ref = db.ref("/car_class_drivers/" + selectedEditCarClass.id);

      db.ref("/car_classes/" + selectedEditCarClass.id + "/public").set(false);
      let data = await ref.once("value");

      if (data && data.val() && data.val() !== null) {
        let dataList = Object.keys(data.val());

        for (let i = 0; i < dataList.length; i++) {
          let driver = db.ref("/users/" + dataList[i]);
          let driverData: any = (await driver.once("value")).val();

          if (driverData && driverData.carClasses) {
            let list = driverData.carClasses.filter(
              (val: any) => val !== selectedEditCarClass.id
            );

            driverData = {
              ...driverData,
              carClasses: driverData.carClasses ? list : [],
            };
            await db.ref("/users/" + dataList[i]).set(driverData);
          }
        }

        await db.ref("/car_class_drivers/" + selectedEditCarClass.id).remove();
      }

      dispatch(
        setSelectedEditCarClass({ ...selectedEditCarClass, public: false })
      );
      dispatch(
        setCarClassList(
          carClassList.map((val: CarClassModel) => {
            if (val.id !== selectedEditCarClass.id) {
              return val;
            } else {
              return { ...val, public: false };
            }
          })
        )
      );
    } else {
      toast.error("Please re-open this car class to delete it.");
    }
  }
  async function renewClass() {
    if (selectedEditCarClass) {
      console.log("renewing...");
      dispatch(
        setSelectedEditCarClass({ ...selectedEditCarClass, public: true })
      );
      console.log("Set to public");

      console.log("Original", carClassList);
      console.log(
        "New:",
        carClassList.map((val: CarClassModel) => {
          if (val.id !== selectedEditCarClass.id) {
            return val;
          } else {
            return { ...val, public: true };
          }
        })
      );
      dispatch(
        setCarClassList(
          carClassList.map((val: CarClassModel) => {
            if (val.id !== selectedEditCarClass.id) {
              return val;
            } else {
              return { ...val, public: true };
            }
          })
        )
      );

      db.ref("/car_classes/" + selectedEditCarClass.id + "/public").set(true);
    } else {
      toast.error("Please re-open this car class to delete it.");
    }
  }

  return (
    <Fade in={selectedEditCarClass !== undefined || createNewCarClass === true}>
      <Grid
        sx={{
          ...styles.modalBackdrop,
        }}
        item
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        {carClassListLoading ? (
          <CircularProgress />
        ) : !confirmDeletion ? (
          <Grid
            item
            container
            xs={11}
            sm={10}
            alignItems={"flex-start"}
            spacing={isMobile ? 0 : 2}
            sx={{
              ...styles.modal,
            }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{ height: 40 }}
              justifyContent={"space-between"}
            >
              <Typography variant={"h6"}>
                {selectedEditCarClass !== undefined
                  ? "Edit Car Class"
                  : "Create Car Class"}
              </Typography>

              <IconButton
                onClick={() => {
                  dispatch(setSelectedEditCarClass(undefined));
                  dispatch(setCreateNewCarClass(undefined));
                  clearStates();
                }}
              >
                <Close />
              </IconButton>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item container xs={12}>
                <ReactImageUploading
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div
                      className="upload__image-wrapper"
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor: !isDragging
                          ? "rgba(0,0,0,0.04)"
                          : "rgba(95,102,234,0.5)",
                        padding: 10,
                        borderRadius: 5,
                        marginBottom: 5,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onImageUpload();
                      }}
                      {...dragProps}
                    >
                      {imageList.length === 0 && (
                        <Grid
                          container
                          flexDirection={"column"}
                          alignItems={"center"}
                        >
                          <img
                            src={"/static/avatar.svg"}
                            style={{
                              width: 140,
                              height: 140,

                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              borderRadius: 400,
                              background: "none",
                            }}
                            onClick={onImageUpload}
                          />
                          <Grid
                            container
                            item
                            sx={{ width: "60%", marginTop: 2 }}
                          >
                            <Typography variant="body1" textAlign={"center"}>
                              Click{" "}
                              <span
                                style={{
                                  color: primaryColor,
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  onImageUpload();
                                }}
                              >
                                here
                              </span>{" "}
                              to upload an image, or drag it over this area.
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                      {imageList.map((image, index) => {
                        let src = image["data_url"];
                        return (
                          <div key={index} className="image-item">
                            <div
                              style={{
                                width: 140,
                                height: 140,
                                borderRadius: 400,
                                overflow: "hidden",
                                backgroundImage: `url("${src}")`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            <IconButton
                              onClick={() => onImageRemove(index)}
                              style={{
                                backgroundColor: "red",
                                padding: 10,
                                borderRadius: 50,
                                width: 40,
                                height: 40,
                                position: "relative",
                                top: -130,
                                right: -110,
                              }}
                            >
                              <Close style={{ color: "white" }} />
                            </IconButton>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </ReactImageUploading>
                {imagesError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {imagesError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                NAME:
              </Typography>
              <TextField
                placeholder="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: nameError ? 5 : 10,
                }}
              />

              {nameError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {nameError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                Base Price
              </Typography>
              <TextField
                placeholder="Base Price"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>£</Typography>
                    </InputAdornment>
                  ),
                }}
                value={basePrice}
                onChange={(e) => {
                  if (e.target.value.includes(".")) {
                    setBasePrice(Number.parseFloat(e.target.value));
                  } else {
                    setBasePrice(Number.parseInt(e.target.value));
                  }
                }}
                ref={inputRef}
                type="number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: basePriceError ? 5 : 10,
                }}
              />

              {basePriceError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {basePriceError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                PRICE PER MINUTE
              </Typography>
              <TextField
                placeholder="Price Per Minute"
                value={pricePerMin}
                type="number"
                onChange={(e) => {
                  if (e.target.value.includes(".")) {
                    setPricePerMin(Number.parseFloat(e.target.value));
                  } else {
                    setPricePerMin(Number.parseInt(e.target.value));
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>£</Typography>
                    </InputAdornment>
                  ),
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: pricePerMinError ? 5 : 10,
                }}
              />

              {pricePerMinError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {pricePerMinError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                  marginTop: 2,
                }}
              >
                PRICE PER MILE:
              </Typography>
              <TextField
                placeholder="Price Per Mile"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>£</Typography>
                    </InputAdornment>
                  ),
                }}
                value={pricePerMile}
                onChange={(e) => {
                  if (e.target.value.includes(".")) {
                    setPricePerMile(Number.parseFloat(e.target.value));
                  } else {
                    setPricePerMile(Number.parseInt(e.target.value));
                  }
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: pricePerMileError ? 5 : 10,
                }}
              />

              {pricePerMileError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {pricePerMileError}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} sm={6} alignItems={"flex-start"}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                CANCELLATION FEE
              </Typography>
              <TextField
                placeholder="Cancellation Fee"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>£</Typography>
                    </InputAdornment>
                  ),
                }}
                value={cancellationFee}
                type="number"
                onChange={(e) => {
                  if (e.target.value.includes(".")) {
                    setCancellationFee(Number.parseFloat(e.target.value));
                  } else {
                    setCancellationFee(Number.parseInt(e.target.value));
                  }
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: cancellationFeeError ? 5 : 10,
                }}
              />

              {cancellationFeeError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {cancellationFeeError}
                  </Typography>
                </Grid>
              )}
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                COMPANY COMMISSION
              </Typography>
              <TextField
                placeholder="Company Commision"
                value={companyCommission}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>%</Typography>
                    </InputAdornment>
                  ),
                }}
                type="number"
                onChange={(e) => {
                  if (e.target.value.includes(".")) {
                    setCompanyCommission(Number.parseFloat(e.target.value));
                  } else {
                    setCompanyCommission(Number.parseInt(e.target.value));
                  }
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: companyCommissionError ? 5 : 10,
                }}
              />

              {companyCommissionError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {companyCommissionError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                NUMBER OF SEATS
              </Typography>
              <TextField
                placeholder="Number of Seats"
                value={numSeats}
                type="number"
                onChange={(e) => {
                  if (e.target.value.includes(".")) {
                    setNumSeats(Number.parseFloat(e.target.value));
                  } else {
                    setNumSeats(Number.parseInt(e.target.value));
                  }
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: numSeatsError ? 5 : 10,
                }}
              />

              {numSeatsError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {numSeatsError}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {selectedEditCarClass && (
              <Grid
                item
                container
                xs={12}
                justifyContent={"flex-end"}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                {selectedEditCarClass.public ? (
                  <Button
                    variant={"contained"}
                    startIcon={<VisibilityOff />}
                    onClick={() => {
                      deleteClass();
                    }}
                    style={{
                      backgroundColor: "red",
                      zIndex: 1,
                      marginRight: 5,
                    }}
                  >
                    Hide
                  </Button>
                ) : (
                  <Button
                    variant={"contained"}
                    startIcon={<Refresh />}
                    onClick={() => {
                      renewClass();
                    }}
                    style={{
                      backgroundColor: primaryColor,
                      zIndex: 1,
                      marginRight: 5,
                    }}
                  >
                    Restore
                  </Button>
                )}
                {selectedEditCarClass.public && (
                  <Button
                    variant={"contained"}
                    startIcon={<Save />}
                    onClick={() => {
                      validateCarClass();
                    }}
                    style={{ backgroundColor: primaryColor }}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={11}
            sm={5}
            alignItems={"flex-start"}
            spacing={2}
            justifyContent={"center"}
            sx={{
              ...styles.modal,
            }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{ height: 40 }}
              justifyContent={"space-between"}
            >
              <Typography variant={"h6"}>Are you sure?</Typography>

              <IconButton
                onClick={() => {
                  setConfirmDeletion(false);
                }}
              >
                <Close />
              </IconButton>
            </Grid>

            <Grid item container xs={12} justifyContent={"center"}>
              <img src={selectedEditCarClass.imageUrl} width={250} />
            </Grid>
            <Grid item container xs={12} justifyContent={"center"}>
              <Typography variant="body1">
                If you delete this class, it will no longer be accessible
                through the GEO GO application.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent={"flex-end"}
              spacing={2}
              sx={{ marginTop: 3 }}
            >
              <Button
                variant={"contained"}
                startIcon={<Cancel />}
                style={{ backgroundColor: "grey", marginRight: 5 }}
                onClick={() => {
                  setConfirmDeletion(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fade>
  );
}
