import { useEffect, useState } from "react";
import { useTools } from "../../services/general_services";
import { setPage } from "../../store/slices/general.slice";
import { PageType } from "../../models/PageType";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DarkTextField from "../components/general/TextFieldDarker";
import { Add } from "@mui/icons-material";
import { Location } from "../../models/LocationModel";
import {
  getDriverDocumentList,
  getDriverList,
} from "../../store/thunks/driver.thunks";
import { useSelector } from "react-redux";
import { DriverAndDocModel } from "../../models/DriverModel";
import { setCreateNewDriver } from "../../store/slices/driver.slice";
import { DriverDocsGrid } from "../components/general/DriverDocsGrid";

export function DocumentsScreen() {
  const { dispatch, navigate } = useTools();
  const { driverDocList, driverDocListLoading } = useSelector(
    (state: any) => state.DriverSlice
  );

  const [searchValue, setSearchValue] = useState("");
  const [filterDriverLocation, setFilterDriverLocation] = useState<
    Location | string
  >(Location.NONE);

  const [driverListRetrieved, setDriverListRetrieved] = useState(false);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    dispatch(setPage(PageType.DOCUMENTS));
    if (!driverListRetrieved) {
      dispatch(getDriverDocumentList());
    }
  }, []);

  // Update local list with data from driver list
  useEffect(() => {
    if (driverDocList) {
      setFilteredList(driverDocList);
    }
  }, [driverDocList]);

  // Search through items
  useEffect(() => {
    if (searchValue === "") {
      setFilteredList(driverDocList);
    } else {
      let searchVal = searchValue;
      if (searchVal[0] === "0") {
        searchVal = searchValue.slice(1);
      }
      let list = driverDocList.filter(
        (val: DriverAndDocModel) =>
          val.driver.firstName
            .toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          val.driver.lastName.toLowerCase().includes(searchVal.toLowerCase()) ||
          val.driver.emailAddress
            .toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          val.driver.registrationPlate
            .toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          val.driver.licensingCouncils.find((val2: Location) => {
            if (val2 && val2.toLowerCase().includes(searchVal.toLowerCase())) {
              return val2;
            }
          }) !== undefined ||
          val.driver.phoneNumber.toString().includes(searchVal.toLowerCase()) ||
          val.driver.emailAddress.includes(searchVal.toLowerCase())
      );
      setFilteredList(list);
    }
  }, [searchValue]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{ padding: 2 }}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      {/* Top section - Search & Filter */}
      <Grid item container xs={12} alignItems={"center"}>
        <Grid item container xs={9} alignItems={"center"}>
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            style={{ marginRight: 30 }}
          >
            Documents
          </Typography>

          <DarkTextField
            placeholder="Search Documents..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            style={{
              width: "200px",
              borderRadius: 30,
              marginRight: 30,
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          />

          {/* <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              dispatch(setCreateNewDriver(true));
            }}
            sx={{ borderRadius: 20 }}
          >
            Add Driver
          </Button> */}
        </Grid>
      </Grid>
      {/* Bottom section - Driver Grid */}
      <Grid
        item
        container
        xs={12}
        sx={{ marginTop: 5 }}
        justifyContent={"center"}
      >
        {driverDocListLoading ? (
          <CircularProgress />
        ) : (
          <DriverDocsGrid
            rows={filteredList.map((val: DriverAndDocModel, index) => {
              return { ...val, id: index };
            })}
          />
        )}
      </Grid>
    </Grid>
  );
}
