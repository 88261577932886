export enum Location {
  NONE = "NONE",
  CARDIFF = "CARDIFF",
  NEWPORT = "NEWPORT",
}

export interface LocationModel {
  lat: number;
  lng: number;
  address?: string;
  arrivalTime?: string;
  departureTime?: string;
  distanceDriven?: number;
}
