import {
  StripeConnectInstance,
  loadConnectAndInitialize,
} from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
} from "@stripe/react-connect-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function DriverConnect() {
  const [stripeConnectInstance, setStripeConnectInstance] =
    useState<StripeConnectInstance | null>(null);
  const { userData } = useSelector((state: any) => state.UserSlice);
  async function fetchClientSecret() {
    // Fetch the AccountSession client secret
    const response = await fetch(
      "https://us-central1-geo-go-app.cloudfunctions.net/createAccountSession",
      {
        method: "POST",
        body: JSON.stringify({
          stripeAccountId: userData.stripeAccountId,
        }),
      }
    );
    if (!response.ok) {
      // Handle errors on the client side here
      const { error } = await response.json();
      console.error("An error occurred: ", error);

      return undefined;
    } else {
      const { client_secret: clientSecret } = await response.json();
      return clientSecret;
    }
  }
  useEffect(() => {
    if (userData && userData.stripeAccountId) {
      let instance = loadConnectAndInitialize({
        // This is your test publishable API key.
        publishableKey:
          "pk_live_51NnjfPH8RareRJbavdksoG8ituPVyiqBsvFS310ktethrddngomb8hO2z4bZi2HkmL2ytVuQvvQ254MubT2QvpWD00Hgt3SBY3",
        fetchClientSecret: fetchClientSecret,
      });

      setStripeConnectInstance(instance);
    }
  }, []);
  return (
    <>
      {stripeConnectInstance === null ? (
        <></>
      ) : (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectAccountOnboarding
            onExit={() => {
              console.log("The account has exited onboarding");
            }}
            // Optional: make sure to follow our policy instructions above
            // fullTermsOfServiceUrl="{{URL}}"
            // recipientTermsOfServiceUrl="{{URL}}"
            // privacyPolicyUrl="{{URL}}"
            // skipTermsOfServiceCollection={false}
          />
        </ConnectComponentsProvider>
      )}
    </>
  );
}
