import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase_services";
import { DriverModel } from "../../models/DriverModel";
import { CarClassModel } from "../../models/CarClassModel";

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getCarClassList = createAsyncThunk(
  "GetCarClass",
  async (): Promise<CarClassModel[] | undefined> => {
    try {
      let carClasss = db.ref("car_classes/");

      let carClassFound = await carClasss.once("value");

      if (carClassFound.val() !== null) {
        const list: CarClassModel[] = Object.values(carClassFound.val());
        return list;
      } else {
        return [];
      }
    } catch (err: any) {
      console.log(err);
    }
  }
);
