import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PayoutAppModel } from "../../models/PayoutModel";
import { findPayouts } from "../thunks/booking.thunks";

/**
 * modalHeight - Height of the information modal being shown during a journey - Used to display properly.
 * reportUser - Data of a user from a group selected to be reported.
 * page - The current page a user is on - PageType.HOME - PageType.JOURNEYS etc.
 */
interface IProps {
  payouts: PayoutAppModel[];
  payoutsLoading: boolean;
  payoutsError: boolean;
}

const payoutInitialState: IProps = {
  payouts: [],
  payoutsError: false,
  payoutsLoading: false,
};

const payoutSlice: any = createSlice({
  name: "payout_data",
  initialState: payoutInitialState,
  reducers: {
    // Setters

    setPayouts: (
      state: IProps,
      { payload }: PayloadAction<PayoutAppModel[]>
    ) => {
      state.payouts = payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      findPayouts.fulfilled,
      (state: IProps, { payload }: PayloadAction<PayoutAppModel[]>) => {
        state.payouts = payload;
        state.payoutsError = false;
        state.payoutsLoading = false;
      }
    );
    builder.addCase(findPayouts.pending, (state: IProps) => {
      state.payoutsError = false;
      state.payoutsLoading = true;
    });
    builder.addCase(findPayouts.rejected, (state: IProps) => {
      state.payoutsError = true;
      state.payoutsLoading = false;
    });
  },
});

export const { setPayouts } = payoutSlice.actions;

export default payoutSlice.reducer;
