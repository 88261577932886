import { Grid, Typography, TextField, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTools } from "../../services/general_services";
import { loginSubmit, watchAuthState } from "../../services/auth_services";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styles } from "../../assets/style/styles";
import { PageType } from "../../models/PageType";
import { auth } from "../../services/firebase_services";
import { setPage } from "../../store/slices/general.slice";
import { getAdminStatus } from "../../store/thunks/general.thunks";
import { useSelector } from "react-redux";
import { getUserData } from "../../store/thunks/user.thunks";
import { getCarClassList } from "../../store/thunks/carClass.thunks";

interface IProps {
  type: PageType;
}

export function LoginScreen({ type = PageType.LOGIN }: IProps) {
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const { page } = useSelector((state: any) => state.GeneralSlice);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [hidePassword, setHidePassword] = useState(true);

  const { navigate, dispatch } = useTools();

  const passwordInputRef = useRef<any>();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    try {
      if (type !== "INITIAL") {
        console.log("starting?");
        auth.onAuthStateChanged(async function (result: any) {
          if (result && result !== null && !initialized && page === "INITIAL") {
            setInitialized(true);
            if (type === PageType.LOGIN) {
              navigate("/admin/dashboard");
              dispatch(setPage(PageType.DASHBOARD));
            }

            if (type !== PageType.DRIVERS) {
              dispatch(getAdminStatus({ id: auth.currentUser?.uid }));
              dispatch(getUserData({}));
              dispatch(getCarClassList());
            }

            return;
          }
        });
      }
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/wrong-password") {
        setEmailAddressError("");
        setPasswordError("Wrong password for this account.");
      } else if (errorCode === "auth/missing-password") {
        setEmailAddressError("");
        setPasswordError("Wrong password for this account.");
      } else {
        setPasswordError("");
        setEmailAddressError(errorMessage.split(":")[1]);
      }
    }
  }, [page]);
  return (
    <Grid container>
      <Grid
        container
        item
        xs={6}
        sx={{
          display: { xs: "none", sm: "flex" },
          backgroundImage: `url(${"/static/login_bground.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      />

      <Grid
        container
        item
        sm={6}
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100vh", backgroundColor: "#fff", paddingTop: 2 }}
      >
        <Grid
          container
          item
          xs={9}
          sm={9}
          md={9}
          justifyContent={"space-evenly"}
          alignItems={"flex-start"}
          sx={{ height: "100%" }}
        >
          <Grid item container xs={12} justifyContent={"center"}>
            <img
              src={"/static/logo.png"}
              style={{ width: "80%", maxWidth: "200px" }}
            />
          </Grid>

          <Typography variant="h2">Welcome back.</Typography>
          <div
            style={{
              borderBottom: "0.5px solid grey",
              width: "100%",
            }}
          />
          <TextField
            placeholder="Email Address"
            label="Email Address"
            value={emailAddress}
            onChange={(e) => {
              setEmailAddress(e.target.value);
            }}
            style={{
              width: "100%",
              borderRadius: 10,
              marginBottom: emailAddressError ? 5 : 10,
            }}
          />

          {emailAddressError !== "" && (
            <Grid
              style={{
                width: "100%",
                marginBottom: 10,
                marginTop: 5,
                justifyContent: "center",
              }}
            >
              <Typography style={{ textAlign: "left", color: "red" }}>
                {emailAddressError}
              </Typography>
            </Grid>
          )}
          <TextField
            inputRef={passwordInputRef}
            placeholder="Password"
            label="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                passwordInputRef.current?.blur();
                loginSubmit(
                  emailAddress,
                  password,
                  setEmailAddressError,
                  setPasswordError,
                  navigate,
                  dispatch,
                  type
                );
              }
            }}
            type="password"
            style={{
              width: "100%",
              borderRadius: 10,
              marginBottom: passwordError ? 5 : 10,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 18,
                right: 20,
                backgroundColor: "green",
              }}
              onClick={() => {
                setHidePassword(!hidePassword);
              }}
            >
              {hidePassword ? <Visibility /> : <VisibilityOff />}
            </div>
          </TextField>
          {passwordError !== "" && (
            <Grid
              style={{
                width: "100%",
                marginTop: 5,
                marginBottom: 10,
                justifyContent: "center",
              }}
            >
              <Typography style={{ textAlign: "left", color: "red" }}>
                {passwordError}
              </Typography>
            </Grid>
          )}
          <Button
            style={styles.largeRoundedBtn}
            variant="contained"
            onClick={() => {
              loginSubmit(
                emailAddress,
                password,
                setEmailAddressError,
                setPasswordError,
                navigate,
                dispatch,
                type
              );
            }}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
