import { MenuItem, Select, useThemeProps } from "@mui/material";
import { TimeFrame } from "../../../models/TimeFrameModel";
import { setTimeframe } from "../../../store/slices/general.slice";
import { useTools } from "../../../services/general_services";
import { useSelector } from "react-redux";

export function TimeFrameSelect() {
  const { navigate, dispatch } = useTools();

  const { timeframe } = useSelector((state: any) => state.GeneralSlice);

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={timeframe}
      style={{
        flex: 1,
        backgroundColor: "rgba(224,224,224,0.19)",
        border: "none",
        fontFamily: "Montserrat",
        fontSize: 12,
        display: "block",
      }}
      onChange={(e) => {
        let value = e.target.value as keyof typeof TimeFrame;
        dispatch(setTimeframe(TimeFrame[value]));
      }}
    >
      <MenuItem
        style={{ fontFamily: "Montserrat", fontSize: 15 }}
        value={TimeFrame.DAILY}
      >
        Daily
      </MenuItem>{" "}
      <MenuItem
        style={{ fontFamily: "Montserrat", fontSize: 15 }}
        value={TimeFrame.WEEKLY}
      >
        Weekly
      </MenuItem>
      <MenuItem
        style={{ fontFamily: "Montserrat", fontSize: 15 }}
        value={TimeFrame.MONTHLY}
      >
        Monthly
      </MenuItem>
      <MenuItem
        style={{ fontFamily: "Montserrat", fontSize: 15 }}
        value={TimeFrame.YEARLY}
      >
        Yearly
      </MenuItem>
      <MenuItem
        style={{ fontFamily: "Montserrat", fontSize: 15 }}
        value={TimeFrame.ALL}
      >
        All
      </MenuItem>
    </Select>
  );
}
