import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase_services";
import {
  DriverAndDocModel,
  DriverExpirationModel,
  DriverModel,
} from "../../models/DriverModel";
import e from "express";

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getDriverList = createAsyncThunk(
  "GetDriverList",
  async (): Promise<DriverModel[] | undefined> => {
    try {
      let drivers = db.ref("drivers/");
      let driverList: DriverModel[] = [];

      let driversFound = await drivers.once("value");

      if (
        driversFound !== null &&
        driversFound.val() &&
        driversFound.val() !== null
      ) {
        const list = Object.values(driversFound.val());

        if (list) {
          for (let i = 0; i < list.length; i++) {
            console.log("getting user...");
            let user = db.ref("/users/" + list[i]);

            let user_data = await user.once("value");
            if (user_data.val() !== null) {
              let driver: DriverModel = user_data.val();

              console.log("Driver is: ", driver);

              let expirations = await getExpForDriver(driver);

              driver = { ...driver, expirations: expirations };

              driverList = [...driverList, driver];
            } else {
              console.log("null");
            }
          }
        }

        return driverList;
      }

      return driverList;
    } catch (err: any) {
      console.log(err);
    }
  }
);
export const getDriverDocumentList = createAsyncThunk(
  "GetDriverDocumentList",
  async (): Promise<DriverAndDocModel[] | undefined> => {
    try {
      let drivers = db.ref("drivers/");
      let driverDocList: DriverAndDocModel[] = [];
      let driverList: DriverModel[] = [];

      let driversFound = await drivers.once("value");

      if (
        driversFound !== null &&
        driversFound.val() &&
        driversFound.val() !== null
      ) {
        console.log(driversFound.val());
        let list: DriverModel[] = Object.values(driversFound.val());

        if (list) {
          // Get EXPIRATIONS!
          for (let i = 0; i < list.length; i++) {
            let user = db.ref("/users/" + list[i]);

            let user_data = await user.once("value");
            if (user_data !== null) {
              let driver: DriverModel = user_data.val();

              let expirations = await getExpForDriver(driver);

              driver = { ...driver, expirations: expirations };

              driverList = [...driverList, driver];
            }
          }

          console.log("List before: ", driverList.length);

          driverList = driverList.filter(
            (val: DriverModel) => val.documents !== undefined
          );
          console.log("List of documents: ", driverList.length);
          for (let i = 0; i < driverList.length; i++) {
            let user = db.ref("/users/" + driverList[i].id);

            let user_data = await user.once("value");
            if (user_data.val() !== null && driverList[i].documents) {
              let driver: DriverModel = user_data.val();

              let expirations = await getExpForDriver(driver);

              driver = { ...driver, expirations: expirations };

              if (driver.documents) {
                for (let j = 0; j < driverList[i].documents!.length; j++) {
                  driverDocList = [
                    ...driverDocList,
                    { driver: driver, document: driverList[i].documents![j] },
                  ];
                }
              }
            } else {
              console.log("User is not existing anymore?");
            }
          }
        }

        return driverDocList;
      }

      return driverDocList;
    } catch (err: any) {
      console.log(err);
    }
  }
);

export const getDriverById = createAsyncThunk(
  "GetDriver",
  async ({ id }: { id: string }): Promise<DriverModel | undefined> => {
    try {
      let driverDb = db.ref("users/" + id);

      let driverFound = await driverDb.once("value");

      if (driverFound !== null) {
        let driverExp = await getExpForDriver(driverFound.val());

        console.log("EXP: ", driverExp);

        return { ...driverFound.val(), expirations: driverExp };
      }
    } catch (err: any) {
      console.log(err);
    }
  }
);

export async function getExpForDriver(driver: DriverModel) {
  if (driver === null) {
    return [];
  }
  let driverExp = (
    await db.ref("driver_expirations/" + driver.id).once("value")
  ).val();

  let expirations: DriverExpirationModel[] = [];

  if (driverExp !== null) {
    expirations = Object.values(driverExp);
  }

  return expirations;
}
