import { Grid } from "@mui/material";
import { ternaryColor } from "../../../assets/style/theme";

export function BorderLine() {
  return (
    <Grid item container xs={12} justifyContent={"center"}>
      <Grid
        item
        container
        xs={10}
        style={{
          borderBottom: "0.5px solid " + ternaryColor,
          marginTop: 30,
          marginBottom: 20,
        }}
      />
    </Grid>
  );
}
