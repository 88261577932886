import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import store from "../store/store";
import { AdminContainer } from "./components/AdminContainer";
import { LoginScreen } from "./pages/LoginScreen";
import { DashboardScreen } from "./pages/DashboardScreen";
import { SettingsScreen } from "./pages/SettingsScreen";
import { DocumentsScreen } from "./pages/DocumentsScreen";
import { DriversScreen } from "./pages/DriversScreen";
import { ClientsScreen } from "./pages/ClientsScreen";
import { RidesScreen } from "./pages/RidesScreen";
import { ViewDriverScreen } from "./pages/ViewDriverScreen";
import { Button, Grid, Typography } from "@mui/material";
import { ViewClientScreen } from "./pages/ViewClientScreen";
import { ViewRideScreen } from "./pages/ViewRideScreen";
import { DriverContainer } from "./components/DriverContainer";
import { DriverConnect } from "./pages/DriverConnect";
import { PageType } from "../models/PageType";
import { useEffect } from "react";
import { ReAuth } from "./pages/ReAuthScreen";
import { PayoutScreen } from "./pages/PayoutScreen";
import HomeScreen from "./pages/HomeScreen";
import PrivacyEulaScreen from "./pages/PrivacyPolicyScreen";

export default function AppRoutes({ mapboxgl }: any) {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HomeScreen type={PageType.HOME} />
              </>
            }
          />

          <Route
            path="/admin"
            element={
              <>
                <LoginScreen type={PageType.LOGIN} />
              </>
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              <AdminContainer>
                <DashboardScreen mapboxgl={mapboxgl} />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/rides"
            element={
              <AdminContainer>
                <RidesScreen />
              </AdminContainer>
            }
          />

          <Route
            path="/admin/payouts"
            element={
              <AdminContainer>
                <PayoutScreen />
              </AdminContainer>
            }
          />

          <Route
            path="/admin/rides/:id"
            element={
              <AdminContainer>
                <ViewRideScreen mapboxgl={mapboxgl} />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/clients"
            element={
              <AdminContainer>
                <ClientsScreen />
              </AdminContainer>
            }
          />

          <Route
            path="/admin/clients/:id"
            element={
              <AdminContainer>
                <ViewClientScreen />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/drivers"
            element={
              <AdminContainer>
                <DriversScreen />
              </AdminContainer>
            }
          />

          <Route
            path="/admin/drivers/:id"
            element={
              <AdminContainer>
                <ViewDriverScreen />
              </AdminContainer>
            }
          />
          <Route
            path="/driver/connect-setup"
            element={
              <DriverContainer>
                <DriverConnect />
              </DriverContainer>
            }
          />

          <Route
            path="/driver/re-auth"
            element={
              <DriverContainer>
                <ReAuth />
              </DriverContainer>
            }
          />
          <Route
            path="/admin/documents"
            element={
              <AdminContainer>
                <DocumentsScreen />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <AdminContainer>
                <SettingsScreen />
              </AdminContainer>
            }
          />
          <Route
            path="/apptermsandconditions"
            element={<>{/* <AppTermsAndConditions /> */}</>}
          />

          <Route
            path="/login"
            element={
              <>
                <LoginScreen type={PageType.LOGIN} />
              </>
            }
          />
          <Route
            path="/privacyeula"
            element={
              <>
                <PrivacyEulaScreen />
              </>
            }
          />
          <Route
            path="*"
            element={
              <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100vh"}
              >
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  rowSpacing={2}
                >
                  <Grid item>
                    <img src={"/static/pageNotFound.svg"} width={400} />
                  </Grid>
                  <Grid item>
                    <Typography variant={"h4"}>You goofed up.</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={"body1"}>
                      This page LITERALLY doesn't exist - This is not a big foot
                      situation, your search can stop.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        window.location.href = "/login";
                      }}
                    >
                      Take me home!
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
}
