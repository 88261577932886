import { Button, Grid, IconButton, Typography } from "@mui/material";
import { createRef, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { BorderLine } from "./BorderLine";
import { Add, Delete } from "@mui/icons-material";
import { primaryColor } from "../../../assets/style/theme";

interface IProps {
  files: any[];
  setFiles: Function;
  dropzoneRef: any;
}
export function PaperDropzone({ files, setFiles, dropzoneRef }: IProps) {
  return (
    <Dropzone
      ref={dropzoneRef}
      onDropAccepted={(newFiles) => {
        setFiles([...files, ...newFiles]);
      }}
    >
      {({ getRootProps, getInputProps, isFocused }) => (
        <Grid item container xs={12}>
          {files && files.length !== 0 ? (
            <Grid
              item
              container
              onClick={() => {}}
              sx={{
                backgroundColor: primaryColor,
                padding: 1,
                paddingLeft: 2,
                paddingRight: 2,
                width: 155,
                borderRadius: 3,
                marginTop: 4,
                cursor: "pointer",
              }}
              {...getRootProps({ refKey: "innerRef" })}
            >
              <Add style={{ color: "white", marginRight: 4 }} />
              <Typography style={{ color: "white" }}>Add Another</Typography>
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              alignItems={"flex-start"}
              alignContent={"flex-start"}
              sx={{
                backgroundColor: !isFocused
                  ? "rgba(0,0,0,0.04)"
                  : "rgba(95,102,234,0.5)",
                opacity: files.length > 0 ? 0 : 1,
                borderRadius: 1,
                padding: 2,
                height: 190,
                cursor: "pointer",
                overflow: "scroll",
              }}
              {...getRootProps({ refKey: "innerRef" })}
            >
              <input {...getInputProps()} />
              <Grid item container xs={12} justifyContent={"center"}>
                <img src="/static/upload.svg" width="100" />
              </Grid>
              <Grid item container xs={12} justifyContent={"center"}>
                <p>Drag 'n' drop some files here, or click to select files</p>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Dropzone>
  );
}
