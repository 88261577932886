import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import { useTools } from "../../../services/general_services";
import { Grid } from "@mui/material";
import { Instagram } from "@mui/icons-material";

const logoStyle = {
  width: "140px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      Geo GO Limited&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const { theme } = useTools();
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "30%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "30%" } }}>
            <Box sx={{ ml: "-15px" }}>
              <img
                src={
                  theme.palette.mode === "light"
                    ? "/static/logo.png"
                    : "/static/logo-white.png"
                }
                style={logoStyle}
                alt="logo of sitemark"
              />
            </Box>
          </Box>
        </Box>
        <Grid container xs={12} spacing={2}>
          <Grid
            container
            item
            sx={{
              flexDirection: "column",
            }}
            sm={4}
            xs={12}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{ marginBottom: "20px" }}
            >
              Follow us:
            </Typography>
            <Grid item container>
              <Grid item xs={6}>
                <Link
                  color="text.secondary"
                  href="https://www.facebook.com/people/Geo-Go/61559304727193/"
                >
                  <FacebookIcon />
                </Link>
              </Grid>

              <Grid item xs={6}>
                <Link
                  color="text.secondary"
                  href="https://www.instagram.com/goexecutiveout/"
                >
                  <Instagram />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={4}
            xs={6}
            sx={{
              flexDirection: "column",
              gap: 1,
            }}
            alignContent={"center"}
          >
            <Typography variant="body2" fontWeight={600}>
              Product
            </Typography>
            <Link color="text.secondary" href="#">
              Features
            </Link>
            {/* <Link color="text.secondary" href="#">
            Testimonials
          </Link> */}
            <Link color="text.secondary" href="#">
              Highlights
            </Link>
            {/* <Link color="text.secondary" href="#">
              Pricing
            </Link> */}
            <Link color="text.secondary" href="#">
              FAQs
            </Link>
          </Grid>
          <Grid
            container
            sx={{
              flexDirection: "column",
              gap: 1,
            }}
            alignContent={"center"}
            item
            xs={6}
            sm={4}
          >
            <Grid item>
              <Typography variant="body2" fontWeight={600}>
                Office Address:
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography
                variant="body2"
                fontWeight={600}
                color="text.secondary"
              >
                1 Capital Quarter
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={600}
              >
                Tyndall Street
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                fontWeight={600}
                color="text.secondary"
              >
                Cardiff
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                fontWeight={600}
                color="text.secondary"
              >
                CF10 4BZ
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/*       
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <Link color="text.secondary" href="#">
            Terms
          </Link>
          <Link color="text.secondary" href="#">
            Privacy
          </Link>
          <Link color="text.secondary" href="#">
            Contact
          </Link>
        </Box> */}
      </Box>
      <Grid
        xs={12}
        alignItems={"center"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 12, sm: 12 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
          flexDirection: { xs: "column", sm: "row" },
          gap: 5,
        }}
      >
        <Link color="text.secondary" href="privacyeula">
          Privacy Policy / EULA
        </Link>

        <Typography
          display="inline"
          sx={{ mx: 0.5, opacity: 0.5, display: { xs: "none", sm: "block" } }}
        >
          &nbsp;•&nbsp;
        </Typography>
        {/* <Link color="text.secondary" href="#">
            Terms of Service
          </Link> */}
        <Copyright />
        <Typography display={"inline"}>Company No. 14932761</Typography>
        <Link color="text.secondary" href="mailto:support@geo-go-exec.com">
          support@geo-go-exec.com
        </Link>
        {/* <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/mui"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack> */}
      </Grid>
    </Container>
  );
}
