import { createTheme } from "@mui/material";

export const primaryColor: string = "#5459EA";
const primaryLightColor: string = process.env.REACT_APP_PRIMARY_LIGHT
  ? process.env.REACT_APP_PRIMARY_LIGHT
  : "#0A307C";
export const secondaryColor: string = process.env.REACT_APP_SECONDARY_COLOR
  ? process.env.REACT_APP_SECONDARY_COLOR
  : "#fcfcfc";
export const ternaryColor: string = process.env.REACT_APP_TERNARY_COLOR
  ? process.env.REACT_APP_TERNARY_COLOR
  : "#494949";
const primaryText: string = process.env.REACT_APP_TEXT_PRIMARY
  ? process.env.REACT_APP_TEXT_PRIMARY
  : "#fcfcfc";
const secondaryText: string = process.env.REACT_APP_TEXT_SECONDARY
  ? process.env.REACT_APP_TEXT_SECONDARY
  : "#0A307C";

const AppTheme: any = createTheme({
  typography: {
    body1: {
      fontFamily: "Roboto",
    },
    body2: {
      fontFamily: "Roboto",
    },
    h1: {
      fontFamily: "Montserrat",
    },
    h2: {
      fontFamily: "Montserrat",
    },
    h3: {
      fontFamily: "Montserrat",
    },
    h4: {
      fontFamily: "Montserrat",
    },
    h5: {
      fontFamily: "Montserrat",
    },
    h6: {
      fontFamily: "Montserrat",
    },
  },
  palette: {
    text: {
      primary: ternaryColor,
      secondary: secondaryText,
    },
    primary: {
      main: primaryColor,
      light: secondaryColor,
      dark: ternaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      default: secondaryColor,
      paper: secondaryColor,
    },
  },
});

export default AppTheme;
