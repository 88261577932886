import { CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function ReAuth() {
  // Send off an account link for the current account
  // If the current account has a stripe ID and is a driver
  // Else show an error and log the user doing this

  const { userData } = useSelector((state: any) => state.UserSlice);
  const [authInitialised, setAuthInitialised] = useState(false);

  useEffect(() => {
    if (!authInitialised && userData) {
      // Re-auth this user baby!
    }
  }, [userData]);

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Typography>We're authenticating you now...</Typography>
      <CircularProgress />
    </Grid>
  );
}
