import { Grid, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { styles } from "../../assets/style/styles";
import { ternaryColor } from "../../assets/style/theme";
import { PageType } from "../../models/PageType";
import { NavigationButton } from "./NavigationButton";
import { useTools } from "../../services/general_services";
import { BorderLine } from "./general/BorderLine";
import { Close } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

export function NavBar({ setShowNav }: { setShowNav: Function }) {
  const { page } = useSelector((state: any) => state.GeneralSlice);

  const { userData } = useSelector((state: any) => state.UserSlice);

  const { navigate, dispatch } = useTools();

  return (
    <Grid
      container
      item
      xs={12}
      style={styles.navbar}
      alignContent={"flex-start"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid
        item
        container
        xs={10}
        sx={{ display: { xs: "flex", sm: "none" } }}
        justifyContent={"flex-end"}
      >
        <IconButton
          onClick={() => {
            setShowNav(false);
          }}
        >
          <Close style={{ color: "white" }} />
        </IconButton>
      </Grid>
      <Grid item container>
        {/* Image of user */}
        <Grid
          item
          container
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            paddingBottom: 10,
            ...styles.sidePadding,
          }}
          spacing={2}
        >
          {/* Circle Profile Pic */}
          <Grid container item xs={4} justifyContent={"flex-end"}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 55,
                  height: 55,
                  border: "2px solid white",
                  borderRadius: 32,
                }}
              >
                <div
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 25,
                    backgroundImage: `url(${userData.profilePictureUrl}`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </div>
            </div>
          </Grid>

          {/* Profile Information */}
          <Grid item xs={8} flexDirection={"column"}>
            <Typography
              variant={"h6"}
              style={{ color: "white", lineHeight: 1.2 }}
            >
              {userData.firstName}
            </Typography>
            <Typography
              variant={"body1"}
              style={{
                color: "rgba(255,255,255, 0.7)",
                fontFamily: "Roboto",
                fontWeight: 200,
                fontSize: 15,
              }}
            >
              +44 {userData.phoneNumber}
            </Typography>
          </Grid>
        </Grid>

        <BorderLine />
        <Grid item container xs={12} justifyContent={"center"}>
          <Grid
            item
            container
            xs={10}
            sx={{
              paddingBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={"bold"}
              style={{ fontSize: 12, color: "rgba(255,255,255,0.7)" }}
            >
              MAIN MENU
            </Typography>
          </Grid>
        </Grid>

        {/* Navigation buttons */}
        <Grid item container xs={12}>
          <NavigationButton
            page={page}
            buttonPage={PageType.DASHBOARD}
            onClick={() => {
              navigate("/admin/dashboard");
              if (window.screen.width < 500) {
                setShowNav(false);
              }
            }}
            imgSrc={"/static/home_white.png"}
            hoveredImgSrc={"/static/home_color.png"}
            text={"Dashboard"}
          />

          <NavigationButton
            page={page}
            buttonPage={PageType.RIDES}
            onClick={() => {
              navigate("/admin/rides");
              if (window.screen.width < 500) {
                setShowNav(false);
              }
            }}
            imgSrc={"/static/rides_white.png"}
            hoveredImgSrc={"/static/rides_color.png"}
            text={"Rides"}
          />

          <NavigationButton
            page={page}
            buttonPage={PageType.PAYOUTS}
            onClick={async () => {
              navigate("/admin/payouts");
              if (window.screen.width < 500) {
                setShowNav(false);
              }
            }}
            imgSrc={"/static/cash_white.png"}
            hoveredImgSrc={"/static/cash_color.png"}
            text={"Payouts"}
          />

          <NavigationButton
            page={page}
            buttonPage={PageType.CLIENTS}
            onClick={() => {
              navigate("/admin/clients");
              if (window.screen.width < 500) {
                setShowNav(false);
              }
            }}
            imgSrc={"/static/clients_white.png"}
            hoveredImgSrc={"/static/clients_color.png"}
            text={"Clients"}
          />

          <NavigationButton
            page={page}
            buttonPage={PageType.DRIVERS}
            onClick={() => {
              navigate("/admin/drivers");
              if (window.screen.width < 500) {
                setShowNav(false);
              }
            }}
            imgSrc={"/static/drivers_white.png"}
            hoveredImgSrc={"/static/drivers_color.png"}
            text={"Drivers"}
          />

          <NavigationButton
            page={page}
            buttonPage={PageType.DOCUMENTS}
            onClick={() => {
              navigate("/admin/documents");
              if (window.screen.width < 500) {
                setShowNav(false);
              }
            }}
            imgSrc={"/static/documents_white.png"}
            hoveredImgSrc={"/static/documents_color.png"}
            text={"Documents"}
          />

          <NavigationButton
            page={page}
            buttonPage={PageType.SETTINGS}
            onClick={() => {
              navigate("/admin/settings");
              if (window.screen.width < 500) {
                setShowNav(false);
              }
            }}
            imgSrc={"/static/settings_white.png"}
            hoveredImgSrc={"/static/settings_color.png"}
            text={"Settings"}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} justifyContent={"center"}>
        <BorderLine />
        <Grid item xs={10}>
          <Typography
            variant="h6"
            fontSize={14}
            style={{ color: "rgba(255,255,255,0.7)" }}
          >
            @2024 Geo Go | Blundell Labs
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
