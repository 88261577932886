import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment, { isDate } from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DriverModel } from "../../../models/DriverModel";
import { Check, Close, Edit, Launch } from "@mui/icons-material";
import {
  setSelectedEditDriver,
  setSelectedViewDriver,
} from "../../../store/slices/driver.slice";
import {
  getCurrentDateTime,
  useTools,
} from "../../../services/general_services";

export function DriverGrid({ rows }: { rows: DriverModel[] }) {
  const { driverList } = useSelector((state: any) => state.DriverSlice);

  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "firstName",
      headerName: "Driver",
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid
            item
            container
            xs={12}
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
            style={{
              height: "52px",
            }}
            flexDirection={"row"}
          >
            {/* Circle Profile Pic */}
            <Grid
              item
              container
              xs={4}
              sx={{ height: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Avatar
                style={{
                  width: "28px",
                  height: "28px",
                  marginRight: 10,
                }}
                alt={params.row.firstName}
                src={params.row.profilePictureUrl}
              />
            </Grid>

            {/* Profile Information */}
            <Grid item xs={8} flexDirection={"column"}>
              <Typography
                variant={"h6"}
                style={{ lineHeight: 1.2, fontSize: 15 }}
              >
                {params.row.firstName}
              </Typography>
              <Typography
                variant={"body1"}
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontFamily: "Roboto",
                  fontWeight: 200,
                  fontSize: 12,
                }}
              >
                +44 {params.row.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "carDescriptionShort",
      headerName: "Car Description",
      flex: 1,
    },
    {
      field: "registrationPlate",
      headerName: "Registration Plate",
      flex: 1,
      cellClassName: "regPlate",
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor: "#FFD600",
              borderRadius: 5,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            <Typography variant="h6" fontSize={15} letterSpacing={1.4}>
              {params.value}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "licensingCouncils",
      headerName: "Registered Councils",
      flex: 1.5,
    },
    {
      field: "lastActiveDate",
      headerName: "Active",
      renderCell: (params) => {
        const datePast = moment(params.row.lastActiveDate);

        return (
          <div
            style={{
              backgroundColor: "rgba(90,96,255,0.2)",
              color: "#5A60FF",
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 25,
            }}
          >
            {params.row.lastActiveDate === "Never"
              ? "Never"
              : datePast.from(getCurrentDateTime())}
          </div>
        );
      },
    },

    {
      field: "action2",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton
            key={"id2"}
            onClick={() => {
              dispatch(setSelectedEditDriver(params.row));
            }}
          >
            <Edit />
          </IconButton>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton
            key={"id2"}
            onClick={() => {
              dispatch(setSelectedViewDriver(params.row));
              navigate("/admin/drivers/" + params.row.id);
            }}
          >
            <Launch />
          </IconButton>
        );
      },
    },
  ];
  function getRowId(row: any) {
    return row.id;
  }
  return (
    <DataGrid
      rows={rows}
      getRowId={getRowId}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: "none",
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250 }}
    />
  );
}
