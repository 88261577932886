import { useEffect, useRef, useState } from "react";
import {
  getLastObjectOfList,
  getMiles,
  getStateTypeDesc,
  useTools,
} from "../../services/general_services";
import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import * as turf from "@turf/turf";

import { getClientById } from "../../store/thunks/client.thunks";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AttachFile,
  Cancel,
  ChevronLeft,
  Close,
  Delete,
  Edit,
  EventAvailable,
  Info,
  Launch,
  Payments,
  Phone,
} from "@mui/icons-material";
import {
  setSelectedEditClient,
  setSelectedViewClient,
} from "../../store/slices/client.slice";
import { styles } from "../../assets/style/styles";
import { db } from "../../services/firebase_services";
import { primaryColor } from "../../assets/style/theme";
import moment, { isDate } from "moment";
import { findTripById, findUserTrips } from "../../store/thunks/booking.thunks";
import { RideGrid } from "../components/rides/RideGrid";
import { isUserBanned } from "../../services/data_saving_services";
import { InfoPanel } from "../components/dashboard/InfoPanel";
import {
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  Timeline,
  timelineOppositeContentClasses,
  timelineItemClasses,
} from "@mui/lab";
import { LocationModel } from "../../models/LocationModel";
import {
  GeneralDriver,
  StateAppModel,
  StateModel,
  StateType,
} from "../../models/BookingModels";
import { DriverModel } from "../../models/DriverModel";
import { setSelectedViewDriver } from "../../store/slices/driver.slice";

export function ViewRideScreen({ mapboxgl }: any) {
  const { dispatch, navigate } = useTools();

  const { selectedTrip, selectedTripLoading, selectedTripError } = useSelector(
    (state: any) => state.BookingSlice
  );
  const { id } = useParams();

  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);

  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(15);
  const [initialized, setInitialized] = useState(false);

  const [driver, setDriver] = useState<undefined | DriverModel>();

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (mapboxgl && selectedTrip) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lng, lat],
        zoom: zoom,
      });

      //Add markers to map
      let destinationList: any = selectedTrip.trip.waypoints.map(
        (val: LocationModel) => {
          return [val.lng, val.lat];
        }
      );

      if (selectedTrip.trip.journeyLocations) {
        // Add line for journey locations
        const route = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: selectedTrip.trip.journeyLocations.map(
                  (val: LocationModel) => {
                    return [val.lng, val.lat];
                  }
                ),
              },
            },
          ],
        };

        map.current.on("load", () => {
          console.log("Adding route??");
          // Add a source and layer displaying a point which will be animated in a circle.
          map.current.addSource("route", {
            type: "geojson",
            data: route,
          });

          console.log("Addding route...", route);

          map.current.addLayer({
            id: "route",
            source: "route",
            type: "line",
            paint: {
              "line-width": 2,
              "line-color": primaryColor,
            },
          });
        });
      } else {
        let origin: any = [
          selectedTrip.trip.origin.lng,
          selectedTrip.trip.origin.lat,
        ];

        // A single point that animates along the route.
        // Coordinates are initially set to origin.
        const point: any = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: origin,
              },
            },
          ],
        };
        let arc: undefined | Array<any> = undefined;
        if (destinationList.length > 0) {
          // Create a LineString feature from the coordinates
          const lineString = turf.lineString([origin, ...destinationList]);

          // Calculate the bezier spline curve
          const bezierSpline = turf.bezierSpline(lineString);

          // Number of steps to use in the animation
          const steps = 500;

          // Calculate the length of the bezier spline
          const bezierSplineLength = turf.length(bezierSpline, {
            units: "kilometers",
          });

          // Initialize the arc array with the starting point
          arc = [origin];

          // Calculate points along the bezier spline
          for (let t = 1; t <= steps; t++) {
            const fraction = (t / steps) * 2;

            const distanceAlongCurve = fraction * bezierSplineLength;
            const pointOnCurve = turf.along(bezierSpline, distanceAlongCurve, {
              units: "kilometers",
            });
            arc.push(pointOnCurve.geometry.coordinates);
          }
        }

        console.log(arc);
        // Update the route with the calculated arc coordinates
        const route = arc
          ? {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: arc,
                  },
                },
              ],
            }
          : undefined;

        // Used to increment the value of the point measurement against the route.
        let counter = 0;

        map.current.on("load", () => {
          // Add a source and layer displaying a point which will be animated in a circle.

          if (route) {
            map.current.addSource("route", {
              type: "geojson",
              data: route,
            });
            map.current.addLayer({
              id: "route",
              source: "route",
              type: "line",
              paint: {
                "line-width": 2,
                "line-color": primaryColor,
              },
            });
          }

          map.current.addSource("point", {
            type: "geojson",
            data: point,
          });
        });
      }

      if (selectedTrip.trip.startingLocation) {
        const popup = new mapboxgl.Popup()
          .setHTML(`${selectedTrip.trip.startingLocation.address}`)
          .addTo(map.current);

        new mapboxgl.Marker({ color: primaryColor })
          .setLngLat([
            selectedTrip.trip.startingLocation.lng,
            selectedTrip.trip.startingLocation.lat,
          ])
          .addTo(map.current)
          .setPopup(popup);
      } else {
        const popup = new mapboxgl.Popup()
          .setHTML(
            `<strong>Origin:<br /></strong>${selectedTrip.trip.origin.address}`
          )
          .addTo(map.current);

        new mapboxgl.Marker({ color: primaryColor })
          .setLngLat([
            selectedTrip.trip.origin.lng,
            selectedTrip.trip.origin.lat,
          ])
          .addTo(map.current)
          .setPopup(popup);
      }

      if (selectedTrip.trip.endingLocations) {
        for (let i = 0; i < selectedTrip.trip.endingLocations.length; i++) {
          let destination: LocationModel = selectedTrip.trip.endingLocations[i];
          const destPopup = new mapboxgl.Popup()
            .setHTML(`${destination.address}`)
            .addTo(map.current);

          new mapboxgl.Marker({ color: primaryColor })
            .setLngLat([destination.lng, destination.lat])
            .addTo(map.current)
            .setPopup(destPopup);

          destinationList.push([destination.lng, destination.lat]);
        }
      } else {
        for (let i = 0; i < selectedTrip.trip.waypoints.length; i++) {
          let destination = selectedTrip.trip.waypoints[i];
          const destPopup = new mapboxgl.Popup()
            .setHTML(
              `<strong>Destination:<br /></strong>${destination.address}`
            )
            .addTo(map.current);

          new mapboxgl.Marker({ color: primaryColor })
            .setLngLat([destination.lng, destination.lat])
            .addTo(map.current)
            .setPopup(destPopup);

          destinationList.push([destination.lng, destination.lat]);
        }
      }
    }
    if (selectedTrip && map.current) {
      console.log("origin lat: ", selectedTrip.trip.origin.lat);
      console.log("origin lng: ", selectedTrip.trip.origin.lng);
      setLat(selectedTrip.trip.origin.lat);
      setLng(selectedTrip.trip.origin.lng);

      console.log([
        {
          lat: selectedTrip.trip.origin.lat,
          lng: selectedTrip.trip.origin.lng,
        },

        ...selectedTrip.trip.waypoints.map((val: LocationModel) => {
          return { lat: val.lat, lng: val.lng };
        }),
      ]);
      let list = [
        {
          lat: selectedTrip.trip.origin.lat,
          lng: selectedTrip.trip.origin.lng,
        },

        ...selectedTrip.trip.waypoints.map((val: LocationModel) => {
          return { lat: val.lat, lng: val.lng };
        }),
      ];

      if (selectedTrip.trip.startingLocation) {
        list[0] = {
          lat: selectedTrip.trip.startingLocation.lat,
          lng: selectedTrip.trip.startingLocation.lng,
        };
      }
      if (selectedTrip.trip.endingLocations) {
        list = [list[0], ...selectedTrip.trip.endingLocations];
      }

      console.log(list);
      map.current.fitBounds(list, {
        speed: 4,
        padding: 50,
        offset: [0, 0],
      });
    }
  }, [mapboxgl, selectedTrip]);

  useEffect(() => {
    if (!selectedTrip && !initialized && id) {
      dispatch(findTripById({ tripId: id }));
      setInitialized(true);
    }
  }, []);

  const [distance, setDistance] = useState("0");
  const [timeWaited, setTimeWaited] = useState("0");
  const [duration, setDuration] = useState("0");

  useEffect(() => {
    if (selectedTrip) {
      console.log("meh", selectedTrip.trip.driversResponded);
      if (selectedTrip.trip.driversResponded) {
        if (selectedTrip.trip.driversResponded.length > 0) {
          console.log("meh", selectedTrip.trip.driversResponded);
          setDriver(
            selectedTrip.trip.driversResponded[
              selectedTrip.trip.driversResponded.length - 1
            ].driver
          );
        }
      }

      let miles_driven = 0;
      let mins_waited = 0;

      let diff =
        selectedTrip &&
        selectedTrip.trip.origin.arrivalTime &&
        selectedTrip.trip.origin.departureTime
          ? moment(selectedTrip.trip.origin.arrivalTime).diff(
              moment(selectedTrip.trip.origin.departureTime),
              "minutes"
            )
          : 0;

      // Do not consider origin drive (Yet)
      // if (currentDriverTrip.origin.distanceDriven) {
      //   miles_driven += getMiles(
      //     currentDriverTrip.origin.distanceDriven,
      //   );
      // }

      // Only account for actual waited time...
      if (diff > 3) {
        mins_waited = diff - 3;
      }

      if (selectedTrip) {
        for (let i = 0; i < selectedTrip.trip.waypoints.length; i++) {
          let waypoint = selectedTrip.trip.waypoints[i];

          // Only account for waiting for waypoints (Not destination)
          if (i !== selectedTrip.trip.waypoints.length - 1) {
            let waypointDiff = moment(waypoint.arrivalTime).diff(
              moment(waypoint.departureTime),
              "minutes"
            );

            // Only account for actual waited time...
            if (waypointDiff > 3) {
              mins_waited = diff - 3;
            }
          }
          if (waypoint.distanceDriven) {
            miles_driven += getMiles(waypoint.distanceDriven);
          }
        }
        let endedEarly: StateModel = selectedTrip.trip.states.find(
          (val: StateAppModel) => val.state === "Trip ended early"
        );
        let lastWaypoint = getLastObjectOfList(selectedTrip.trip.waypoints);

        let startTime: string | undefined = undefined;

        if (selectedTrip.trip.origin.departureTime) {
          startTime = selectedTrip.trip.origin.departureTime;
        } else {
          startTime = selectedTrip.trip.creationDateTime;
        }

        let endTime: string | undefined = undefined;
        if (endedEarly) {
          endTime = endedEarly.dateTime;
        } else if (lastWaypoint && lastWaypoint.arrivalTime) {
          endTime = lastWaypoint.arrivalTime;
        }

        setDistance(miles_driven.toFixed(2));
        setTimeWaited(mins_waited + " Mins");
        if (startTime && endTime) {
          setDuration(
            moment(new Date(endTime)).diff(
              moment(new Date(startTime)),
              "minutes"
            ) + " Mins"
          );
        }
      }
    }
  }, [selectedTrip]);

  return (
    <Grid item container xs={12}>
      <Grid
        container
        xs={9}
        item
        justifyContent={"center"}
        alignContent={"flex-start"}
      >
        <Grid item container xs={12}>
          <Button
            onClick={() => {
              navigate("/admin/rides");
            }}
            variant={"text"}
            sx={{ position: "relative", top: -40, left: 0 }}
          >
            <ChevronLeft />
            Back to rides
          </Button>
        </Grid>
        {selectedTripLoading || !selectedTrip ? (
          <CircularProgress />
        ) : (
          <Grid item container xs={11} spacing={2}>
            <Grid item container xs={12}>
              <Typography variant="h5">
                {selectedTrip.trip.origin.address.split(",")[0] +
                  " > " +
                  selectedTrip.trip.waypoints[
                    selectedTrip.trip.waypoints.length - 1
                  ].address.split(",")[0]}{" "}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid container item style={{ overflow: "scroll" }}>
                <Grid
                  container
                  item
                  style={{ height: 100, minWidth: 500 }}
                  columnSpacing={2}
                >
                  <Grid item xs={4}>
                    <InfoPanel
                      backgroundColor={"rgba(0,238,253,0.19)"}
                      icon={
                        <Payments
                          fontSize={"large"}
                          style={{ color: "#249BC1" }}
                        />
                      }
                      color={"#249BC1"}
                      title={"Total Fare"}
                      value={
                        selectedTrip.trip.amountPaid
                          ? selectedTrip.trip.amountPaid
                          : "0.00"
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoPanel
                      backgroundColor={"rgba(158,255,0,0.2)"}
                      icon={
                        <Payments
                          fontSize={"large"}
                          style={{ color: "#24C18F" }}
                        />
                      }
                      color={"#24C18F"}
                      title={"Driver Cut"}
                      value={
                        selectedTrip.trip.driverPaid
                          ? selectedTrip.trip.driverPaid
                          : "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InfoPanel
                      backgroundColor={"rgba(255,124,51,0.19)"}
                      icon={
                        <Payments
                          fontSize={"large"}
                          style={{ color: "#FF7C33" }}
                        />
                      }
                      color={"#FF7C33"}
                      title={"Geo GO Cut"}
                      value={
                        selectedTrip.trip.amountPaid &&
                        selectedTrip.trip.driverPaid
                          ? (
                              Number.parseFloat(selectedTrip.trip.amountPaid) -
                              Number.parseFloat(selectedTrip.trip.driverPaid)
                            ).toFixed(2)
                          : "N/A"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} marginBottom={2} spacing={2}>
              {/* Map */}
              <Grid item xs={12} sm={6} container>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ height: { xs: "350px", sm: "100%" }, width: "100%" }}
                >
                  {" "}
                  <div
                    style={{
                      width: "100%",
                      height: "100% !important",
                      borderRadius: 5,
                    }}
                    ref={mapContainer}
                    className="activity-map-container"
                  />
                </Grid>
              </Grid>

              {/* Details */}
              <Grid container item xs={12} sm={6}>
                <Grid container item boxShadow={2} borderRadius={5} padding={4}>
                  <Grid item container xs={12}>
                    <Typography variant={"h6"}>Journey Stops</Typography>
                  </Grid>
                  <Timeline
                    style={{ width: "100%" }}
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        {selectedTrip.trip.origin.address}
                      </TimelineContent>
                    </TimelineItem>
                    {selectedTrip.trip.waypoints.map(
                      (val: LocationModel, index: number) => {
                        return (
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot />
                              {index !==
                                selectedTrip.trip.waypoints.length - 1 && (
                                <TimelineConnector />
                              )}
                            </TimelineSeparator>
                            <TimelineContent>{val.address}</TimelineContent>
                          </TimelineItem>
                        );
                      }
                    )}
                  </Timeline>

                  <Grid item container xs={12}>
                    <Typography variant={"h6"} sx={{ marginBottom: 2 }}>
                      Journey Information
                    </Typography>
                    <Grid item container xs={12}>
                      <Grid
                        item
                        container
                        xs={6}
                        flexDirection={"column"}
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="body2" fontWeight={"bold"}>
                            Duration:
                          </Typography>
                        </Grid>{" "}
                        <Grid item>
                          <Typography variant="body2" fontWeight={"bold"}>
                            Distance Driven:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" fontWeight={"bold"}>
                            Minutes Late:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" fontWeight={"bold"}>
                            Total Fare:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" fontWeight={"bold"}>
                            Driver Commission:
                          </Typography>
                        </Grid>
                        {selectedTrip.trip.details.carClass && (
                          <Grid item>
                            <Typography variant="body2" fontWeight={"bold"}>
                              Car Class:
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={6}
                        flexDirection={"column"}
                        alignContent={"flex-end"}
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="body2">{duration}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            {distance} Miles
                          </Typography>
                        </Grid>{" "}
                        <Grid item>
                          <Typography variant="body2">{timeWaited}</Typography>
                        </Grid>{" "}
                        <Grid item>
                          <Typography variant="body2">
                            {selectedTrip.trip.amountPaid
                              ? "£" + selectedTrip.trip.amountPaid
                              : "£" +
                                selectedTrip.trip.estimatedCost +
                                " (Est)"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            {selectedTrip.trip.driverPaid
                              ? "£" + selectedTrip.trip.driverPaid
                              : "Unpaid"}
                          </Typography>
                        </Grid>
                        {selectedTrip.trip.details.carClass && (
                          <Grid
                            item
                            alignContent={"center"}
                            alignItems={"center"}
                            flexDirection={"row"}
                            container
                          >
                            <Typography variant="body2">
                              {selectedTrip.trip.details.carClass.name}
                            </Typography>
                            <Tooltip
                              style={{ width: "300px" }}
                              title={
                                <div style={{ flexDirection: "column" }}>
                                  <h3>
                                    {selectedTrip.trip.details.carClass.name}
                                  </h3>

                                  <p>
                                    <strong>Base Price: </strong>£
                                    {Number.parseFloat(
                                      selectedTrip.trip.details.carClass
                                        .basePrice
                                    ).toFixed(2)}
                                  </p>

                                  <p>
                                    <strong>Price per min:</strong>£
                                    {Number.parseFloat(
                                      selectedTrip.trip.details.carClass
                                        .pricePerMin
                                    ).toFixed(2)}
                                  </p>
                                  <p>
                                    <strong>Price per mile:</strong>£
                                    {Number.parseFloat(
                                      selectedTrip.trip.details.carClass
                                        .pricePerMile
                                    ).toFixed(2)}
                                  </p>

                                  <p>
                                    <strong>Num seats:</strong>
                                    {
                                      selectedTrip.trip.details.carClass
                                        .numSeats
                                    }
                                  </p>
                                  <p>
                                    <strong>Cancellation Fee:</strong>£
                                    {Number.parseFloat(
                                      selectedTrip.trip.details.carClass
                                        .cancellationFee
                                    ).toFixed(2)}
                                  </p>
                                  <p>
                                    <b>Company Commission:</b>
                                    {
                                      selectedTrip.trip.details.carClass
                                        .companyCommission
                                    }
                                    %
                                  </p>
                                </div>
                              }
                            >
                              <Info style={{ width: "50px" }} />
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Driver and customer */}
            <Grid
              item
              container
              xs={12}
              marginBottom={2}
              spacing={2}
              sx={{ marginTop: 1, paddingLeft: "40px !important" }}
            >
              <Grid
                container
                xs={12}
                item
                sx={{
                  boxShadow: 2,
                  borderRadius: 5,
                  paddingTop: "0px !important",
                  paddingBottom: "16px",
                }}
                spacing={2}
              >
                <Grid
                  item
                  container
                  xs={12}
                  md={5.8}
                  sx={{ cursor: driver ? "pointer" : "default" }}
                  onClick={() => {
                    if (driver) {
                      dispatch(setSelectedViewDriver(driver));
                      navigate("/admin/drivers/" + driver.id);
                    }
                  }}
                >
                  {selectedTrip.trip.details &&
                  selectedTrip.trip.details.driver ? (
                    <Grid container justifyContent={"center"}>
                      <Typography
                        variant="h6"
                        fontWeight={"bold"}
                        style={{
                          fontSize: 12,
                          color: "rgba(0,0,0,0.7)",
                          marginBottom: 4,
                        }}
                      >
                        Driver
                      </Typography>

                      <Grid container item xs={12}>
                        <Grid item xs={4}>
                          {" "}
                          <div
                            style={{
                              backgroundImage: `url("${
                                driver && driver.profilePictureUrl
                                  ? driver.profilePictureUrl
                                  : "/static/avatar.svg"
                              }")`,
                              padding: 0,
                              paddingRight: 10,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: 60,
                              width: 60,
                              borderRadius: 40,
                            }}
                          />
                        </Grid>
                        <Grid item xs={8} flexDirection={"column"}>
                          <Typography variant={"h6"}>
                            {selectedTrip.trip.details.driver.firstName +
                              " " +
                              selectedTrip.trip.details.driver.lastName}
                          </Typography>
                          <Grid
                            item
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{
                              ...styles.regPlate,
                              borderRadius: 3,
                              padding: 0,
                              paddingLeft: 0,
                              paddingRight: 0,
                              height: "50px",
                              width: 140,
                            }}
                          >
                            <Typography variant={"h6"} fontSize={16}>
                              {
                                selectedTrip.trip.details.driver
                                  .registrationPlate
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container sx={{ marginTop: "15px" }}>
                          <Grid item xs={12} flexDirection={"column"}>
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 8,
                                textTransform: "uppercase",
                                letterSpacing: 1.2,
                              }}
                            >
                              Licence Num:
                            </Typography>

                            <Typography>
                              {selectedTrip.trip.details.driver.licenceNumber}
                            </Typography>
                            <br />
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 8,
                                textTransform: "uppercase",
                                letterSpacing: 1.2,
                              }}
                            >
                              Badge Num:
                            </Typography>
                            <Typography fontWeight={"bold"}>
                              {selectedTrip.trip.details.driver.badgeNumber}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      style={{
                        fontSize: 12,
                        color: "rgba(0,0,0,0.7)",
                        marginBottom: 4,
                      }}
                    >
                      No Driver
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={0.1}
                  sx={{ display: { xs: "none", md: "flex" }, height: "100%" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                >
                  <Grid
                    item
                    container
                    xs={10}
                    sx={{
                      borderRight: "0.5px solid rgba(0,0,0,0.1)",
                      height: "100%",
                    }}
                  ></Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={5.8}
                  sx={{
                    cursor: driver ? "pointer" : "default",
                    paddingLeft: 2,
                  }}
                >
                  <Grid
                    container
                    alignContent={"flex-start"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      style={{
                        fontSize: 12,
                        color: "rgba(0,0,0,0.7)",
                        marginBottom: 4,
                      }}
                    >
                      Customer
                    </Typography>

                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        {" "}
                        <div>
                          <div
                            style={{
                              backgroundImage: `url("${
                                selectedTrip.trip.bookingUser.profilePictureUrl
                                  ? selectedTrip.trip.bookingUser
                                      .profilePictureUrl
                                  : "/static/avatar.svg"
                              }")`,
                              padding: 0,
                              paddingRight: 10,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: 60,
                              width: 60,
                              maxWidth: "60px !important",
                              borderRadius: 40,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={8} flexDirection={"column"}>
                        <Typography variant={"h6"}>
                          {selectedTrip.trip.bookingUser.firstName +
                            " " +
                            selectedTrip.trip.bookingUser.lastName}
                        </Typography>
                        <Button
                          onClick={() => {
                            dispatch(
                              setSelectedViewClient({
                                user: selectedTrip.trip.bookingUser,
                              })
                            );
                            navigate(
                              "/admin/clients/" +
                                selectedTrip.trip.bookingUser.id
                            );
                          }}
                        >
                          View Profile
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {selectedTrip && (
        <Grid item container xs={3}>
          <Grid
            item
            container
            sx={{ backgroundColor: "black", marginRight: -20 }}
            borderRadius={3}
          >
            <Timeline
              style={{ width: "100%" }}
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {selectedTrip.trip.states.map(
                (val: StateAppModel, index: number) => {
                  return (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        {index !== selectedTrip.trip.states.length - 1 && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Grid
                          item
                          container
                          xs={12}
                          sx={{
                            backgroundColor: "white",
                            borderRadius: 6,
                            borderTopLeftRadius: 0,
                            padding: 2,
                            paddingTop: 2,
                            maxWidth: "100%",
                            paddingBottom: 1,
                          }}
                        >
                          <Typography
                            variant={"h6"}
                            sx={{ lineHeight: 1.2, marginBottom: 1 }}
                          >
                            {val.state}.
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{
                              fontFamily: "Montserrat",
                              fontSize: 11,
                              fontWeight: "100",
                              marginBottom: 1,
                            }}
                          >
                            {getStateTypeDesc(val.state)}
                          </Typography>

                          <Grid
                            item
                            sx={{
                              backgroundColor: primaryColor,
                              borderRadius: 20,
                              alignItems: "center",

                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Grid
                              container
                              justifyContent={"center"}
                              alignContent={"center"}
                              sx={{
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Typography
                                variant={"body1"}
                                style={{
                                  padding: 5,
                                  fontSize: 12,
                                  color: "white",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                {moment(val.dateTime).format("Do MMM HH:mma")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TimelineContent>
                    </TimelineItem>
                  );
                }
              )}
            </Timeline>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
