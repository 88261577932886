import { CarClassModel } from "./CarClassModel";
import { DriverModel } from "./DriverModel";
import { LocationModel } from "./LocationModel";
import { MessageAppModel, MessageModel } from "./MessageModel";
import UserDataModel from "./UserDataModel";
import { VoucherModel } from "./VoucherModel";

export interface BookingModel {
  id: string;
  tripType: TripType;
  state: StateType;
  carClassId: string;
  active: boolean;
  creationDate: string;
  date?: string;
  airportTransfer?: boolean;
  origin: LocationModel;
  distanceSearch: number;
}

export interface DriverJobModel {
  id: string;
  tripType: TripType;
  state: StateType;
  joinDate: string;
  startDate: string;
  active: boolean;
}

export interface UserBookingModel {
  id: string;
  active: boolean;
}

export enum TripType {
  BOOKED_RIDE = "BOOKED_RIDE",
  INSTANT_RIDE = "INSTANT_RIDE",
}

// Id = Id of ride
// Active = If ride is active
// Booking user ID = Id of user booking
// Date time - Optional, for future bookings
// Selected car class ID - Class selected for trip
// Estimated cost - Cost of trip approximately
// Voucher ID = Id of voucher used
// Drivers responded = List of drivers who have responded.
export interface RideModel {
  id: string;
  active: boolean;
  bookingUserId: string;
  dateTime?: string;
  selectedCarClassId: string;
  estimatedCost: number;
  voucherId?: string;
  driversResponded?: GeneralDriver[];
  origin: LocationModel;

  creationDateTime: string;
  waypoints: LocationModel[];
  states: StateModel[];
  currentIndex: number;
  cost?: number;
  distance?: number;
  duration?: number;
  distanceToDest: number;
  durationToDest: number;
  driverLocation?: LocationModel;
  customerLocation?: LocationModel;
  journeyLocations?: LocationModel[];

  startingLocation?: LocationModel[];
  endingLocations?: LocationModel[];
  messages?: Array<MessageModel[]>;
  request?: RequestModel;
  password: string;
  paymentIntentId?: string;
  minutesWaited: number;
  airportTransfer: boolean;
  amountPaid?: number;
  driverPaid?: number;
  details?: DetailsModel;
}

// Model storing present details at time of booking.
// Avoids data changing in future no longer representing the trip data at the time.
export interface DetailsModel {
  customer: CustomerDetailsModel;
  driver?: DriverDetailsModel;
  carClass: CarClassDetailsModel;
}

// Customer details
export interface CustomerDetailsModel {
  id: string;
  firstName: string;
  lastName: string;
}
export interface CarClassDetailsModel {
  id: string;
  name: string;
  imageUrl: string;
  basePrice: number;
  pricePerMin: number;
  pricePerMile: number;
  numSeats: number;
  companyCommission: number;
  cancellationFee: number;
}
// Driver details
export interface DriverDetailsModel {
  id: string;
  firstName: string;
  lastName: string;
  registrationPlate: string;
  licenceNumber: string;
  badgeNumber: string;
}

export interface RequestModel {
  distance: number;
  duration: number;
  waypoints: LocationModel[];
  estimatedCost: number;
}

export interface StateModel {
  state: StateType;
  dateTime: string;
}

export interface StateAppModel {
  state: string;
  dateTime: string;
}

// State should represent the ride
// Future trip - Looking for driver
// Future trip - Driver found
// Instant trip - Looking for driver
// Driver on way
// Driver arrived
// Trip in progress
// Customer arrived at destination
// Trip finished
// Customer finished early
// Driver finished early
// Aiport driver arriving at pickup
// Airport customer arriving at airport
// Airport driver arriving at airport (Return trip)
export enum StateType {
  FUTURE_TRIP_LOOKING_FOR_DRIVER = "FUTURE_TRIP_LOOKING_FOR_DRIVER",
  AIRPORT_TRIP_LOOKING_FOR_DRIVER = "AIRPORT_TRIP_LOOKING_FOR_DRIVER",
  AIRPORT_TRIP_DRIVER_FOUND = "AIRPORT_TRIP_DRIVER_FOUND",
  FUTURE_TRIP_DRIVER_FOUND = "FUTURE_TRIP_DRIVER_FOUND",
  INSTANT_TRIP_LOOKING_FOR_DRIVER = "INSTANT_TRIP_LOOKING_FOR_DRIVER",
  GENERAL_DRIVER_FINISHING_TRIP = "GENERAL_DRIVER_FINISHING_TRIP",
  GENERAL_DRIVER_ON_WAY = "GENERAL_DRIVER_ON_WAY",
  AIRPORT_DRIVER_ARRIVED_AT_PICKUP = "AIRPORT_DRIVER_ARRIVED_AT_PICKUP",
  GENERAL_DRIVER_ARRIVED = "GENERAL_DRIVER_ARRIVED",
  GENERAL_DRIVER_NOT_FOUND = "GENERAL_DRIVER_NOT_FOUND",
  GENERAL_DRIVER_NOT_CONFIRMED = "GENERAL_DRIVER_NOT_CONFIRMED",
  GENERAL_CUSTOMER_LATE = "GENERAL_CUSTOMER_LATE",
  GENERAL_TRIP_IN_PROGRESS = "GENERAL_TRIP_IN_PROGRESS",
  AIRPORT_CUSTOMER_ARRIVED_AT_AIRPORT = "AIRPORT_CUSTOMER_ARRIVED_AT_AIRPORT",
  AIRPORT_DRIVER_ARRIVED_AT_AIRPORT = "AIRPORT_DRIVER_ARRIVED_AT_AIRPORT",
  GENERAL_ARRIVED_AT_WAYPOINT = "GENERAL_ARRIVED_AT_WAYPOINT",
  GENERAL_ARRIVED_AT_DESTINATION = "GENERAL_ARRIVED_AT_DESTINATION",
  GENERAL_TRIP_CHANGED = "GENERAL_TRIP_CHANGED",
  GENERAL_TRIP_FINISHED = "GENERAL_TRIP_FINISHED",
  GENERAL_CUSTOMER_ENDED_EARLY = "GENERAL_CUSTOMER_ENDED_EARLY",
  GENERAL_DRIVER_ENDED_EARLY = "GENERAL_DRIVER_ENDED_EARLY",
  GENERAL_DRIVER_LEFT = "GENERAL_DRIVER_LEFT",
  GENERAL_CONFIRM_NEW_PRICE = "GENERAL_CONFIRM_NEW_PRICE",
}

export interface RideAppModel {
  active: boolean;
  id: string;
  bookingUser: UserDataModel;
  dateTime?: string;
  selectedCarClass: CarClassModel;
  estimatedCost: number;
  voucher?: VoucherModel;
  driversResponded?: GeneralAppDriver[];
  origin: LocationModel;
  waypoints: LocationModel[];
  states: StateAppModel[];
  creationDateTime: string;
  currentIndex: number;
  cost?: number;
  distance?: number;
  duration?: number;
  distanceToDest: number;
  durationToDest: number;
  driverLocation?: LocationModel;
  customerLocation?: LocationModel;
  journeyLocations?: LocationModel[];
  startingLocation?: LocationModel[];
  endingLocations?: LocationModel[];
  messages?: MessageAppModel[];
  request?: RequestModel;
  distanceDriven?: number;
  password: string;
  paymentIntentId?: string;
  minutesWaited: number;
  airportTransfer: boolean;
  amountPaid?: number;
  driverPaid?: number;
  driverNotFound?: boolean;
  details?: DetailsModel;
}

export interface GeneralDriver {
  driverId: string;
  active: boolean;
  leaveReasoning?: string;
}

export interface GeneralAppDriver {
  driver: DriverModel;
  active: boolean;
}
