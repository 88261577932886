import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase_services";
import {
  DriverAppModel,
  DriverModel,
  ExpirationModel,
} from "../../models/DriverModel";
import { CarClassModel } from "../../models/CarClassModel";
import {
  BookingModel,
  GeneralAppDriver,
  RideAppModel,
  RideModel,
  StateAppModel,
  StateModel,
  TripType,
} from "../../models/BookingModels";
import UserDataModel from "../../models/UserDataModel";

import { getStateTypeString } from "../../services/general_services";
import { getDriversFromList } from "../../services/data_saving_services";
import { TimeFrame } from "../../models/TimeFrameModel";
import { DriverEarningModel, EarningModel } from "../../models/EarningsModel";
import moment from "moment";
import { da } from "date-fns/locale";
import { PayoutAppModel, PayoutModel } from "../../models/PayoutModel";

export const findDriverEarnings = createAsyncThunk(
  "FindDriverEarnings",
  async ({
    driverId,
    timeframe,
  }: {
    driverId: string;
    timeframe: TimeFrame;
  }): Promise<EarningModel[]> => {
    let earnings_to_return: EarningModel[] = [];

    let days_add: any =
      timeframe === TimeFrame.ALL
        ? null
        : timeframe === TimeFrame.DAILY
        ? -1
        : timeframe === TimeFrame.WEEKLY
        ? -7
        : timeframe === TimeFrame.MONTHLY
        ? -30
        : timeframe === TimeFrame.YEARLY && -365;

    let start_date =
      days_add !== null
        ? moment(new Date()).add(days_add, "days").toDate().toISOString()
        : null;
    let end_date = new Date().toISOString();

    let earningsDb = (
      await db
        .ref("/payment_data/driver_earnings/" + driverId)
        .orderByChild("date")
        .startAfter(start_date)
        .endAt(end_date)
        .once("value")
    ).val();

    if (earningsDb !== null) {
      earnings_to_return = Object.values(earningsDb);
    }

    return earnings_to_return;
  }
);

export const findDriverExpirations = createAsyncThunk(
  "FindDriverExpirations",
  async ({ driverId }: { driverId: string }): Promise<ExpirationModel[]> => {
    let expirations_to_return: ExpirationModel[] = [];

    let expirationsDb = (
      await db
        .ref("/driver_expirations/" + driverId)
        .orderByChild("date")
        .once("value")
    ).val();

    if (expirationsDb !== null) {
      expirations_to_return = Object.values(expirationsDb);
    }

    return expirations_to_return;
  }
);

export const findAllDriverEarnings = createAsyncThunk(
  "FindAllDriverEarnings",
  async ({
    timeframe,
  }: {
    timeframe: TimeFrame;
  }): Promise<{
    earnings: DriverEarningModel[];
    grossProfit: number;
    netProfit: number;
  }> => {
    try {
      let netProfit = 0;
      let grossProfit = 0;

      let days_add: any =
        timeframe === TimeFrame.ALL
          ? null
          : timeframe === TimeFrame.DAILY
          ? -1
          : timeframe === TimeFrame.WEEKLY
          ? -7
          : timeframe === TimeFrame.MONTHLY
          ? -30
          : timeframe === TimeFrame.YEARLY && -365;

      let start_date =
        days_add !== null
          ? moment(new Date()).add(days_add, "days").toDate().toISOString()
          : null;
      let end_date = new Date().toISOString();

      let earningsDb = (
        await db
          .ref("/payment_data/payouts/")
          .orderByChild("date")
          .startAfter(start_date)
          .endAt(end_date)
          .once("value")
      ).val();

      let driverDataAmounts: DriverEarningModel[] = [];

      if (earningsDb !== null) {
        let earningsList: EarningModel[] = Object.values(earningsDb);
        let driversAmounts: any[] = [];

        for (let i = 0; i < earningsList.length; i++) {
          let inner_earning: EarningModel = earningsList[i];

          let match = driversAmounts.find(
            (val) => val.driverId === inner_earning.driverId
          );

          // Populate list of drivers and their amounts.

          grossProfit += Number.parseFloat(inner_earning.fullAmount);

          let net_profit =
            Number.parseFloat(inner_earning.fullAmount) -
            Number.parseFloat(inner_earning.amount);

          netProfit += net_profit;

          if (match) {
            let new_amount =
              Number.parseFloat(match.amount) +
              Number.parseFloat(inner_earning.fullAmount);

            driversAmounts = driversAmounts.map((val) => {
              if (val.driverId === inner_earning.driverId) {
                return {
                  ...val,
                  amount: new_amount.toFixed(2),
                  orders: val.orders + 1,
                };
              } else {
                return val;
              }
            });
          } else {
            driversAmounts.push({
              driverId: inner_earning.driverId,
              amount: inner_earning.fullAmount,
              orders: 1,
            });
          }
        }

        for (let i = 0; i < driversAmounts.length; i++) {
          // Get drivers
          let driverDb = db.ref("users/" + driversAmounts[i].driverId);

          let driverFound = await driverDb.once("value");

          if (driverFound.val() !== null) {
            driverDataAmounts.push({
              amount: driversAmounts[i].amount,
              fullAmount: driversAmounts[i].fullAmount,
              driver: driverFound.val(),
              orders: driversAmounts[i].orders,
            });
          }
        }
      }
      return {
        earnings: driverDataAmounts,
        grossProfit: grossProfit,
        netProfit: netProfit,
      };
    } catch (err) {
      console.log(err);
      return { earnings: [], grossProfit: 0, netProfit: 0 };
    }
  }
);

export const findTrips = createAsyncThunk(
  "FindTrips",
  async ({
    timeframe,
  }: {
    timeframe: TimeFrame;
  }): Promise<
    | {
        trips: Array<{
          trip: RideAppModel;
          type: TripType;
          id: string;
        }>;
        numTrips: number;
      }
    | undefined
  > => {
    try {
      // Create ride, then save ref to bookings.

      let numTrips = 0;

      let days_add: any =
        timeframe === TimeFrame.ALL
          ? null
          : timeframe === TimeFrame.DAILY
          ? -1
          : timeframe === TimeFrame.WEEKLY
          ? -7
          : timeframe === TimeFrame.MONTHLY
          ? -30
          : timeframe === TimeFrame.YEARLY && -365;

      let start_date =
        days_add !== null
          ? moment(new Date()).add(days_add, "days").toDate().toISOString()
          : null;
      let end_date = new Date().toISOString();

      let tripsFound = (
        await db
          .ref("/booking_data/rides/")
          .orderByChild("creationDateTime")
          .startAfter(start_date)
          .endAt(end_date)
          .once("value")
      ).val();

      let allTrips = [];

      if (tripsFound) {
        const list = tripsFound;

        let actual_list: RideModel[] = Object.values(list);

        if (actual_list.length > 0) {
          for (let i = 0; i < actual_list.length; i++) {
            numTrips += 1;
            let transfer: RideModel = actual_list[i];

            // Get drivers...

            let driversApp: GeneralAppDriver[] = [];

            if (transfer.driversResponded) {
              let drivers = await getDriversFromList(
                transfer.driversResponded
                  ? Object.values(transfer.driversResponded)
                  : []
              );

              if (drivers) {
                driversApp = drivers;
              }
            }

            // Get car class...

            let carClassDb = db.ref(
              "car_classes/" + transfer.selectedCarClassId
            );

            let selectedCarClass: CarClassModel = {} as CarClassModel;

            if (transfer.details) {
              selectedCarClass = transfer.details?.carClass;
            }
            let states: StateAppModel[] = transfer.states
              ? Object.values(transfer.states).map((val: StateModel) => {
                  return {
                    state: getStateTypeString(val.state),
                    dateTime: val.dateTime,
                  };
                })
              : [];
            let currentBookee = (
              await db.ref("/users/" + transfer.bookingUserId).once("value")
            ).val();
            let rideModel: RideAppModel = {
              id: transfer.id,
              active: transfer.active,
              bookingUser: currentBookee,
              origin: transfer.origin,
              states: states,
              currentIndex: transfer.currentIndex,
              driversResponded: driversApp,
              estimatedCost: transfer.estimatedCost,
              waypoints: transfer.waypoints,
              dateTime: transfer.dateTime,
              selectedCarClass: selectedCarClass,
              creationDateTime: transfer.creationDateTime,
              cost: transfer.cost,
              driverLocation: transfer.driverLocation,
              distance: transfer.distance,
              duration: transfer.duration,
              request: transfer.request,
              password: transfer.password,
              distanceToDest: transfer.distanceToDest,
              durationToDest: transfer.durationToDest,
              paymentIntentId: transfer.paymentIntentId,
              minutesWaited: transfer.minutesWaited,
              airportTransfer: transfer.airportTransfer,
              amountPaid: transfer.amountPaid,
              driverPaid: transfer.driverPaid,
              details: transfer.details,
            };
            if (transfer.journeyLocations) {
              rideModel = {
                ...rideModel,
                journeyLocations: Object.values(transfer.journeyLocations),
              };
            }
            if (transfer.startingLocation) {
              rideModel = {
                ...rideModel,
                startingLocation: transfer.startingLocation,
              };
            }
            if (transfer.endingLocations) {
              rideModel = {
                ...rideModel,
                endingLocations: Object.values(transfer.endingLocations),
              };
            }
            allTrips.push({
              id: transfer.id,
              trip: rideModel,
              type: rideModel.dateTime
                ? TripType.BOOKED_RIDE
                : TripType.INSTANT_RIDE,
            });
          }
        }
      }

      return { trips: allTrips, numTrips };
    } catch (err) {
      console.log(err);
      return;
    }
  }
);

export const findPayouts = createAsyncThunk(
  "FindPayouts",
  async ({
    timeframe,
  }: {
    timeframe: TimeFrame;
  }): Promise<PayoutAppModel[]> => {
    try {
      // Create ride, then save ref to bookings.

      let numPayouts = 0;

      let days_add: any =
        timeframe === TimeFrame.ALL
          ? null
          : timeframe === TimeFrame.DAILY
          ? -1
          : timeframe === TimeFrame.WEEKLY
          ? -7
          : timeframe === TimeFrame.MONTHLY
          ? -30
          : timeframe === TimeFrame.YEARLY && -365;

      let start_date =
        days_add !== null
          ? moment(new Date()).add(days_add, "days").toDate().toISOString()
          : null;
      let end_date = new Date().toISOString();

      let payoutsFound = (
        await db
          .ref("/payment_data/payouts/")
          .orderByChild("date")
          .startAfter(start_date)
          .endAt(end_date)
          .once("value")
      ).val();

      let allPayouts: PayoutAppModel[] = [];

      if (payoutsFound) {
        console.log("Payouts found..");

        const list = payoutsFound;

        let actual_list: PayoutModel[] = Object.values(list);

        if (actual_list.length > 0) {
          for (let i = 0; i < actual_list.length; i++) {
            numPayouts += 1;

            let payout: PayoutModel = actual_list[i];

            let driverDb = db.ref("users/" + payout.driverId);

            let driverFound = await driverDb.once("value");

            let driver: null | DriverAppModel = null;

            console.log("Payout", payout);

            if (driverFound.val() !== null) {
              driver = driverFound.val();
            }

            let rideDb = db.ref("/booking_data/rides/" + payout.tripId);

            let rideFound = await rideDb.once("value");

            let ride: null | RideAppModel = null;

            if (rideFound.val() !== null) {
              let transfer: RideModel = rideFound.val();

              // Get drivers...

              let driversApp: GeneralAppDriver[] = [];

              if (transfer.driversResponded) {
                let drivers = await getDriversFromList(
                  transfer.driversResponded
                    ? Object.values(transfer.driversResponded)
                    : []
                );

                if (drivers) {
                  driversApp = drivers;
                }
              }

              // Get car class...

              let carClassDb = db.ref(
                "car_classes/" + transfer.selectedCarClassId
              );

              let carClassFound = await carClassDb.once("value");
              let selectedCarClass: CarClassModel = {} as CarClassModel;

              if (carClassFound.val() !== null) {
                selectedCarClass = carClassFound.val();
              }

              let states: StateAppModel[] = transfer.states
                ? Object.values(transfer.states).map((val: StateModel) => {
                    return {
                      state: getStateTypeString(val.state),
                      dateTime: val.dateTime,
                    };
                  })
                : [];
              let currentBookee = (
                await db.ref("/users/" + transfer.bookingUserId).once("value")
              ).val();
              let rideModel: RideAppModel = {
                id: transfer.id,
                active: transfer.active,
                bookingUser: currentBookee,
                origin: transfer.origin,
                states: states,
                currentIndex: transfer.currentIndex,
                driversResponded: driversApp,
                estimatedCost: transfer.estimatedCost,
                waypoints: transfer.waypoints,
                dateTime: transfer.dateTime,
                selectedCarClass: selectedCarClass,
                creationDateTime: transfer.creationDateTime,
                cost: transfer.cost,
                driverLocation: transfer.driverLocation,
                distance: transfer.distance,
                duration: transfer.duration,
                request: transfer.request,
                password: transfer.password,
                distanceToDest: transfer.distanceToDest,
                durationToDest: transfer.durationToDest,
                paymentIntentId: transfer.paymentIntentId,
                minutesWaited: transfer.minutesWaited,
                airportTransfer: transfer.airportTransfer,
                amountPaid: transfer.amountPaid,
                driverPaid: transfer.driverPaid,
                details: transfer.details,
              };

              if (transfer.journeyLocations) {
                rideModel = {
                  ...rideModel,
                  journeyLocations: Object.values(transfer.journeyLocations),
                };
              }
              if (transfer.startingLocation) {
                rideModel = {
                  ...rideModel,
                  startingLocation: transfer.startingLocation,
                };
              }
              if (transfer.endingLocations) {
                rideModel = {
                  ...rideModel,
                  endingLocations: Object.values(transfer.endingLocations),
                };
              }

              ride = rideModel;
            }

            if (driver !== null && ride !== null) {
              allPayouts.push({
                ...payout,
                trip: ride,
                driver: driver,
                id: payout.tripId,
              });
            } else {
              console.log(
                "Driver or ride null",
                driver === null,
                ride === null
              );
            }
          }
        }
      }

      return allPayouts;
    } catch (err) {
      console.log(err);
      return [];
    }
  }
);

export const findDriverTrips = createAsyncThunk(
  "FindDriverTrips",
  async ({
    userObj,
  }: {
    userObj: UserDataModel;
  }): Promise<
    | {
        trips: Array<{
          trip: RideAppModel;
          type: TripType;
        }>;
      }
    | undefined
  > => {
    try {
      console.log("trying..");
      // Create ride, then save ref to bookings.
      let trips = db.ref("booking_data/driver_jobs/" + userObj.id);

      let tripsFound = await trips.once("value");

      let allTrips = [];

      if (tripsFound.val() !== null) {
        const list = tripsFound.val();

        console.log("Got trips...");

        let actual_list: BookingModel[] = Object.values(list);

        if (actual_list.length > 0) {
          for (let i = 0; i < actual_list.length; i++) {
            console.log("loop ", i);
            let trip_to_get: BookingModel = actual_list[i];

            let rides = db.ref("booking_data/rides/" + trip_to_get.id);

            let ridesFound = await rides.once("value");

            if (ridesFound.val() !== null) {
              let transfer: RideModel = ridesFound.val();

              // Get drivers...

              let driversApp: GeneralAppDriver[] = [];

              if (transfer.driversResponded) {
                let drivers = await getDriversFromList(
                  transfer.driversResponded
                    ? Object.values(transfer.driversResponded)
                    : []
                );

                if (drivers) {
                  driversApp = drivers;
                }
              }

              // Get car class...

              let carClassDb = db.ref(
                "car_classes/" + transfer.selectedCarClassId
              );

              let carClassFound = await carClassDb.once("value");
              let selectedCarClass: CarClassModel = {} as CarClassModel;

              if (carClassFound.val() !== null) {
                selectedCarClass = carClassFound.val();
              }

              let states: StateAppModel[] = transfer.states
                ? Object.values(transfer.states).map((val: StateModel) => {
                    return {
                      state: getStateTypeString(val.state),
                      dateTime: val.dateTime,
                    };
                  })
                : [];

              let currentBookee = (
                await db.ref("/users/" + transfer.bookingUserId).once("value")
              ).val();

              let voucher = null;
              if (transfer.voucherId) {
                voucher = (
                  await db.ref("/vouchers/" + transfer.voucherId).once("value")
                ).val();
              }
              let rideModel: RideAppModel = {
                id: transfer.id,
                active: transfer.active,
                bookingUser: currentBookee,
                origin: transfer.origin,
                states: states,
                currentIndex: transfer.currentIndex,
                driversResponded: driversApp,
                estimatedCost: transfer.estimatedCost,
                waypoints: transfer.waypoints,
                distanceToDest: transfer.distanceToDest,
                durationToDest: transfer.durationToDest,
                dateTime: transfer.dateTime,
                selectedCarClass: selectedCarClass,
                creationDateTime: transfer.creationDateTime,
                cost: transfer.cost,
                driverLocation: transfer.driverLocation,
                distance: transfer.distance,
                duration: transfer.duration,
                request: transfer.request,
                password: transfer.password,
                paymentIntentId: transfer.paymentIntentId,
                minutesWaited: transfer.minutesWaited,
                airportTransfer: transfer.airportTransfer,
                amountPaid: transfer.amountPaid,
                driverPaid: transfer.driverPaid,
                voucher: voucher !== null ? voucher : undefined,
                details: transfer.details,
              };
              if (transfer.journeyLocations) {
                rideModel = {
                  ...rideModel,
                  journeyLocations: Object.values(transfer.journeyLocations),
                };
              }
              if (transfer.startingLocation) {
                rideModel = {
                  ...rideModel,
                  startingLocation: transfer.startingLocation,
                };
              }
              if (transfer.endingLocations) {
                rideModel = {
                  ...rideModel,
                  endingLocations: Object.values(transfer.endingLocations),
                };
              }
              allTrips.push({
                trip: rideModel,
                type: ridesFound.val().dateTime
                  ? TripType.BOOKED_RIDE
                  : TripType.INSTANT_RIDE,
              });
            }
          }
        } else {
          return;
        }
        console.log("Returning now: ", allTrips.length);

        return { trips: allTrips };
      } else {
        return;
        console.log("no trips here!");
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }
);

export const findUserTrips = createAsyncThunk(
  "FindTrips",
  async ({
    userId,
  }: {
    userId: string;
  }): Promise<
    | {
        trips: Array<{
          trip: RideAppModel;
          type: TripType;
          id: string;
        }>;
      }
    | undefined
  > => {
    try {
      // Create ride, then save ref to bookings.
      let trips = db.ref("booking_data/user_bookings/" + userId);

      let tripsFound = await trips.once("value");

      let allTrips = [];

      if (tripsFound.val() !== null) {
        const list = tripsFound.val();

        let actual_list: BookingModel[] = Object.values(list);

        if (actual_list.length > 0) {
          for (let i = 0; i < actual_list.length; i++) {
            let trip_to_get: BookingModel = actual_list[i];

            let rides = db.ref("booking_data/rides/" + trip_to_get.id);

            let ridesFound = await rides.once("value");

            if (ridesFound.val() !== null) {
              let transfer: RideModel = ridesFound.val();

              // Get drivers...

              let driversApp: GeneralAppDriver[] = [];

              if (transfer.driversResponded) {
                let driver = await getDriversFromList(
                  transfer.driversResponded
                    ? Object.values(transfer.driversResponded)
                    : []
                );

                if (driver) {
                  driversApp = [...driver];
                }
              }

              // Get car class...

              let carClassDb = db.ref(
                "car_classes/" + transfer.selectedCarClassId
              );

              let carClassFound = await carClassDb.once("value");
              let selectedCarClass: CarClassModel = {} as CarClassModel;

              if (carClassFound.val() !== null) {
                selectedCarClass = carClassFound.val();
              }

              let userDb = db.ref("users/" + transfer.bookingUserId);

              let userFound = await userDb.once("value");
              let userObj: UserDataModel = {} as UserDataModel;

              if (userFound.val() !== null) {
                userObj = userFound.val();
                let states: StateAppModel[] = transfer.states
                  ? Object.values(transfer.states).map((val: StateModel) => {
                      return {
                        state: getStateTypeString(val.state),
                        dateTime: val.dateTime,
                      };
                    })
                  : [];

                let rideModel: RideAppModel = {
                  id: transfer.id,
                  active: transfer.active,
                  bookingUser: userObj,
                  origin: transfer.origin,
                  states: states,
                  currentIndex: transfer.currentIndex,
                  driversResponded: driversApp,
                  estimatedCost: transfer.estimatedCost,
                  waypoints: transfer.waypoints,
                  dateTime: transfer.dateTime,
                  selectedCarClass: selectedCarClass,
                  creationDateTime: transfer.creationDateTime,
                  cost: transfer.cost,
                  driverLocation: transfer.driverLocation,
                  distance: transfer.distance,
                  duration: transfer.duration,
                  request: transfer.request,
                  password: transfer.password,
                  distanceToDest: transfer.distanceToDest,
                  durationToDest: transfer.durationToDest,
                  minutesWaited: transfer.minutesWaited,
                  airportTransfer: transfer.airportTransfer,
                };
                if (transfer.journeyLocations) {
                  rideModel = {
                    ...rideModel,
                    journeyLocations: Object.values(transfer.journeyLocations),
                  };
                }
                if (transfer.startingLocation) {
                  rideModel = {
                    ...rideModel,
                    startingLocation: transfer.startingLocation,
                  };
                }
                if (transfer.endingLocations) {
                  rideModel = {
                    ...rideModel,
                    endingLocations: Object.values(transfer.endingLocations),
                  };
                }
                allTrips.push({
                  trip: rideModel,
                  type: ridesFound.val().dateTime
                    ? TripType.BOOKED_RIDE
                    : TripType.INSTANT_RIDE,
                  id: rideModel.id,
                });
              }
            }
          }
        } else {
          return;
        }

        return { trips: allTrips };
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }
);

export const findTripsContainingDriver = createAsyncThunk(
  "FindTripsContainingDriver",
  async ({
    driverObj,
    allTrips,
  }: {
    driverObj: DriverModel;
    allTrips: Array<{
      trip: RideAppModel;
      type: TripType;
    }>;
  }): Promise<
    | {
        trips: Array<{
          trip: RideAppModel;
          type: TripType;
        }>;
      }
    | undefined
  > => {
    try {
      // Create ride, then save ref to bookings.

      // Filter to only trips with a driver
      let filtered = allTrips.filter(
        (val) =>
          val.trip.driversResponded && val.trip.driversResponded.length > 0
      );

      filtered = filtered.filter(
        (val: { trip: RideAppModel; type: TripType }) => {
          // Find if drivers is in journey

          let filteredDrivers = val.trip.driversResponded!.find(
            (val: GeneralAppDriver) => val.driver.id === driverObj.id
          );

          if (filteredDrivers) {
            return val;
          }
        }
      );

      return { trips: filtered };
    } catch (err) {
      console.log(err);
      return;
    }
  }
);

export const findTripById = createAsyncThunk(
  "FindTripById",
  async ({
    tripId,
  }: {
    tripId: string;
  }): Promise<
    | {
        trip: RideAppModel;
        type: TripType;
        id: string;
      }
    | undefined
  > => {
    // Determine type from booking db
    let trip = db.ref("booking_data/bookings/" + tripId);

    let tripFound = await trip.once("value");

    let tripToReturn = {};

    if (tripFound.val !== null) {
      let type = tripFound.val().tripType;
      let trip_to_get = tripFound.val();

      let rides = db.ref("booking_data/rides/" + trip_to_get.id);

      let ridesFound = await rides.once("value");

      if (ridesFound.val() !== null) {
        let transfer: RideModel = ridesFound.val();

        // Get drivers...

        let driversApp: GeneralAppDriver[] = [];

        if (transfer.driversResponded) {
          let driver = await getDriversFromList(
            transfer.driversResponded
              ? Object.values(transfer.driversResponded)
              : []
          );
          if (driver) {
            driversApp = [...driver];
          }
        }

        // Get car class...

        let carClassDb = db.ref("car_classes/" + transfer.selectedCarClassId);

        let carClassFound = await carClassDb.once("value");
        let selectedCarClass: CarClassModel = {} as CarClassModel;

        if (carClassFound.val() !== null) {
          selectedCarClass = carClassFound.val();
        }

        let userDb = db.ref("users/" + transfer.bookingUserId);

        let userFound = await userDb.once("value");
        let userObj: UserDataModel = {} as UserDataModel;

        if (userFound.val() !== null) {
          userObj = userFound.val();
          let states: StateAppModel[] = transfer.states
            ? Object.values(transfer.states).map((val: StateModel) => {
                return {
                  state: getStateTypeString(val.state),
                  dateTime: val.dateTime,
                };
              })
            : [];

          let rideModel: RideAppModel = {
            id: transfer.id,
            active: transfer.active,
            bookingUser: userObj,
            origin: transfer.origin,
            states: states,
            currentIndex: transfer.currentIndex,
            driversResponded: driversApp,
            estimatedCost: transfer.estimatedCost,
            waypoints: transfer.waypoints,
            dateTime: transfer.dateTime,
            selectedCarClass: selectedCarClass,
            creationDateTime: transfer.creationDateTime,
            cost: transfer.cost,
            driverLocation: transfer.driverLocation,
            distance: transfer.distance,
            duration: transfer.duration,
            request: transfer.request,
            password: transfer.password,
            distanceToDest: transfer.distanceToDest,
            durationToDest: transfer.durationToDest,
            minutesWaited: transfer.minutesWaited,
            airportTransfer: transfer.airportTransfer,
            amountPaid: transfer.amountPaid,
            driverPaid: transfer.driverPaid,
            details: transfer.details,
          };
          if (transfer.journeyLocations) {
            rideModel = {
              ...rideModel,
              journeyLocations: Object.values(transfer.journeyLocations),
            };
          }
          if (transfer.startingLocation) {
            rideModel = {
              ...rideModel,
              startingLocation: transfer.startingLocation,
            };
          }
          if (transfer.endingLocations) {
            rideModel = {
              ...rideModel,
              endingLocations: Object.values(transfer.endingLocations),
            };
          }
          return {
            trip: rideModel,
            type: ridesFound.val().dateTime
              ? TripType.BOOKED_RIDE
              : TripType.INSTANT_RIDE,
            id: rideModel.id,
          };
        }
      }
    }
  }
);
