import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getClientById, getClientList } from "../thunks/client.thunks";
import { ClientModel } from "../../models/UserDataModel";
import { RideAppModel, TripType } from "../../models/BookingModels";
import { findUserTrips } from "../thunks/booking.thunks";

/**
 * userId - The user ID belonging to the logged in user
 * userData - The data belonging to the logged in user (UserDataModel)
 * userDataSearching - If the user data is loading
 * userDataError - If the user data had an error loading.
 */
interface IProps {
  selectedViewClient: ClientModel | undefined;
  selectedViewClientLoading: boolean;
  selectedViewClientError: boolean;
  selectedEditClient: ClientModel | undefined;
  clientList: ClientModel[];
  clientListLoading: boolean;
  clientListError: boolean;
  createNewClient: boolean;
  selectedClientTrips: {
    trips: Array<{
      trip: RideAppModel;
      type: TripType;
      id: string;
    }>;
  };
  selectedClientTripsLoading: boolean;
}

const clientInitialState: IProps = {
  selectedViewClient: undefined,
  selectedViewClientLoading: false,
  selectedViewClientError: false,
  selectedEditClient: undefined,
  clientList: [],
  clientListError: false,
  createNewClient: false,
  clientListLoading: false,
  selectedClientTrips: { trips: [] },
  selectedClientTripsLoading: false,
};

const clientSlice: any = createSlice({
  name: "client_data",
  initialState: clientInitialState,
  reducers: {
    // Setter functions
    setSelectedViewClient: (
      state: IProps,
      { payload }: PayloadAction<ClientModel>
    ) => {
      state.selectedViewClient = payload;
    },
    setSelectedViewClientLoading: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.selectedViewClientLoading = payload;
    },
    setSelectedViewClientError: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.selectedViewClientError = payload;
    },
    setCreateNewClient: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.createNewClient = payload;
    },
    setSelectedEditClient: (
      state: IProps,
      { payload }: PayloadAction<ClientModel>
    ) => {
      state.selectedEditClient = payload;
    },
    setClientList: (
      state: IProps,
      { payload }: PayloadAction<ClientModel[]>
    ) => {
      state.clientList = payload;
    },
    setClientListLoading: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.clientListLoading = payload;
    },
    setClientListError: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.clientListError = payload;
    },
  },

  // Reducers to tell react which states to update with the thunk functions.
  extraReducers: (builder: any) => {
    builder.addCase(getClientList.rejected, (state: IProps) => {
      state.clientListLoading = false;
      state.clientListError = true;
    });
    builder.addCase(getClientList.pending, (state: IProps) => {
      state.clientListLoading = true;
      state.clientListError = false;
    });
    builder.addCase(
      getClientList.fulfilled,
      (state: IProps, { payload }: PayloadAction<ClientModel[]>) => {
        state.clientListError = false;
        state.clientListLoading = false;

        state.clientList = payload.sort((a: ClientModel, b: ClientModel) => {
          return (
            // @ts-ignore
            new Date(b.user.lastActiveDate) -
            // @ts-ignore
            new Date(a.user.lastActiveDate)
          );
        });
      }
    );
    builder.addCase(getClientById.rejected, (state: IProps) => {
      state.selectedViewClientError = true;
      state.selectedViewClientLoading = false;
    });
    builder.addCase(getClientById.pending, (state: IProps) => {
      state.selectedViewClientLoading = true;
      state.selectedViewClientError = false;
    });
    builder.addCase(
      getClientById.fulfilled,
      (state: IProps, { payload }: PayloadAction<ClientModel>) => {
        state.selectedViewClientError = false;
        state.selectedViewClientLoading = false;

        state.selectedViewClient = payload;
      }
    );
    builder.addCase(
      findUserTrips.pending,
      (state: IProps, { payload }: PayloadAction<Array<RideAppModel>>) => {
        state.selectedClientTripsLoading = true;
      }
    );
    builder.addCase(
      findUserTrips.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<{
          trips: Array<{
            trip: RideAppModel;
            type: TripType;
            id: string;
          }>;
        }>
      ) => {
        state.selectedClientTripsLoading = false;
        state.selectedClientTrips = payload;
      }
    );
  },
});

export const {
  setSelectedEditClient,
  setSelectedViewClient,
  setClientList,
  setClientListError,
  setClientListLoading,
  setCreateNewClient,
} = clientSlice.actions;

export default clientSlice.reducer;
