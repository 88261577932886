import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment, { isDate } from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DriverModel } from "../../../models/DriverModel";
import { Edit, Launch } from "@mui/icons-material";
import {
  setSelectedEditDriver,
  setSelectedViewDriver,
} from "../../../store/slices/driver.slice";
import { useTools } from "../../../services/general_services";
import {
  RideAppModel,
  StateModel,
  StateType,
  TripType,
} from "../../../models/BookingModels";
import { styles } from "../../../assets/style/styles";
import { setSelectedViewClient } from "../../../store/slices/client.slice";
import { setSelectedTrip } from "../../../store/slices/booking.slice";
import { PayoutAppModel } from "../../../models/PayoutModel";

export function PayoutGrid({ rows }: { rows: PayoutAppModel[] }) {
  const { driverList } = useSelector((state: any) => state.DriverSlice);

  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: "bookingUser",
      headerName: "Trip",
      flex: 2,
      valueGetter: (params) => {
        return `${params.row.trip.bookingUser.firstName} ${params.row.trip.bookingUser.lastName}`;
      },
      renderCell: (params) => {
        return (
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            alignContent={"center"}
            style={{
              height: "52px",
            }}
            flexDirection={"row"}
          >
            {/* Circle Profile Pic */}
            <Grid
              item
              container
              xs={4}
              sx={{ height: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Tooltip
                title={
                  params.row.trip.bookingUser.firstName +
                  " " +
                  params.row.trip.bookingUser.lastName
                }
              >
                <Avatar
                  style={{
                    width: "28px",
                    height: "28px",
                    marginRight: 10,
                  }}
                  alt={params.row.trip.bookingUser.firstName}
                  src={params.row.trip.bookingUser.profilePictureUrl}
                />
              </Tooltip>
            </Grid>

            {/* Profile Information */}
            <Grid item xs={8}>
              <Typography
                variant={"body1"}
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontFamily: "Roboto",
                  fontWeight: 200,
                  fontSize: 12,
                  width: "100%",
                }}
              >
                {`${params.row.trip.origin.address} >
                  ${
                    params.row.trip.waypoints[
                      params.row.trip.waypoints.length - 1
                    ].address
                  }`}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "driver",
      headerName: "Driver",
      flex: 2,
      valueGetter: (params) => {
        return `${params.row.driver.firstName} ${params.row.driver.lastName}`;
      },
      renderCell: (params) => {
        console.log("Driver lol", params.row.driver);
        let driver = params.row.driver;

        if (driver && driver !== null) {
          return (
            <Grid
              item
              container
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              style={{
                height: "52px",
              }}
              flexDirection={"row"}
            >
              {/* Circle Profile Pic */}
              <Grid
                item
                container
                xs={4}
                sx={{ height: "100%" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <div
                  style={{
                    width: 30,
                    height: 30,
                    alignSelf: "center",
                    borderRadius: 25,
                    backgroundImage: `url(${
                      driver ? driver.profilePictureUrl : "/static/avatar.svg"
                    }`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>

              {/* Profile Information */}
              <Grid item xs={8} flexDirection={"column"}>
                <Typography
                  variant={"h6"}
                  style={{ lineHeight: 1.2, fontSize: 15 }}
                >
                  {driver.firstName}
                </Typography>
                <Typography
                  variant={"body1"}
                  style={{
                    color: "rgba(0,0,0,0.4)",
                    fontFamily: "Roboto",
                    fontWeight: 200,
                    fontSize: 12,
                  }}
                >
                  +44 {driver.phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          );
        }
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      valueGetter: (params) => {
        return `${params.row.amountPaid}`;
      },
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid item container justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "400",
                fontSize: 12,
              }}
              variant={"body1"}
            >
              {"£" + params.row.trip.amountPaid}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: "creationDateTime",
      headerName: "Created",
      valueGetter: (params) => {
        return `${params.row.trip.date}`;
      },
      flex: 1.5,
      type: "string",
      headerAlign: "center",
      renderCell: (params) => {
        let finishTime: null | string = null;

        for (let i = params.row.trip.states.length - 1; i > 0; i--) {
          let state: any = params.row.trip.states[i];

          console.log(state);
          if (
            state.state === "Arrived at destination" ||
            state.state === "Trip ended early"
          ) {
            finishTime = new Date(state.dateTime).toISOString();
            break;
          }
        }
        return (
          <Grid item container justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "400",
                fontSize: 12,
              }}
              variant={"body1"}
            >
              {finishTime
                ? moment(finishTime).format("Do MMM YYYY HH:mma")
                : moment(params.row.trip.creationDateTime).format(
                    "Do MMM YYYY HH:mma"
                  )}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: "paidDate",
      headerName: "Paid",
      flex: 1.5,
      headerAlign: "center",
      valueGetter: (params) => {
        return `${moment(params.row.paidDate).format("DD/MM/YY HH:mma")}`;
      },
      renderCell: (params) => {
        return (
          <Grid item container justifyContent={"center"}>
            <div
              style={{
                backgroundColor: !params.row.paidDate
                  ? "rgba(255,30,0,0.2)"
                  : "rgba(5,255,0,0.2)",
                color: "black",
                padding: 5,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 25,
                fontSize: 12,
              }}
            >
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: 12,
                }}
                variant={"body1"}
              >
                {params.row.paidDate
                  ? moment(params.row.paidDate).format("DD/MM/YY HH:mma")
                  : "Upcoming"}
              </Typography>
            </div>
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <IconButton key={"id2"} onClick={() => {}}>
            <Launch />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: "none",
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      onCellClick={(e) => {
        if (e.field === "driversResponded") {
          navigate(
            "/admin/drivers/" +
              e.row.trip.driversResponded[
                e.row.trip.driversResponded.length - 1
              ].driver.id
          );
          dispatch(
            setSelectedViewDriver(
              e.row.trip.driversResponded[
                e.row.trip.driversResponded.length - 1
              ].driver
            )
          );
        } else if (e.field === "driver") {
          navigate("/admin/drivers/" + e.row.driver.id);
          dispatch(setSelectedViewDriver(e.row.driver));
        } else {
          navigate("/admin/rides/" + e.row.trip.id);
          dispatch(setSelectedTrip(e.row));
        }
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250 }}
    />
  );
}
