import { createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase_services";
import { VoucherModel } from "../../models/VoucherModel";

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getVoucherList = createAsyncThunk(
  "GetVoucherList",
  async (): Promise<VoucherModel[] | undefined> => {
    try {
      let vouchers = db.ref("vouchers/");

      let voucherFound = await vouchers.once("value");

      if (voucherFound.val() !== null) {
        const list = voucherFound.val();

        return Object.values(list);
      } else {
        return [];
      }
    } catch (err: any) {
      console.log(err);
    }
  }
);
