import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserDataModel from "../../models/UserDataModel";
import { getUserData } from "../thunks/user.thunks";

/**
 * userId - The user ID belonging to the logged in user
 * userData - The data belonging to the logged in user (UserDataModel)
 * userDataSearching - If the user data is loading
 * userDataError - If the user data had an error loading.
 */
interface IProps {
  userId: string;
  userData: UserDataModel | undefined;
  userDataSearching: boolean;
  userDataError: boolean;
}

const userInitialState: IProps = {
  userId: "",
  userData: undefined,
  userDataSearching: false,
  userDataError: false,
};

const userSlice: any = createSlice({
  name: "user_data",
  initialState: userInitialState,
  reducers: {
    // Setter functions
    setUserId: (state: IProps, { payload }: PayloadAction<string>) => {
      state.userId = payload;
    },
    setUserData: (
      state: IProps,
      { payload }: PayloadAction<UserDataModel | undefined>
    ) => {
      state.userData = payload;
    },
    setUserDataSearching: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.userDataSearching = payload;
    },
    setUserDataError: (state: IProps, { payload }: PayloadAction<boolean>) => {
      state.userDataError = payload;
    },
  },

  // Reducers to tell react which states to update with the thunk functions.
  extraReducers: (builder: any) => {
    builder.addCase(getUserData.rejected, (state: IProps) => {
      state.userDataSearching = false;
      state.userDataError = true;
    });
    builder.addCase(getUserData.pending, (state: IProps) => {
      state.userDataSearching = true;
      state.userDataError = false;
    });
    builder.addCase(
      getUserData.fulfilled,
      (state: IProps, { payload }: PayloadAction<UserDataModel>) => {
        state.userDataError = false;
        state.userDataSearching = false;
        state.userData = payload;
      }
    );
  },
});

export const {
  setUserId,
  setUserData,
  setUserDataError,
  setUserDataSearching,
} = userSlice.actions;

export default userSlice.reducer;
