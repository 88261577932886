import {
  CircularProgress,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import "../../index.css";
import { LoginScreen } from "../pages/LoginScreen";
import { NavBar } from "./NavBar";
import { useEffect, useState } from "react";
import { Logout, Menu, MenuOpen } from "@mui/icons-material";
import { logoutUser } from "../../services/auth_services";
import { useTools } from "../../services/general_services";
import { BorderLine } from "./general/BorderLine";
import { DriverModalType } from "../../models/DriverModelType";
import { DriverModal } from "./drivers/DriverModal";
import { CarClassModal } from "./settings/CarClassModal";
import { VoucherModal } from "./settings/VoucherModal";
import { isMobile } from "react-device-detect";
import { PageType } from "../../models/PageType";

export function DriverContainer({ children }: any) {
  const { userData } = useSelector((state: any) => state.UserSlice);

  const { navigate, dispatch, theme } = useTools();

  const [showNav, setShowNav] = useState(!isMobile);
  const [slideNav, setSlideNav] = useState(true);

  useEffect(() => {
    if (showNav) {
      setTimeout(() => {
        setSlideNav(true);
      }, 250);
    } else {
      setSlideNav(false);
    }
  }, [showNav]);

  useEffect(() => {
    if (userData && !userData.driver) {
      window.alert(
        "You are not permitted to be on this page, please leave immediately."
      );
    }
  }, [userData]);
  return (
    <>
      {userData && userData.driver ? (
        <Grid
          container
          justifyContent={"flex-end"}
          sx={{
            backgroundColor: "black",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{
              backgroundColor: "black",
              paddingTop: 1,
              paddingBottom: 1,
              paddingRight: 1,
              height: "100vh",
              transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 2,
                height: "100%",
                overflow: "hidden",
                overflowY: "scroll",
              }}
              flexDirection={"row"}
            >
              <Grid item container xs={12}>
                <Grid item container xs={10} alignItems={"center"}>
                  <IconButton
                    onClick={() => {
                      setShowNav(!showNav);
                    }}
                  >
                    {showNav ? <MenuOpen /> : <Menu />}
                  </IconButton>

                  <Typography
                    variant="h5"
                    fontWeight={"100"}
                    sx={{ paddingLeft: 2, fontStyle: "italic" }}
                  >
                    Hello, {userData.firstName} 👋
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <IconButton
                    onClick={() => {
                      logoutUser(navigate, dispatch);
                    }}
                  >
                    <Logout />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{ height: "100%", paddingTop: 5 }}
                alignItems={"flex-start"}
              >
                {children}
              </Grid>
            </Grid>
          </Grid>

          <DriverModal type={DriverModalType.CREATE} />
          <DriverModal type={DriverModalType.EDIT} />
          <CarClassModal />
          <VoucherModal />
        </Grid>
      ) : (
        <LoginScreen type={PageType.DRIVERS} />
      )}
    </>
  );
}
